// Extra libs
import Cookies from "js-cookie";
import { apiGet } from "utils/webRequestUtils.jsx";

const Graph_Api_Base_Url = "https://graph.microsoft.com/v1.0/";
const Graph_Api_Base_Url_Beta = "https://graph.microsoft.com/beta/";

/**
 * Executes GraphAPI GET call to provided api path.
 * @param {string} apiPath Endpoint to call (without backslash prefix).
 * @param {boolean} useBeta Should this call use the beta endpoint of the GraphAPI?.
 * @param {boolean} isRetry Indicates if this call is a retry attempt because of a previous call failing.
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function graphApiGet(apiPath, useBeta = false, isRetry = false)
{
    var baseUrl = useBeta ? Graph_Api_Base_Url_Beta : Graph_Api_Base_Url;

    // Get authentication token.
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)auth-graph\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    
    return fetch(baseUrl + apiPath,
    {
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        // If unauthorized is returned, try to fetch new token from UCP API and retry to call the GraphAPI.
        if (result.status === 401 && !isRetry)
        {
            return getNewGraphApiToken().then(() =>
            {
                return graphApiGet(apiPath, useBeta, true);
            });
        }
        else if (result.status !== 200)
        {
            throw result;
        }
        else
        {
            return result;  
        }  
    });
}

/**
 * Executes GraphAPI POST call to provided api path.
 * @param {string} apiPath Endpoint to call (without backslash prefix). 
 * @param {boolean} useBeta Should this call use the beta endpoint of the GraphAPI?.
 * @param {boolean} isRetry Indicates if this call is a retry attempt because of a previous call failing.
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function graphApiPost(apiPath, useBeta = false, isRetry = false)
{
    var baseUrl = useBeta ? Graph_Api_Base_Url_Beta : Graph_Api_Base_Url;

    // Get authentication token.
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)auth-graph\s*\=\s*([^;]*).*$)|^.*$/, "$1")

    return fetch(baseUrl + apiPath,
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        // If unauthorized is returned, try to fetch new token from UCP API and retry to call the GraphAPI.
        if (result.status === 401 && !isRetry)
        {
            return getNewGraphApiToken().then(() =>
            {
                return graphApiGet(apiPath, useBeta, true);
            });
        }
        else if (result.status !== 200)
        {
            throw result;
        }

        return result;  
    });
}

/**
 * Executes GraphAPI POST call to provided api path.
 * @param {string} apiPath Endpoint to call (e.g. /cases OR /users/me). 
 * @param {object} jsonBody Json object to pass a the request body (don't stringify the object).
 * @param {boolean} useBeta Should this call use the beta endpoint of the GraphAPI?.
 * @param {boolean} isRetry Indicates if this call is a retry attempt because of a previous call failing.
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function graphPostJson(apiPath, jsonBody, useBeta = false, isRetry = false)
{
    var baseUrl = useBeta ? Graph_Api_Base_Url_Beta : Graph_Api_Base_Url;

    // Get authentication token.
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)auth-graph\s*\=\s*([^;]*).*$)|^.*$/, "$1")

    return fetch(baseUrl + apiPath,
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonBody)
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        // If unauthorized is returned, try to fetch new token from UCP API and retry to call the GraphAPI.
        if (result.status === 401 && !isRetry)
        {
            return getNewGraphApiToken().then(() =>
            {
                return graphApiGet(apiPath, useBeta, true);
            });
        }
        else if (result.status !== 200)
        {
            throw result;
        }

        return result;   
    });
}

/**
* Try to fetch Graph API access token from UCP API.
*/
function getNewGraphApiToken()
{
   return apiGet("graph-api/auth").then((result) => 
   {
       Cookies.set("auth-graph", result.data, { expires: 1 / 24, secure: true, sameSite: "lax" });  // TODO: Add Secure, http only & SameSite flags. Write documentation about why these flags are added.
   });
}