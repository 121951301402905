import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PeopleIcon from "@material-ui/icons/People";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPortalPermissions = [    
  "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
  "UCALLMANAGER.AGENTS.VIEW",
  "UCALLMANAGER.AGENTS.EDIT",
  "UCALLMANAGER.QUEUES.EDIT"
]

class UCallManagerAgentsWidget extends React.Component 
{
  constructor(props) 
  {
    super(props);
      
    this.state = 
    {
      error: null,
      isLoaded: false,
      agentsCount: 0
    }
  }

  componentDidMount()
  {   
      // Check portal permissions   
      if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) ||
          !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
      {
          this.setState(
          {
              isLoaded: true,
              error: "Access denied"
          });

          return;
      }

      // Get agents count
      this.getAgentsCount();
  }

  getAgentsCount()
  {
      apiGet("ucm/agents/count").then((result) =>            
      {
          // TODO: Check if 'Authorization has been denied for this request' message is received.
          this.setState(
          {
              isLoaded:true,
              agentsCount: result.data
          });
      },
      (error) =>
      {
          this.setState(
          {
              isLoaded: true,
              error: error.data
          });
      });
  }

  render() 
  {
      const { classes } = this.props;
      const { isLoaded, error, agentsCount } = this.state;
      
      if (!isLoaded)
      {
          
          return <LoadingIndicator loadingText="Loading agents widget..."></LoadingIndicator>
      }
      else if (error)
      {
          // TODO: Error logging
          return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
      } 

      return (
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <PeopleIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Agents</p>
            <h3 className={classes.cardTitle}>{agentsCount === 1 ? "1 agent" : (agentsCount + " agents")}</h3>
          </CardHeader> 
          <CardFooter stats>
            <NavLink to="/ucm/agents">
              Go to uCallManager agents
            </NavLink>  
          </CardFooter>
        </Card>
      );
  }
}
export default withStyles(dashboardStyle)(UCallManagerAgentsWidget);