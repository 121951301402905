import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Extra libs
import globalData from "variables/globalData.jsx";

import { apiGet, apiPost } from "utils/webRequestUtils.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = theme => (
{
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

class CaseDetails extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,

            confirmDialogOpen: false,
            closingCase: false,
            caseClosed: false,

            toastOpen: false,
            toastColor: "success",
            toastText: "",

            isCaseOwner: false,
            caseDetails: null
        }
    }

    // TODO: This is a temporary solution.
    // TODO: Duplicatie code (CaseDetails / CaseActivities)
    getOwnerDetails = () =>
    {
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Check if user is case owner
        apiGet("cases/owner/" + ticketNumber).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedOwnerDetails:true,
                isCaseOwner: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedOwnerDetails: true,
                error: error.data,
                isCaseOwner: false
            });
        });
    }
    
    componentDidMount()
    {
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Get details of case
        apiGet("cases/get/" + ticketNumber).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                caseDetails: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });

        // Get case ownership info
        this.getOwnerDetails();
    }
    
    handleConfirmDialogOpen = () =>
    {
        this.setState({
            confirmDialogOpen: true
        })
    }

    handleConfirmDialogClose = () => 
    {
        this.setState({
            confirmDialogOpen: false
        })
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    closeCase = () =>
    {
        this.setState({closingCase:true});

        // TODO: Getting ticketnumber is duplicate at this point.
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Close case
        apiPost("cases/close/" + ticketNumber).then(() =>        
        {
            this.setState(
            {
                closingCase: false,
                caseClosed: true
            });            
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                closingCase: false
            });

            this.handleConfirmDialogClose();
            this.showNotification("danger", error.data);
        });
    }

    render() 
    {        
        const { error, isLoaded, confirmDialogOpen, closingCase, caseClosed, toastOpen, toastColor, toastText, isCaseOwner, caseDetails } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading case details..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error.message}></ErrorIndicator>
        }
        else if (caseClosed)
        {
            // If case was closed, navigate to cases page.
            return (<Redirect to="../cases" push />)
        }

        return (
            <div>

                {/* Popup alert */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Close case dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.handleConfirmDialogClose()} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to close this case?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Closed cases can't be reopened from the Universal Customer Portal again.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleConfirmDialogClose()} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={closingCase} onClick={() => this.closeCase()} color="danger">
                            Close case!
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Case details card */}
                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <h4 className={classes.cardTitleWhite}>Case Details</h4>
                                <p className={classes.cardCategoryWhite}>{caseDetails.Title}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} style={{textAlign: 'right'}}>
                            {                                
                                PMUtils.userHasAnyRequiredPermissions(["ADMINCASES"], globalData.loggedInUser.PortalPermissions) ||
                                (PMUtils.userHasAnyRequiredPermissions(["PERSONALCASESONLY", "READALLCASESPERSONALWRITE"], globalData.loggedInUser.PortalPermissions) && isCaseOwner)
                                ? 
                                (<Button color="secondary" onClick={() => this.handleConfirmDialogOpen()}>Close Case</Button>) 
                                : null
                            }                                
                            </GridItem>
                        </GridContainer>                                
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Status" 
                                    id="status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.Status
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Ticket Number"
                                    id="ticketnumber"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.TicketNumber
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Type" 
                                    id="type"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.Type.Name
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Primary Contact"
                                    id="primary-contact"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.PrimaryContact.FullName
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Entitlement" 
                                    id="entitlement"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.Entitlement.Name
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Origin"
                                    id="origin"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.Origin.Name
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Subject" 
                                    id="subject"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.Subject.Title
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Created On"
                                    id="created-on"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        defaultValue: caseDetails.DateCreated
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            {/* <InputLabel style={{ color: "#AAAAAA" }}>Description</InputLabel>  */}
                            <CustomInput
                            labelText="Description"
                            id="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                defaultValue: caseDetails.Description
                            }}
                            multiline={true}
                            rows={5}
                            />
                        </GridItem>
                    </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(CaseDetails)