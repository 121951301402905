import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import CaseDetails from "views/Cases/CaseInfo/ClosedCaseDetails.jsx";
import CaseActivities from "views/Cases/CaseInfo/ClosedCaseActivities.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = 
{
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const allowedPortalPermissions =
[
    "ADMINCASES",
    "PERSONALCASESONLY",
    "READALLCASES",
    "READALLCASESPERSONALWRITE"
]

class ClosedCaseInfoPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                error: "Access denied"
            });
        }

        this.setState(
        {
            isLoaded: true
        });
    }

    render() 
    {        
        const { isLoaded, error } = this.state;

        if (!isLoaded)
        {   
            return <LoadingIndicator loadingText="Loading case info..." fullScreen></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10} xl={5}>
                        <CaseDetails></CaseDetails>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={7}>
                        <CaseActivities></CaseActivities>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(ClosedCaseInfoPage)