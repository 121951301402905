import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import TableJson from "components/Table/TableJson.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import SendIcon from '@material-ui/icons/Send';
import PublishIcon from '@material-ui/icons/Publish';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

// Extra libs
import { apiGet, apiPostFormData } from "utils/webRequestUtils.jsx";

const styles = theme => (
{
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    rightIcon:{
        marginLeft: "4px"
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

class CaseActivities extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoadedActivities: false,
            isLoadedOwnerDetails: false,

            replyDialogOpen: false,
            sendingPortalresponse: false,

            toastOpen: false,
            toastColor: "success",
            toastText: "",

            caseActivities: null,
            isCaseOwner: false,

            replySubject: "",
            replyMessage: "",

            selectedFilesText: "No file(s) selected."
        }
    }

    // Prevent timeout from setting set when page is closed.
    componentWillUnmount() 
    {
        var id = window.setTimeout(null, 0);
        while (id--) 
        {
            window.clearTimeout(id);
        }
    }

    handleReplyDialogOpen = () =>
    {
        this.setState({
            replyDialogOpen: true
        })
    }
    
    handleReplyDialogClose = () => 
    {
        this.setState({
            replyDialogOpen: false,
            selectedFilesText: "No file(s) selected."
        })
    }

    componentDidMount()
    {
        // Get activities and case ownership details
        this.getActivities();
        this.getOwnerDetails();
    }

    getActivities = () =>
    {
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Get activities of ticket
        apiGet("activities/get/" + ticketNumber).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedActivities:true,
                caseActivities: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedActivities: true,
                error: error.data
            });
        });
    }

    // TODO: This is a temporary solution.
    // TODO: Duplicatie code (CaseDetails / CaseActivities)
    getOwnerDetails = () =>
    {
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Check if user is case owner
        apiGet("cases/owner/" + ticketNumber).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedOwnerDetails:true,
                isCaseOwner: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedOwnerDetails: true,
                error: error.data,
                isCaseOwner: false
            });
        });
    }

    sendPortalresponse = () => 
    {
        this.setState({sendingPortalresponse: true})

        // TODO: authentication
        const { replySubject, replyMessage } = this.state;

        // TODO: Getting ticketnumber is duplicate at this point.
        // Get case ticketnumber from url parameter.
        var url = new URL(window.location.href);
        var ticketNumber = url.searchParams.get("no");

        // Create request body
        var formDataRequestBody = new FormData();
        formDataRequestBody.append("subject", replySubject);
        formDataRequestBody.append("description", replyMessage);
        formDataRequestBody.append("ticketNumber", ticketNumber);

        var filesToUpload = document.getElementById("file-upload").files;

        for (var i = 0; i < filesToUpload.length; i++) {      
            var file = filesToUpload[i];

            if (file.size > 15000000)
            {
                this.setState({ sendingPortalresponse: false });

                this.showNotification("danger", "Oops, file size can not be bigger than 15 MB.");
                return;
            }

            formDataRequestBody.append("attachments", file);
        }

        // Send portal response
        apiPostFormData("activities/sendmessage", formDataRequestBody).then(() =>        
        {
            this.setState(
            {
                sendingPortalresponse: false
            });

            this.handleReplyDialogClose();
            this.showNotification("success", "Portal response sent!");

            this.getActivities();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                //createCaseError: error, 
                sendingPortalresponse: false
            });

            this.showNotification("danger", error.data);
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    setSelectedFilesText = () =>
    {
        var fileName = "";
        var fileInput = document.getElementById("file-upload");
        
        if( fileInput.files && fileInput.files.length > 1 )
        {
            var fileSize = 0;

            for (var i = 0; i < fileInput.files.length; i++)
            {
                fileSize += parseFloat((fileInput.files[i].size / 1000000).toFixed(2));
            }

			fileName = fileInput.files.length + " files selected (" + fileSize + " MB).";
        }
        else
        {
			fileName = fileInput.value.split( '\\' ).pop() + " (" + parseFloat((fileInput.files[0].size / 1000000).toFixed(2)) + " MB).";
        }

        this.setState(
        {
            selectedFilesText: fileName
        });
    }

    render() 
    {        
        const { error, isLoadedActivities, isLoadedOwnerDetails, replyDialogOpen, sendingPortalresponse, toastOpen, toastColor, toastText, caseActivities, isCaseOwner, selectedFilesText } = this.state;
        const { classes } = this.props;

        if (!isLoadedActivities || !isLoadedOwnerDetails)
        {
            return <LoadingIndicator loadingText="Loading case activities..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                <Dialog className={classes.responsiveDialog} open={replyDialogOpen} onClose={() => this.handleReplyDialogClose()} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.sendPortalresponse()}}>
                        <DialogTitle id="form-dialog-title">Reply To Case</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, enter the details of your message down below.
                            </DialogContentText>

                            <CustomInput
                                labelText="Subject"
                                id="reply-subject"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true,
                                    autoFocus: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({replySubject: value}); }
                                }
                            />    
                            <CustomInput
                                labelText="Please, enter your message..."
                                id="reply-message"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({replyMessage: value}); }
                                }
                                multiline={true}
                                rows={5}
                            />    
                            
                            {/* TODO: Create reusable control */}
                            <div style={{marginTop: "20px"}}>
                                <label>Max. file size: 15 MB</label>
                                <br/>
                                <Button onClick={() => document.getElementById("file-upload").click()} color="secondary" style={{marginRight: "10px"}}>
                                        <PublishIcon></PublishIcon>
                                        Select files...
                                </Button>
                                <span>{selectedFilesText}</span>

                                <input id="file-upload" type="file" style={{display:"none"}} multiple="multiple" onChange={() => this.setSelectedFilesText()}></input>  
                            </div> 

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleReplyDialogClose()} color="white">
                                Cancel
                            </Button>
                            <Button disabled={sendingPortalresponse} type="submit" color="secondary">
                                <SendIcon></SendIcon>
                                {sendingPortalresponse ? "Sending..." : "Send"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <h4 className={classes.cardTitleWhite}>Case Activities</h4>
                                <p className={classes.cardCategoryWhite}>All case activities. </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} style={{textAlign: 'right'}}>
                            {            
                                PMUtils.userHasAnyRequiredPermissions(["ADMINCASES"], globalData.loggedInUser.PortalPermissions) ||
                                (PMUtils.userHasAnyRequiredPermissions(["PERSONALCASESONLY", "READALLCASESPERSONALWRITE"], globalData.loggedInUser.PortalPermissions) && isCaseOwner)
                                ?                    
                                (<Button color="secondary" onClick={() => this.handleReplyDialogOpen()}>Reply to Case</Button>) 
                                : null
                            }                                
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Subject", "Activity Type", "Status", "Created By", "Created On", "Description"]}
                            tableHeadDataMap={["Subject", "Type", "Status", "CreatedBy", "CreatedOn", "Description"]}
                            tableData={caseActivities}
                            infoFieldIndex={5}
                            infoFieldLinkText="Show description"
                            infoModalTitle="Description"
                            isSortable={true}
                            initialOrderBy={"CreatedOn"}
                            tableDateColumns={["CreatedOn"]}
                            tableIgnoreSortColumns={["Description"]}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(CaseActivities)