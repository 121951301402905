import React from "react";

// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeopleIcon from "@material-ui/icons/People";

// core components
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomEntrySelect from "components/CustomInput/CustomEntrySelect";

// Extra libs
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { graphApiGet } from "utils/graphApi/graphApiWebRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const requiredPortalPermissions = [    
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.AGENTS.VIEW",
    "UCALLMANAGER.AGENTS.EDIT",
    "UCALLMANAGER.QUEUES.EDIT"
]

class AgentsPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            interfacesLoaded: false,
            agentsLoaded: false,
            error: null,

            // State
            loadingOffice365Users: false,

            // Meta
            foundOffice365Users: "",
            data: "",
            
            // Dialogs           
            addAgentsDialogOpen: false,
            addingAgent: false,

            updateAgentsDialogOpen: false,
            updatingAgent: false,

            confirmDialogOpen: false,
            removingAgent: false,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            interfaces: [],
            agents: [],

            // Add / update agent
            oldAgent: "",
            agent: "", 
            number: "",
            fallbackNumber: "",
            selectedInterfaceTypeId: "",
            isAlwaysAvailableChecked: false,
            isRingInUseChecked: false,
            selectedOffice365User: "",
            foundUserPrincipalName: "",            

            agentID: "",

            // Remove agent
            agentToRemove: "",

            searchText: "",
        }
    }    
    
    handleAgentSelection = (id, userPrincipalName) => {
        //return function () {
            this.setState({
                selectedOffice365User: id,
                foundUserPrincipalName: userPrincipalName
            })   
         //}             
    };

    toggleAddAgentsDialogOpen = (isOpen) => 
    {
        this.setState({
            addAgentsDialogOpen: !isOpen
        })
    }

    toggleUpdateAgentsDialogOpen = (isOpen) => 
    {
        this.setState({
            updateAgentsDialogOpen: !isOpen
        })
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    setSearch = (searchTerm) => {
        const { foundOffice365Users, searchText, data } = this.state;     
          
        const search = foundOffice365Users.filter(
            item =>
                item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1,
        );
        if (searchTerm == "" || searchTerm.length == 0) {
            this.setState( { data: foundOffice365Users, searchText: searchTerm });
            this.getOffice365UsersFromGraphApi();
        }
        else if (searchTerm.length >= 3) {   
            console.log(searchTerm); 
            this.getFilteredOffice365UsersFromGraphApi(searchTerm);    
            this.setState( { data: search, searchText: searchTerm });        
        }  
        else {
            this.setState( { searchText: searchTerm });
        }
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) &&
            !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                interfacesLoaded: true,
                agentsLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get interface & agents
        this.getInterfaces();
        this.getAgents();
    }

    getInterfaces()
    {
        // Check if user has permission to fetch interfaces. If not, leave interfaces array empty.
        if (!PMUtils.userHasAllRequiredPermissions(["UCALLMANAGER.AGENTS.EDIT"], globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                interfacesLoaded: true,
                interfaces: []
            });

            return;
        }

        // Get interfaces
        apiGet("ucm/interfaces").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                interfacesLoaded: true,
                interfaces: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                interfacesLoaded: true,
                error: error.data
            });
        });
    }

    getAgents()
    {
        apiGet("ucm/agents").then((result) =>            
        {
            var fetchedAgents = [];
            
            for (var i = 0; i < result.data.length; i++)
            {
                var agent = result.data[i];

                var newAgentRecord = 
                {
                    AgentID: agent.AgentID,
                    Agent: agent.Agent,
                    Number: agent.Number,
                    IsAlwaysAvailable: agent.IsAlwaysAvailable,
                    Penalty: agent.Penalty,
                    FallbackNumber: agent.FallbackNumber,
                    Office365UserId: agent.Office365UserId,
                    UserPrincipalName: agent.UserPrincipalName,
                    InterfaceType: agent.InterfaceType,
                    RingInUse: agent.RingInUse
                }

                fetchedAgents.push(newAgentRecord);
            }

            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                agentsLoaded:true,
                agents: fetchedAgents
            });
        },
        (error) =>
        {
            this.setState(
            {
                agentsLoaded: true,
                error: error.data
            });
        });
    }

    addAgent()
    {
        this.setState({addingAgent: true})

        // TODO: authentication
        const { agent, number, fallbackNumber, selectedInterfaceTypeId, isAlwaysAvailableChecked, addAgentsDialogOpen, selectedOffice365User, isRingInUseChecked, foundUserPrincipalName } = this.state;

        // Create request body
        var requestBody = 
        {
            Agent: agent,
            Number: number,
            FallbackNumber: fallbackNumber,
            InterfaceTypeId: selectedInterfaceTypeId,
            IsAlwaysAvailable: isAlwaysAvailableChecked,
            Office365UserId: selectedOffice365User,
            UserPrincipalName: foundUserPrincipalName,
            RingInUse: isRingInUseChecked
        }

        // Add agent
        apiPostJson("ucm/agents/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingAgent: false,

                fallbackNumber: ""
            });

            this.toggleAddAgentsDialogOpen(addAgentsDialogOpen);
            this.showNotification("success", "Agent added!");

            this.getAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingAgent: false
            });

            this.showNotification("danger", error.data);            
        });
    }

    updateAgent()
    {
        this.setState({updatingAgent: true})

        // TODO: authentication
        const { oldAgent, agent, agentID, number, fallbackNumber, selectedInterfaceTypeId, isAlwaysAvailableChecked, selectedOffice365User, updateAgentsDialogOpen, isRingInUseChecked, foundUserPrincipalName } = this.state;

        // Create request body
        var requestBody = 
        {
            OldAgent: oldAgent,
            AgentID: agentID,
            Agent : agent,
            Number: number,
            FallbackNumber : fallbackNumber,
            InterfaceTypeId: selectedInterfaceTypeId,
            IsAlwaysAvailable: isAlwaysAvailableChecked,
            Office365UserId: selectedOffice365User,  
            UserPrincipalName: foundUserPrincipalName,                     
            RingInUse: isRingInUseChecked
        }

        // Add agent
        apiPostJson("ucm/agents/update", requestBody).then(() =>        
        {
            this.setState(
            {
                updatingAgent: false
            });

            this.toggleUpdateAgentsDialogOpen(updateAgentsDialogOpen);
            this.showNotification("success", "Agent updated!");

            this.getAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                updatingAgent: false
            });

            this.showNotification("danger", error.data);
        });
    }

    setUpdateAgentStateValues(agentToUpdate)
    {
        console.log(agentToUpdate.RingInUse);
        this.setState({
            oldAgent: agentToUpdate.Agent,
            agentID: agentToUpdate.AgentID,
            agent: agentToUpdate.Agent, 
            number: agentToUpdate.Number,
            fallbackNumber: agentToUpdate.FallbackNumber,
            selectedInterfaceTypeId: agentToUpdate.InterfaceType.ID,
            isAlwaysAvailableChecked: agentToUpdate.IsAlwaysAvailable,
            selectedOffice365User: agentToUpdate.Office365UserId,
            foundUserPrincipalName: agentToUpdate.UserPrincipalName,
            isRingInUseChecked: agentToUpdate.RingInUse
        })
    }

    removeAgent()
    {
        this.setState({removingAgent: true});

        const { agentToRemove, confirmDialogOpen } = this.state;

        // Remove agent
        apiPost("ucm/agents/remove?agentId=" + agentToRemove).then(() =>        
        {
            this.setState(
            {
                removingAgent: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("success", "Agent removed!");

            this.getAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingAgent: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    getFilteredOffice365UsersFromGraphApi(searchTerm)
    {
        this.setState({
            loadingOffice365Users: true
        });

        const { searchText } = this.state;

        graphApiGet("users?$select=id,displayName,userPrincipalName&$filter=startswith(userPrincipalName,'" + searchTerm + "')").then((result) =>
        //graphApiGet("users?$select=id,displayName,userPrincipalName &$filter=userType eq 'Member'").then((result) =>
        {
            var office365Users = [];
            
            for (var i = 0; i < result.data.value.length; i++)
            {
                var office365User = result.data.value[i];

                var newUserRecord = 
                {
                    id: office365User.id,
                    name: office365User.displayName,
                    displayName: office365User.displayName + " (" + office365User.userPrincipalName + ")",
                    userPrincipalName: office365User.userPrincipalName
                }

                office365Users.push(newUserRecord);
            }

            office365Users.sort(this.graphApiUsersComparer);

            this.setState({
                foundOffice365Users: office365Users,
                data: office365Users,
                loadingOffice365Users: false
            });
        },
        (error) =>
        {
            this.showNotification("danger", "Could not fetch users from Office 365.");
        }); 
    }

    getOffice365UsersFromGraphApi()
    {
        this.setState({
            loadingOffice365Users: true
        });

        //graphApiGet("users?$select=id,displayName,userPrincipalName &$filter=userType eq 'Member'&$filter=startswith(displayName,'')").then((result) =>
        graphApiGet("users?$select=id,displayName,userPrincipalName").then((result) =>
        {
            var office365Users = [];
            
            for (var i = 0; i < result.data.value.length; i++)
            {
                var office365User = result.data.value[i];

                var newUserRecord = 
                {
                    id: office365User.id,
                    name: office365User.displayName,
                    displayName: office365User.displayName + " (" + office365User.userPrincipalName + ")",
                    userPrincipalName: office365User.userPrincipalName
                }

                office365Users.push(newUserRecord);
            }

            office365Users.sort(this.graphApiUsersComparer);

            this.setState({
                foundOffice365Users: office365Users,
                data: office365Users,
                loadingOffice365Users: false
            });
        },
        (error) =>
        {
            this.showNotification("danger", "Could not fetch users from Office 365.");
        }); 
    }

    graphApiUsersComparer(user1, user2) 
    {
        if ( user1.displayName < user2.displayName ){
          return -1;
        }
        if ( user1.displayName > user2.displayName ){
          return 1;
        }
        return 0;
      }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {
        const { error, interfacesLoaded, agentsLoaded, loadingOffice365Users,
                foundOffice365Users, searchText, data,
                addAgentsDialogOpen, addingAgent, updateAgentsDialogOpen, updatingAgent, confirmDialogOpen, removingAgent, 
                toastColor, toastText, toastOpen, 
                agents, interfaces, 
                agent, number, fallbackNumber, selectedInterfaceTypeId, isAlwaysAvailableChecked, isRingInUseChecked, selectedOffice365User, foundUserPrincipalName } = this.state;
        const { classes } = this.props;

        if (!interfacesLoaded || !agentsLoaded)
        {
            return <LoadingIndicator loadingText="Loading agents..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        } 

        const handleSearch = (event) => {
            //if (agentsLoaded)
            //{ 
                this.setSearch(event);
            //}
        };

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Add agents dialog */}
                <Dialog open={addAgentsDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleAddAgentsDialogOpen(addAgentsDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.addAgent()}}>
                        <DialogTitle id="form-dialog-title">Add new agent</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, fill in the agent details.
                            </DialogContentText>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Agent Alias"
                                        id="agent-alias"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true,
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({ agent: value}) }
                                        }
                                        inputProps={{
                                            autoFocus: true,
                                            maxLength: 200
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Select interface type"
                                        id="interface-type"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={interfaces}
                                        keyColumn="ID"
                                        valueColumn="DisplayName"
                                        selectedValue={selectedInterfaceTypeId}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({selectedInterfaceTypeId: value}); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Is always available"
                                        id="select-isalwaysavailable-new"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={isAlwaysAvailableChecked ? "Yes" : "No"}
                                        onValueChange =
                                        {
                                            (value) => { this.setState({isAlwaysAvailableChecked: (value === "Yes")}); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Ring in use"
                                        id="select-ringinuse-new"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={isRingInUseChecked ? "Yes" : "No"}
                                        onValueChange=
                                        {
                                            (value) => { this.setState({ isRingInUseChecked: (value === "Yes") }); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>                            
                                    <GridContainer justify="center">
                                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <h4 style={{marginTop:"44px", textAlign:"right"}}>+</h4>
                                        </GridItem>
                                        <GridItem xs={10} sm={11} md={11} lg={11} xl={11}>
                                            <CustomInput
                                                labelText="Number or Extension (numeric)"
                                                id="number"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ number: value}) }
                                                }
                                                inputProps ={{
                                                    maxLength: 13,
                                                    pattern: "^[0-9]*$",
                                                    title: "Numbers only, max 13 characters allowed" 
                                                }}
                                            />                                    
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>  
                                    <GridContainer justify="center">
                                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <h4 style={{marginTop:"44px", textAlign:"right"}}>+</h4>
                                        </GridItem>
                                        <GridItem xs={10} sm={11} md={11} lg={11} xl={11}>
                                            <CustomInput
                                                labelText="Fallback Phone Number"
                                                id="fallback-number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ fallbackNumber: value}) }
                                                }
                                                inputProps ={{
                                                    maxLength: 13,
                                                    pattern: "^[0-9]*$",
                                                    title: "Numbers only, max 13 characters allowed" 
                                                }}
                                            />                                    
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                
                                {
                                    foundOffice365Users ?
                                    (                                        
                                        <GridItem xs={12} sm={12} md={12}>  
                                            <CustomInput
                                                labelText="Search by sign-in address (first 100 shown)"
                                                id="search"
                                                
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: false
                                                }}
                                                onValueChange =
                                                
                                                    {handleSearch}
                                                
                                                inputProps={{
                                                    autoFocus: true,
                                                    maxLength: 200,
                                                    defaultValue: searchText
                                                }}
                                            />
                                            <CustomEntrySelect
                                                onPress={this.handleAgentSelection}
                                                labelText="Select Microsoft 365 user (first 100 shown)"
                                                id="select-office365user-new"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={data}
                                                keyColumn="id"
                                                valueColumn="displayName"                                                
                                                selectedValue={selectedOffice365User}
                                                selectedName={foundUserPrincipalName}
                                                onValueChange=
                                                {
                                                    (value) => { 
                                                        
                                                        //Reset the user principal name first
                                                        this.setState({foundUserPrincipalName: null});

                                                        if (data.length > 0) {

                                                            for (var i = 0; i < data.length; i++)
                                                            {                                                                
                                                                if (data[i].id == value) {        
                                                                    this.setState({foundUserPrincipalName: data[i].userPrincipalName});                                                                    
                                                                }
                                                            }

                                                        }
                                                        
                                                        this.setState({selectedOffice365User: value}) 
                                                    }
                                                }
                                            />                                        
                                        </GridItem>
                                    )
                                    : 
                                    (
                                        <GridItem xs={12} sm={12} md={12}>  
                                            <Button disabled={loadingOffice365Users} color="info" onClick={() => this.getOffice365UsersFromGraphApi()}>
                                                { loadingOffice365Users ? "Connecting to Microsoft 365..." : "Connect to Microsoft 365 " }
                                            </Button>
                                        </GridItem>
                                    )
                                }
                            </GridContainer>

                            {/* <FormControlLabel
                                control={<Checkbox color="primary" checked={isAlwaysAvailableChecked} onChange={(event) => this.setState({ isAlwaysAvailableChecked: event.target.checked })}/>}
                                label="Is always available"
                            /> */}


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleAddAgentsDialogOpen(addAgentsDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={addingAgent} type="submit" color="secondary">
                                {addingAgent ? "Adding agent..." : "Add agent"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Update agents dialog */}
                <Dialog open={updateAgentsDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleUpdateAgentsDialogOpen(updateAgentsDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.updateAgent()}}>
                        <DialogTitle id="form-dialog-title">Update agent</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, fill in the agent details.
                            </DialogContentText>
                            
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Agent Alias"
                                        id="agent-alias"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true,
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({ agent: value}) }
                                        }
                                        inputProps={{
                                            autoFocus: true,
                                            maxLength: 200,
                                            defaultValue: agent
                                        }}
                                    />
                                </GridItem>
                                
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Select interface type"
                                        id="interface-type"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={interfaces}
                                        keyColumn="ID"
                                        valueColumn="DisplayName"
                                        selectedValue={selectedInterfaceTypeId}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({selectedInterfaceTypeId: value}); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Is always available"
                                        id="select-isalwaysavailable-new"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={isAlwaysAvailableChecked ? "Yes" : "No"}
                                        onValueChange =
                                        {
                                            (value) => { this.setState({isAlwaysAvailableChecked: (value === "Yes")}); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomSelect
                                        labelText="Ring in use"
                                        id="select-ringinuse-new"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={isRingInUseChecked ? "Yes" : "No"}
                                        onValueChange=
                                        {
                                            (value) => { this.setState({ isRingInUseChecked: (value === "Yes") }); }
                                        }
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <h4 style={{marginTop:"44px", textAlign:"right"}}>+</h4>
                                        </GridItem>
                                        <GridItem xs={10} sm={11} md={11} lg={11} xl={11}>
                                            <CustomInput
                                                labelText="Number or Extension (numeric)"
                                                id="number"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ number: value}) }
                                                }
                                                inputProps ={{
                                                    maxLength: 13,
                                                    pattern: "^[0-9]*$",
                                                    title: "Numbers only, max 13 characters allowed", 
                                                    defaultValue: number
                                                }}
                                            />                                    
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem xs={1} sm={1} md={1} lg={1} xl={1}>
                                            <h4 style={{marginTop:"44px", textAlign:"right"}}>+</h4>
                                        </GridItem>
                                        <GridItem xs={10} sm={11} md={11} lg={11} xl={11}>
                                            <CustomInput
                                                labelText="Fallback Phone Number"
                                                id="fallback-number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ fallbackNumber: value}) }
                                                }
                                                inputProps ={{
                                                    maxLength: 13,
                                                    pattern: "^[0-9]*$",
                                                    title: "Numbers only, max 13 characters allowed", 
                                                    defaultValue: fallbackNumber
                                                }}
                                            />                                    
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>

                            {
                                foundOffice365Users ?
                                    (                                        
                                        <GridItem xs={12} sm={12} md={12}> 
                                            <CustomInput
                                                labelText="Search by sign-in address (first 100 shown)"
                                                id="search"
                                                
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: false
                                                }}
                                                onValueChange =
                                                
                                                    {handleSearch}
                                                
                                                inputProps={{
                                                    autoFocus: true,
                                                    maxLength: 200,
                                                    defaultValue: searchText
                                                }}
                                            />
                                            <CustomEntrySelect
                                                onPress={this.handleAgentSelection}
                                                labelText="Select Microsoft 365 user (first 100 shown)"
                                                id="select-office365user-new"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={data}
                                                keyColumn="id"
                                                valueColumn="displayName"
                                                selectedValue={selectedOffice365User}
                                                selectedName={foundUserPrincipalName}
                                                onValueChange=
                                                {
                                                    (value) => { 
                                                        
                                                        //Reset the user principal name first
                                                        this.setState({foundUserPrincipalName: null});

                                                        if (data.length > 0) {

                                                            for (var i = 0; i < data.length; i++)
                                                            {
                                                                if (data[i].id == value) {        
                                                                    this.setState({foundUserPrincipalName: data[i].userPrincipalName});                                                                    
                                                                }
                                                            }

                                                        }
                                                        
                                                        this.setState({selectedOffice365User: value}) 
                                                    }
                                                }
                                            />                                        
                                        </GridItem>
                                    )
                                    : 
                                    (  
                                        <GridItem xs={12} sm={12} md={12}>  
                                            <Button disabled={loadingOffice365Users} color="info" onClick={() => this.getOffice365UsersFromGraphApi()}>
                                                { loadingOffice365Users ? "Connecting to Microsoft 365..." : "Connect to Microsoft 365 " }
                                            </Button>                                   
                                        </GridItem>
                                    )
                                }

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleUpdateAgentsDialogOpen(updateAgentsDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={updatingAgent} type="submit" color="secondary">
                                {updatingAgent ? "Updating agent..." : "Update agent"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Remove agent confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this agent?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will also remove the agent from all assigned queues.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingAgent} onClick={() => this.removeAgent()} color="danger">
                            {removingAgent ? "Removing agent..." : "Remove agent"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Cards */}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        {/* Agents overview card */}
                        <Card>
                            <CardHeader color="primary">
                                <GridContainer>
                                    <GridItem xs={10} sm={11} md={11}>
                                        <h4 className={classes.cardTitleWhite}>Agents</h4>
                                        <p className={classes.cardCategoryWhite}>Available agents</p>
                                    </GridItem>
                                    <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                        <PeopleIcon/>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                {
                                    PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.AGENTS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                    ?
                                    <Typography align="right">
                                        <Button color="secondary" justIcon round onClick={() => this.toggleAddAgentsDialogOpen(addAgentsDialogOpen)} >
                                            <AddIcon />
                                        </Button>                                    
                                    </Typography>
                                    : null
                                }

                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Agent", "Interface Type", "Number or Extension", "Is Always Available", "Ring In Use", "Fallback Phone Number", "Microsoft 365 User" ]}
                                    tableHeadDataMap={["Agent", "InterfaceType.DisplayName", "Number", "IsAlwaysAvailable", "RingInUse", "FallbackNumber", "UserPrincipalName" ]}
                                    tableData={agents}
                                    onDeleteButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.AGENTS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        (clickedItem) => {this.setState({agentToRemove: clickedItem.AgentID}); this.toggleConfirmDialogOpen(confirmDialogOpen);}
                                        : null
                                    }
                                    onEditButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.AGENTS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        (clickedItem) => { this.setUpdateAgentStateValues(clickedItem); this.toggleUpdateAgentsDialogOpen(updateAgentsDialogOpen); }
                                        : null
                                    }
                                    isSortable={true}
                                    initialOrderBy={"Agent"}
                                    tableNumericColumns={["Number", "FallbackNumber"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );      
    }
}

export default withStyles(styles)(AgentsPage)