import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import HolidayIcon from "@material-ui/icons/Today";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Extra libs
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const requiredPortalPermissions = [    
    "UCALLMANAGER.ACCESS"
]

class HolidaysPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoaded: false,

            // Dialogs           
            addHolidaysDialogOpen: false,
            addingHoliday: false,

            updateHolidaysDialogOpen: false,
            updatingHoliday: false,

            confirmDialogOpen: false,
            removingHoliday: false,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            holidays: [],
            minHolidayDate: new Date().getFullYear() + "-01-01",

            // New holiday
            newHolidayDate: new Date().getFullYear() + "-01-01",
            newHolidayName: null,

            // Update holiday
            holidayToUpdate: null,

            // Removing holiday
            holidayToRemove: null
        }
    }
    
    toggleAddHolidaysDialogOpen = (isOpen) => 
    {
        this.setState({
            addHolidaysDialogOpen: !isOpen
        })
    }

    toggleUpdateHolidaysDialogOpen = (isOpen) => 
    {
        this.setState({
            updateHolidaysDialogOpen: !isOpen
        })
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get holidays
        this.getHolidays();
    }

    getHolidays()
    {
        apiGet("ucm/holidays").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                holidays: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    addHoliday()
    {
        this.setState({addingHoliday: true})

        // TODO: authentication
        const { newHolidayDate, newHolidayName, addHolidaysDialogOpen } = this.state;

        // Create request body
        var requestBody = 
        {
            day : newHolidayDate,
            description: newHolidayName
        }

        // Add holiday
        apiPostJson("ucm/holidays/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingHoliday: false
            });

            this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen);
            this.showNotification("success", "Holiday added!");

            this.getHolidays();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingHoliday: false
            });

            this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    updateHoliday()
    {
        this.setState({updatingHoliday: true})

        // TODO: authentication
        const { holidayToUpdate, updateHolidaysDialogOpen } = this.state;

        // Create request body
        var requestBody = 
        {
            id: holidayToUpdate.ID,
            date : holidayToUpdate.Date,
            description: holidayToUpdate.Description
        }

        // Add holiday
        apiPostJson("ucm/holidays/update", requestBody).then(() =>        
        {
            this.setState(
            {
                updatingHoliday: false
            });

            this.toggleUpdateHolidaysDialogOpen(updateHolidaysDialogOpen);
            this.showNotification("success", "Holiday updated!");

            this.getHolidays();
        },
        (error) =>
        {
            this.setState(
            {
                updatingHoliday: false
            });

            this.toggleUpdateHolidaysDialogOpen(updateHolidaysDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    removeHoliday()
    {
        this.setState({removingHoliday: true});

        const { holidayToRemove, confirmDialogOpen } = this.state;

        // Create request body
        var requestBody = 
        {
            id : holidayToRemove.ID,
            sipcustomer: holidayToRemove.PrimarySipCustomer
        }

        // Remove holiday
        apiPostJson("ucm/holidays/remove", requestBody).then(() =>        
        {
            this.setState(
            {
                removingHoliday: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("success", "Holiday removed!");

            this.getHolidays();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingHoliday: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    /**
     * Takes dd-mm-yyyy date string and formats it to yyyy-mm-dd
     * @param {*} date Date string to format.
     */
    formatDate(date)
    {
        var day = date.substr(0,2),
        month = date.substr(3,2),
        year = date.substr(6,4);

        return year + "-" + month + "-" + day;
    }

    setUpdateData(clickedItem)
    {
        var holidayToUpdate = 
        {
            ID: clickedItem.ID,
            Date: this.formatDate(clickedItem.Date),
            Description: clickedItem.Description
        }

        this.setState({holidayToUpdate: holidayToUpdate})
    }

    render() 
    {
        const { error, isLoaded, 
                addHolidaysDialogOpen, addingHoliday, 
                updateHolidaysDialogOpen, updatingHoliday, 
                confirmDialogOpen, removingHoliday, 
                toastOpen, toastColor, toastText, 
                holidays, minHolidayDate,
                newHolidayDate, newHolidayName, holidayToUpdate  } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading holidays..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }     
        
        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Add holidays dialog */}
                <Dialog open={addHolidaysDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.addHoliday()}}>
                        <DialogTitle id="form-dialog-title">Add Holiday</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, enter the desired day below.
                            </DialogContentText>

                            <CustomInput
                                labelText="Select holiday"
                                id="holiday-date"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({newHolidayDate: value}) }
                                }
                                inputProps={{
                                    autoFocus: true,
                                    type: "date",
                                    defaultValue: newHolidayDate,
                                    min: minHolidayDate
                                }}
                            />

                            <CustomInput
                                labelText="Description"
                                id="holiday-description"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({newHolidayName: value}); }
                                }
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={addingHoliday} type="submit" color="secondary">
                                {addingHoliday ? "Adding holiday..." : "Add holiday"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Update holidays dialog */}
                <Dialog open={updateHolidaysDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleUpdateHolidaysDialogOpen(updateHolidaysDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.updateHoliday()}}>
                        <DialogTitle id="form-dialog-title">Update Holiday</DialogTitle>
                        <DialogContent>

                            <CustomInput
                                labelText="Select holiday"
                                id="holiday-date"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { holidayToUpdate.Date = value; this.setState({holidayToUpdate: holidayToUpdate}) }
                                }
                                inputProps={{
                                    autoFocus: true,
                                    type: "date",
                                    min: newHolidayDate,
                                    defaultValue: holidayToUpdate ? holidayToUpdate.Date : ""
                                }}
                            />

                            <CustomInput
                                labelText="Description"
                                id="holiday-description"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { holidayToUpdate.Description = value; this.setState({holidayToUpdate: holidayToUpdate}); }
                                }
                                inputProps={{
                                    maxLength: 100,
                                    defaultValue: holidayToUpdate ? holidayToUpdate.Description : ""
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleUpdateHolidaysDialogOpen(updateHolidaysDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={updatingHoliday} type="submit" color="secondary">
                                {updatingHoliday ? "Updating holiday..." : "Update holiday"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Remove holiday confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this holiday?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will also remove the holiday from all assigned queues.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingHoliday} onClick={() => this.removeHoliday()} color="danger">
                            {removingHoliday ? "Removing holiday..." : "Remove holiday"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Cards */}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        {/* Holidays overview card */}
                        <Card>
                            <CardHeader color="primary">
                                <GridContainer>
                                    <GridItem xs={10} sm={11} md={11}>
                                        <h4 className={classes.cardTitleWhite}>Holidays</h4>
                                        <p className={classes.cardCategoryWhite}>Holidays this year</p>
                                    </GridItem>
                                    <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                        <HolidayIcon/>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                {
                                    PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.HOLIDAYS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                    ?
                                    <Typography align="right">
                                        <Button color="secondary" justIcon round onClick={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} >
                                            <AddIcon />
                                        </Button>                                    
                                    </Typography>
                                    : null
                                }

                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Day", "Description"]}
                                    tableHeadDataMap={["Date", "Description"]}
                                    tableData={holidays}
                                    onEditButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.HOLIDAYS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        (clickedItem) => { this.setUpdateData(clickedItem); this.toggleUpdateHolidaysDialogOpen(updateHolidaysDialogOpen);}
                                        : null
                                    }
                                    onDeleteButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.HOLIDAYS.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        (clickedItem) => {this.setState({holidayToRemove: clickedItem}); this.toggleConfirmDialogOpen(confirmDialogOpen);}
                                        : null
                                    }
                                    isSortable={true}
                                    initialOrderBy={"Date"}
                                    tableDateColumns={["Date"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );     
    }
}

export default withStyles(styles)(HolidaysPage)