import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeopleIcon from "@material-ui/icons/People";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

class QueueAgents extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoadedDetails: false,
            isLoadedQueueAgents: false,
            isLoadedAllAgents: false,

            // Dialogs           
            addAgentDialogOpen: false,
            addingAgent: false, 
            updateAgentDialogOpen: false,
            updatingAgent: false,
            confirmDialogOpen: false,
            removingAgent: false,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            queueDetails: null,
            queueAgents: [],
            allAgents: [],
            
            // New agent
            agentIdToAssign: "",
            agentPenalty: 0,

            // Removing agent
            agentToRemove: null,
        }
    }

    componentDidMount()
    {
        this.getQueueDetails();
        this.getQueueAgents();
        this.getAllAgents();
    }

    getQueueAgents()
    {
        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        apiGet("ucm/queues/agents?queueName=" + queueName).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedQueueAgents: true,
                queueAgents: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedQueueAgents: true,
                error: error.data
            });
        });
    }

    getQueueDetails()
    {
        // Check if queue data is already fetched, if not, fetch it again.
        if (globalData.currentlyViewingQueue !== null)
        {
            this.setState(
            {
                isLoadedDetails: true,
                queueDetails: globalData.currentlyViewingQueue
            });

            return;
        }

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");
        
        // Get details of queue
        apiGet("ucm/queues/get?queueName=" + queueName).then((result) =>            
        {
            globalData.currentlyViewingQueue = result.data;

            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedDetails:true,
                queueDetails: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedDetails: true,
                error: error.data
            });
        });
    }

    getAllAgents()
    {
        // Check if user has permission to fetch customer agents. If not, leave agents array empty.
        if (!PMUtils.userHasAllRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoadedAllAgents: true,
                allAgents: []
            });

            return;
        }

        // Get all agents of customer
        apiGet("ucm/agents").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedAllAgents:true,
                allAgents: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedAllAgents:true,
                error: error.data
            });
        });
    }

    addAgent()
    {
        this.setState({addingAgent: true})

        // TODO: authentication
        const { agentIdToAssign, addAgentDialogOpen, agentPenalty } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create request body
        var requestBody = 
        {
            agentId : agentIdToAssign,
            queueName : queueName,
            penalty : agentPenalty
        }

        // Add agent
        apiPostJson("ucm/queues/agents/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingAgent: false
            });

            this.toggleAddAgentDialogOpen(addAgentDialogOpen);
            this.showNotification("success", "Agent assigned to queue!");

            this.getQueueAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingAgent: false
            });

            this.toggleAddAgentDialogOpen(addAgentDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    updateAgent()
    {
        this.setState({updatingAgent: true})

        // TODO: authentication
        const { agentIdToAssign, updateAgentDialogOpen, agentPenalty } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create request body
        var requestBody = 
        {
            AgentID : agentIdToAssign,
            QueueName : queueName,
            Penalty : agentPenalty
        }

        // Update agent
        apiPostJson("ucm/queues/agents/update", requestBody).then(() =>        
        {
            this.setState(
            {
                updatingAgent: false
            });

            this.toggleUpdateAgentDialogOpen(updateAgentDialogOpen);
            this.showNotification("success", "Agent updated!");

            this.getQueueAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                updatingAgent: false
            });

            this.toggleUpdateAgentDialogOpen(updateAgentDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    removeAgent()
    {
        this.setState({removingAgent: true});

        const { agentToRemove, confirmDialogOpen } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create request body
        var requestBody = 
        {
            agentID : agentToRemove.AgentID,
            queueName : queueName
        }

        // Add agent
        apiPostJson("ucm/queues/agents/remove", requestBody).then(() =>        
        {
            this.setState(
            {
                removingAgent: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("success", "Agent removed from queue!");

            this.getQueueAgents();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingAgent: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    setUpdateAgentStateValues(agentToUpdate)
    {
        this.setState({
            agentIdToAssign: agentToUpdate.AgentID,
            agentPenalty: agentToUpdate.Penalty
        })
    }

    toggleAddAgentDialogOpen = (isOpen) => 
    {
        this.setState({
            addAgentDialogOpen: !isOpen
        })
    }

    toggleUpdateAgentDialogOpen = (isOpen) => 
    {
        this.setState({
            updateAgentDialogOpen: !isOpen
        })
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {    
        const { isLoadedDetails, isLoadedQueueAgents, isLoadedAllAgents, 
            error, addAgentDialogOpen, addingAgent, updateAgentDialogOpen, updatingAgent, confirmDialogOpen, removingAgent, toastOpen, toastColor, toastText, queueDetails, queueAgents, allAgents, agentIdToAssign, agentPenalty } = this.state;
        const { classes } = this.props; 

        if (!isLoadedDetails || !isLoadedQueueAgents || !isLoadedAllAgents)
        {   
            return <LoadingIndicator loadingText="Loading agents..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Add agents dialog */}
                <Dialog open={addAgentDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleAddAgentDialogOpen(addAgentDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.addAgent()}}>
                        <DialogTitle id="form-dialog-title">Assign agent to queue</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, select the agent you would like to assign to the queue.
                            </DialogContentText>

                            <CustomSelect
                                labelText="Select agent"
                                id="select-agent"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                inputProps={{
                                    autoFocus: true
                                }}
                                values={allAgents}
                                keyColumn="AgentID"
                                valueColumn="Agent"
                                selectedValue={agentIdToAssign}
                                onValueChange = 
                                {
                                    (value) => { this.setState({agentIdToAssign: value}); }
                                }
                            />

                            <CustomInput
                                labelText="Priority (0 - 100)"
                                id="agent-penalty"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({agentPenalty: value}) }
                                }
                                inputProps={{
                                    pattern: "^[0-9][0-9]?$|^100$",
                                    maxLength: 3,
                                    title: "Only numbers between 0 and 100 allowed",
                                    defaultValue: 0
                                }}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleAddAgentDialogOpen(addAgentDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={addingAgent} type="submit" color="secondary">
                                {addingAgent ? "Assigning agent..." : "Assign agent"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                
                {/* Update agent dialog */}
                <Dialog open={updateAgentDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleUpdateAgentDialogOpen(updateAgentDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.updateAgent()}}>
                        <DialogTitle id="form-dialog-title">Update Queue Agent</DialogTitle>
                        <DialogContent>
                            <CustomInput
                                labelText="Priority (0 - 100)"
                                id="agent-penalty-update"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => { this.setState({agentPenalty: value}) }
                                }
                                inputProps={{
                                    pattern: "^[0-9][0-9]?$|^100$",
                                    maxLength: 3,
                                    title: "Only numbers between 0 and 100 allowed",
                                    defaultValue: agentPenalty
                                }}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleUpdateAgentDialogOpen(updateAgentDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={updatingAgent} type="submit" color="secondary">
                                {updatingAgent ? "Updating agent..." : "Update agent"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Remove agent confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this agent from the queue?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will not affect other queues.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingAgent} onClick={() => this.removeAgent()} color="danger">
                            {removingAgent ? "Removing agent..." : "Remove agent"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>Queue Agents</h4>
                                <p className={classes.cardCategoryWhite}>Agents linked to queue</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <PeopleIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions) && queueDetails.QueueType.Type !== "shift"
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => { this.setState({agentPenalty: 0}); this.toggleAddAgentDialogOpen(addAgentDialogOpen) }} >
                                    <AddIcon />
                                </Button>                                    
                            </Typography>
                            : null
                        }

                        {
                            queueDetails.QueueType.Type === "shift"
                            ?
                            <p><i>These agents are dynamically fetched from MS Teams shifts (refreshed hourly).</i></p>
                            :
                            null
                        }

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Agent", "Interface Type", "Number or Extension", "Is Always Available", "Priority"]}
                            tableHeadDataMap={["Agent", "InterfaceType.DisplayName", "Number", "IsAlwaysAvailable", "Penalty"]}
                            tableData={queueAgents}
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions) && queueDetails.QueueType.Type !== "shift"
                                ?
                                (clickedItem) => {this.setState({agentToRemove: clickedItem}); this.toggleConfirmDialogOpen(confirmDialogOpen);}
                                : null
                            }
                            onEditButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions) && queueDetails.QueueType.Type !== "shift"
                                ?
                                (clickedItem) => { this.setUpdateAgentStateValues(clickedItem); this.toggleUpdateAgentDialogOpen(updateAgentDialogOpen) }
                                : null
                            }
                            isSortable={true}
                            initialOrderBy={"Agent"}
                            tableNumericColumns={["Number", "Penalty"]}
                        />
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(QueueAgents)