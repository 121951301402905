/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Extra libs
import Cookies from "js-cookie";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import globalData from "variables/globalData.jsx";
import Constants from "variables/constants.jsx"

import { connect } from "react-redux";
import { addArticle } from "../stateManagement/actions/index";

const switchRoutes = (
  <Switch>
    {
      routes.map((prop, key) => 
      {
        return (
          <Route
            exact
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      })
    }
  </Switch>
);

const mapStateToProps = state => {
  return { articles: state.articles };
};

function mapDispatchToProps(dispatch) {
  return {
    addArticle: article => dispatch(addArticle(article))
  };
}

class Dashboard extends React.Component 
{
  constructor(props) 
  {
    super(props);
    this.state = 
    {
      error: null,
      isLoaded: false,

      image: image,
      color: "neutral",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false
    };
  }

  handleImageClick = image => 
  {
    this.setState({ image: image });
  };

  handleColorClick = color => 
  {
    this.setState({ color: color });
  };

  handleFixedClick = () => 
  {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  
  handleDrawerToggle = () => 
  {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  resizeFunction = () => 
  {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() 
  {
    // TODO: Make use of util method
    var token = Cookies.get("auth");

    fetch(Constants.ApiBaseUrl + "users/me",
    {
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => 
    {
        if (response.status !== 200)
        {
            throw new Error(response.status);
        }

        return response.json()
    })
    .then((result) =>            
    {
        // Store user data globally
        globalData.loggedInUser = result;

        this.setState(
        {
            isLoaded:true
        });

        if (navigator.platform.indexOf("Win") > -1) {
          const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    },
    (error) =>
    {
        this.setState(
        {
            isLoaded: true,
            error: error
        });
    });
  } 

  componentDidUpdate(e) 
  {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() 
  {    
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() 
  {
    const { error, isLoaded } = this.state;
    const { classes, ...rest } = this.props;

    if (!isLoaded)
    {
        return <LoadingIndicator loadingText="Loading portal..." bigSpinner={true} fullScreen></LoadingIndicator>
    }
    else if (error)
    {
      // TODO: Error logging
      return <ErrorIndicator errorText={ "Oops, something went wrong: " + error.message}></ErrorIndicator>
    }

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={globalData.loggedInUser.Organization.Name}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        
        <div className={classes.mainPanel} ref="mainPanel" id="mainpanel">
          <Navbar
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />

          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(Dashboard));
