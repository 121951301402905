/* eslint-disable */
import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components

import AzureAuthentication from "views/Authentication/Azure.jsx";
import ResetPassword from "views/Authentication/ResetPassword.jsx";
import SetNewPassword from "views/Authentication/SetNewPassword.jsx";
import BackgroundImage from "assets/img/background-1.jpg";

class External extends React.Component 
{
  constructor(props) 
  {
    super(props);
  }

  render() 
  { 
    var containerDivStyle = 
    {
      height: "100%",
      background: "url(" + BackgroundImage + ") no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundAttachment: "fixed"
    }

    return (
      <div style={containerDivStyle}>
        <Switch>
          <Route path="/login" component={AzureAuthentication} />
          <Route path="/password-reset" component={ResetPassword} />
          <Route path="/new-password" component={SetNewPassword} />
        </Switch>
      </div>


    );
  }
}

export default (External);
