import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import InfoIcon from '@material-ui/icons/Info';

// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import InputInfoButton from "components/CustomInput/InputInfoButton.jsx";

function CustomInput({ ...props }) {
    const
        {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            multiline,
            rows,
            onValueChange,
            infoText
        } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
    });

    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined
    });

    return (
        <FormControl {...formControlProps} className={formControlProps.className + " " + classes.formControl}>
            {
                labelText !== undefined ?
                    (
                        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
                            {labelText}
                        </InputLabel>
                    ) : null
            }

            <Input classes=
                {{
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                }}
                id={id} inputProps={inputProps}
                onChange={(event) => onValueChange ? onValueChange(event.target.value) : null}
                multiline={multiline}
                rows={rows}
                endAdornment={
                    infoText
                    ?
                    <InputInfoButton text={infoText}/>
                    : null
                } />

            {
                error ?
                    (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ?
                        (
                            <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                        ) : null
            }
            
        </FormControl>
    );
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    onValueChange: PropTypes.func,
    infoText: PropTypes.string
};

export default withStyles(customInputStyle)(CustomInput);
