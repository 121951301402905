import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import globalData from "variables/globalData.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRDetailsEditDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            updatingIVR: false,

            // Meta data
            phoneNumbers: [],

            // Data
            ivrName: "",
            isRoot: "",
            linkedPhoneNumber: "",
            parentIVR: "",
            ivrPrompt: "",
            welcomeVoicePrompt: "",
            closedVoicePrompt: "",
            fallbackQueue: ""
        }
    }
    
    componentDidMount()
    {
        this.setStateValues();
    }

    setStateValues()
    {
        const { ivrDetails } = this.props;

        var phoneNumbers = this.props.updateIVRMeta.PhoneNumbers;

        // Add current phone number to selectable phone numbers list.
        if (ivrDetails.LinkedPhoneNumber)
        {
            phoneNumbers.push(ivrDetails.LinkedPhoneNumber)
        }

        this.setState({
            phoneNumbers: phoneNumbers,

            ivrName: ivrDetails.IVRName ? ivrDetails.IVRName : "",
            isRoot: ivrDetails.IsRoot ? ivrDetails.IsRoot : "",
            linkedPhoneNumber: ivrDetails.LinkedPhoneNumber ? ivrDetails.LinkedPhoneNumber : "",
            parentIVR: ivrDetails.ParentIVR ? ivrDetails.ParentIVR : "",
            ivrPrompt: ivrDetails.IVRPrompt ? ivrDetails.IVRPrompt : "",
            welcomeVoicePrompt: ivrDetails.WelcomeVoicePrompt ? ivrDetails.WelcomeVoicePrompt : "",
            closedVoicePrompt: ivrDetails.ClosedVoicePrompt ? ivrDetails.ClosedVoicePrompt : "",
            fallbackQueue: ivrDetails.FallbackQueue ? ivrDetails.FallbackQueue : ""
        })
    }

    submitForm()
    {
        this.setState({
            updatingIVR: true
        });
        
        // TODO: authentication
        const { ivrName, isRoot, linkedPhoneNumber, parentIVR, ivrPrompt, welcomeVoicePrompt, closedVoicePrompt, fallbackQueue } = this.state;
        
        // Create request body
        var requestBody =
        {
            IVRName: ivrName,
            IsRoot: isRoot,
            LinkedPhoneNumber: linkedPhoneNumber,
            ParentIVR: parentIVR,
            IVRPrompt: ivrPrompt,
            WelcomeVoicePrompt: welcomeVoicePrompt, 
            ClosedVoicePrompt: closedVoicePrompt,
            FallbackQueue: fallbackQueue
        };

        // Create queue
        apiPostJson("ucm/ivr/update", requestBody).then(() =>        
        {
            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                updatingIVR: false
            });

            this.props.onSubmitError(error.data);
        });
    }

    render() 
    {    
        const { updatingIVR } = this.state;
        const { phoneNumbers } = this.state;
        const { ivrName, isRoot, linkedPhoneNumber, parentIVR, ivrPrompt, welcomeVoicePrompt, closedVoicePrompt, fallbackQueue } = this.state;
        
        if (!ivrName)
        {
            return <LoadingIndicator loadingText="Loading..."></LoadingIndicator>
        }

        return (
            <form onSubmit={(e) => {e.preventDefault(); this.submitForm()}}>
                <DialogTitle id="form-dialog-title">Update IVR</DialogTitle>
                <DialogContent>
                    <h5>Generic Settings</h5>
                    <Divider light={true}/>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="IVR Name (disabled)"
                                id="ivr-name"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange =
                                {
                                    (value) => { this.setState({ivrName: value}) }
                                }
                                inputProps={{
                                    autoFocus: true,
                                    maxLength: 50,
                                    defaultValue: ivrName,
                                    disabled: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Is Root IVR"
                                id="root-ivr"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                values={["Yes", "No"]}
                                selectedValue={isRoot ? "Yes" : "No"}
                                onValueChange = 
                                {
                                    (value) => { this.setState({isRoot: (value === "Yes")}) }
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Linked Phone Number"
                                id="phone-number"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                values={phoneNumbers}
                                selectedValue={linkedPhoneNumber}
                                onValueChange =
                                {
                                    (value) => { this.setState({linkedPhoneNumber: value}); }
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Parent IVR"
                                id="parent-ivr"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                values={this.props.updateIVRMeta.IVRNames}
                                selectedValue={parentIVR}
                                onValueChange =
                                {
                                    (value) => { this.setState({parentIVR: value}); }
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Fallback Queue"
                                id="fallback-queue"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                values={this.props.updateIVRMeta.Queues}
                                selectedValue={fallbackQueue}
                                onValueChange =
                                {
                                    (value) => { this.setState({fallbackQueue: value}); }
                                }
                            />
                        </GridItem>
                    </GridContainer>    

                    <br/>

                    <h5>Audio Settings</h5>
                    <Divider light={true}/>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="IVR Prompt"
                                id="ivr-prompt"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                values={this.props.updateIVRMeta.AudioFiles}
                                selectedValue={ivrPrompt}
                                onValueChange =
                                {
                                    (value) => { this.setState({ivrPrompt: value}); }
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Welcome Voice Prompt"
                                id="welcome-voice-prompt"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                values={this.props.updateIVRMeta.AudioFiles}
                                selectedValue={welcomeVoicePrompt}
                                onValueChange =
                                {
                                    (value) => { this.setState({welcomeVoicePrompt: value}); }
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText="Closed Voice Prompt"
                                id="closed-voice-prompt"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                values={this.props.updateIVRMeta.AudioFiles}
                                selectedValue={closedVoicePrompt}
                                onValueChange =
                                {
                                    (value) => { this.setState({closedVoicePrompt: value}); }
                                }
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white">
                        Cancel
                    </Button>
                    <Button disabled={updatingIVR} type="submit" color="secondary">
                        {updatingIVR ? "Updating IVR..." : "Update IVR"}
                    </Button>
                </DialogActions>
            </form>
        );   
    }
}

IVRDetailsEditDialog.propTypes = {
    ivrDetails: PropTypes.object,
    updateIVRMeta: PropTypes.object,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRDetailsEditDialog)