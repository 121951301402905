import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// Local scripts
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";
import { graphApiGet } from "utils/graphApi/graphApiWebRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

  const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.IVR.EDIT"
]

class NewIVR extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            // Component loading
            error: null,
            isLoaded: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // IVR Meta
            newIVRMeta: null,

            // New IVR data
            ivrName: "",
            isRoot: "",
            linkedPhoneNumber: "",
            parentIVR: "",
            ivrPrompt: "",
            welcomeVoicePrompt: "",
            closedVoicePrompt: "",
            fallbackQueue: "",

            // States
            creatingIVR: false,
            ivrCreated: false
        }

        this.getNewIVRMeta();
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) ||
            !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }


    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function()
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }
    
    getNewIVRMeta()
    {
        // Fetch IVR meta data
        apiGet("ucm/ivr/meta").then((result) =>
        {
            this.setState(
            {
                isLoaded:true,
                newIVRMeta: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    createIVR()
    {
        this.setState({creatingIVR: true})

        const { ivrName, isRoot, linkedPhoneNumber, parentIVR, ivrPrompt, welcomeVoicePrompt, closedVoicePrompt, fallbackQueue } = this.state;
        
        // Create request body
        var requestBody =
        {
            IVRName: ivrName,
            IsRoot: isRoot,
            LinkedPhoneNumber: linkedPhoneNumber,
            ParentIVR: parentIVR,
            IVRPrompt: ivrPrompt,
            WelcomeVoicePrompt: welcomeVoicePrompt,
            ClosedVoicePrompt: closedVoicePrompt,
            FallbackQueue: fallbackQueue
        };

        // Create IVR
        apiPostJson("ucm/ivr/add", requestBody).then(() =>
        {
            this.setState(
            {
                ivrCreated: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                creatingIVR: false
            });

            this.showNotification("danger", error.data);
        });
    }

    render()
    {
        const { error, isLoaded } = this.state;
        const { toastColor, toastText, toastOpen } = this.state;
        const { newIVRMeta } = this.state;
        const { ivrName, isRoot, linkedPhoneNumber, parentIVR, ivrPrompt, welcomeVoicePrompt, closedVoicePrompt, fallbackQueue } = this.state;
        const { creatingIVR, ivrCreated } = this.state;

        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading new IVR data..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            // TODO: Proper error handling
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error.message}></ErrorIndicator>
        }
        else if (ivrCreated)
        {            
            // If IVR was created, navigate to the page of the newly created IVR.
            return (<Redirect to={"../ivr/details?no=" + ivrName} push />)
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <form onSubmit={(e) => { e.preventDefault(); this.createIVR() }}>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Create New IVR</h4>
                                </CardHeader>
                                <CardBody>   
                                    <h5>Generic Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="IVR Name"
                                                id="ivr-name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({ivrName: value}) }
                                                }
                                                inputProps={{
                                                    autoFocus: true,
                                                    maxLength: 50
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Is Root IVR"
                                                id="root-ivr"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={isRoot ? "Yes" : "No"}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({isRoot: (value === "Yes")}) }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Linked Phone Number"
                                                id="phone-number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newIVRMeta.PhoneNumbers}
                                                selectedValue={linkedPhoneNumber}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({linkedPhoneNumber: value}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Parent IVR"
                                                id="parent-ivr"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newIVRMeta.IVRNames}
                                                selectedValue={parentIVR}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({parentIVR: value}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Fallback Queue"
                                                id="fallback-queue"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newIVRMeta.Queues}
                                                selectedValue={fallbackQueue}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({fallbackQueue: value}); }
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>    

                                    <br/>

                                    <h5>Audio Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="IVR Prompt"
                                                id="ivr-prompt"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={newIVRMeta.AudioFiles}
                                                selectedValue={ivrPrompt}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({ivrPrompt: value}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Welcome Voice Prompt"
                                                id="welcome-voice-prompt"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newIVRMeta.AudioFiles}
                                                selectedValue={welcomeVoicePrompt}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({welcomeVoicePrompt: value}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Closed Voice Prompt"
                                                id="closed-voice-prompt"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newIVRMeta.AudioFiles}
                                                selectedValue={closedVoicePrompt}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({closedVoicePrompt: value}); }
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>                                 
                                </CardBody>
                                <CardFooter>
                                    <Typography align="right" style={{width:"100%"}}>
                                        <Button color="secondary" disabled={creatingIVR} type="submit">{(creatingIVR ? "Creating IVR..." : "Create")}</Button>
                                    </Typography>
                                </CardFooter>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(NewIVR);