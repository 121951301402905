// Extra libs
import Cookies from "js-cookie";
import Constants from "variables/constants.jsx"

/**
 * Executes API GET call to provided api path.
 * @param {string} apiPath Endpoint to call (without backslash prefix).
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiGet(apiPath)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath,
    {
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        if (result.status !== 200)
        {
            throw result;
        }

        return result;    
    });
}

/**
 * Executes API POST call to provided api path.
 * @param {string} apiPath Endpoint to call (e.g. /cases OR /users/me). 
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiPost(apiPath)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath, 
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        if (result.status !== 200)
        {
            throw result;
        }

        return result;    
    });
}

/**
 * Executes API POST call to provided api path.
 * @param {string} apiPath Endpoint to call (e.g. /cases OR /users/me). 
 * @param {object} jsonBody Json object to pass a the request body (don't stringify the object).
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiPostJson(apiPath, jsonBody)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath, 
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonBody)
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        if (result.status !== 200)
        {
            throw result;
        }
        
        return result;    
    });
}

/**
 * Executes API POST call to provided api path.
 * @param {string} apiPath Endpoint to call (e.g. /cases OR /users/me). 
 * @param {object} FormData object to pass a the request body.
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiPostFormData(apiPath, formData)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath, 
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    .then((response) => response.json().then(body => ({ status: response.status, data: body })))
    .then((result) =>
    {
        if (result.status !== 200)
        {
            throw result;
        }
        
        return result;    
    });
}

/**
 * Executes API GET call to provided api path.
 * Tries to fetch a file via the API.
 * @param {string} apiPath Endpoint to call (without backslash prefix).
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiGetFile(apiPath)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath,
    {
        headers:
        {
            "Authorization": "Bearer " + token
        }
    })
    .then((response) => 
    {
        if (response.status !== 200)
        {
            throw new Error(response.status);
        }

        return response.blob();
    });
}

/**
 * Executes API GET call to provided api path.
 * Tries to fetch a file via the API.
 * @param {string} apiPath Endpoint to call (without backslash prefix).
 * @throws Throws Error object containing errorMessage if the web request failed.
 * @returns Returns promise containing web request result.
 */
export function apiPostFileJson(apiPath, jsonBody)
{
    // Get authentication token.
    var token = Cookies.get("auth");

    return fetch(Constants.ApiBaseUrl + apiPath,
    {
        method: "POST",
        headers:
        {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonBody)
    })
    .then((response) => 
    {
        if (response.status !== 200)
        {
            throw new Error(response.status);
        }

        return response.blob();
    });
}