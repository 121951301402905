import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const Sidebar = ({ ...props }) => 
{
  /**
   * Verifies if the current page contains the provided routeName.
   * @param {*} routeName 
   */
  function activeRoute(routeName) 
  {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  function urlContainsParentRoute(parentRoute)
  {
    // If no parentRoute was specified, return true.
    if (parentRoute === null)
      return true;

    // Check if current url contains the specified parentroute.
    return props.location.pathname.indexOf(parentRoute) > -1 ? true : false;
  }

  const { classes, color, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
    {
      routes.map((prop, key) => 
      {
        // Don't render route has displayRoute = false.
        // Don't render route if the user doesn't own the required permissions.
        // Don't render route if the route is a subroute, and parent route is not in the current page url.
        if (!prop.displayRoute || 
            !PMUtils.userHasAllRequiredPermissions(prop.portalPermissionsMinimum, globalData.loggedInUser.PortalPermissions) ||
            !PMUtils.userHasAnyRequiredPermissions(prop.portalPermissions, globalData.loggedInUser.PortalPermissions) || 
            !urlContainsParentRoute(prop.parentRoute))
        {
          return null;
        }

        var activePro = " ";
        var listItemClasses;

        // Add css to sidebar items.
        listItemClasses = classNames(
        {
          [" " + classes[color]]: activeRoute(prop.path)
        });

        const whiteFontClasses = classNames(
        {
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });
        
        if (!prop.isLink)
        {
          return (
            <NavLink
              to={prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}>
            
              <ListItem button className={classes.itemLink + listItemClasses}>
              {
                typeof prop.icon === "string"  || prop.icon === null
                ? 
                (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}>                
                    {prop.icon}
                  </Icon>
                ) 
                : 
                (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}
                
                {
                  // Check if the route is a subroute
                  // If it is, render a subroute, else render a parent route.
                  prop.parentRoute === null 
                  ?
                  (
                    // Render parent route
                    <ListItemText
                      primary={ prop.name }
                      className={classNames(classes.itemText, whiteFontClasses)}
                      disableTypography={true}
                    />               
                  )
                  :
                  (
                    // Render subroute
                    <ListItemText
                      secondary={ prop.name }
                      className={classNames(classes.subItemText, whiteFontClasses)}
                      disableTypography={true}
                    />   
                  )
                }              
              </ListItem>
            </NavLink>
          );
        }
        else
        {
          return (
            
            <a
              target="_blank"
              href={
                // TODO: Come up with better solution to this fix
                prop.path === "https://uwallboard.com?user=" ? prop.path  + document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, "$1")
                :
                prop.path
              } 
              className={activePro + classes.item}
              key={key}>
            
              <ListItem button className={classes.itemLink + listItemClasses}>
              {
                typeof prop.icon === "string"  || prop.icon === null
                ? 
                (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}>                
                    {prop.icon}
                  </Icon>
                ) 
                : 
                (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}
                
                {
                  // Check if the route is a subroute
                  // If it is, render a subroute, else render a parent route.
                  prop.parentRoute === null 
                  ?
                  (
                    // Render parent route
                    <ListItemText
                      primary={ prop.name }
                      className={classNames(classes.itemText, whiteFontClasses)}
                      disableTypography={true}
                    />               
                  )
                  :
                  (
                    // Render subroute
                    <ListItemText
                      secondary={ prop.name }
                      className={classNames(classes.subItemText, whiteFontClasses)}
                      disableTypography={true}
                    />   
                  )
                }              
              </ListItem>
            </a>
          );
        }
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <a
        href="https://universal.cloud"
        className={classNames(classes.textOnlyLink)}>
        {logoText}
      </a>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
