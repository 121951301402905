import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import HolidayIcon from "@material-ui/icons/Today";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

class QueueHolidays extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoadedQueueHolidays: false,
            isLoadedAllHolidays: false,

            // Dialogs           
            addHolidaysDialogOpen: false,
            addingHoliday: false,
            confirmDialogOpen: false,
            removingHoliday: false,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            queueHolidays: [],
            allHolidays: [],
            
            // New holiday
            holidayIdToAssign: "",

            // Removing holiday
            holidayToRemove: null
        }
    }

    componentDidMount()
    {
        this.getQueueHolidays();
        this.getAllHolidays();
    }

    getQueueHolidays()
    {
        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        apiGet("ucm/holidays/queue/" + queueName).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedQueueHolidays:true,
                queueHolidays: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedQueueHolidays: true,
                error: error.data
            });
        });
    }

    getAllHolidays()
    {
        // Check if user has permission to fetch customer holidays. If not, leave holidays array empty.
        if (!PMUtils.userHasAllRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoadedAllHolidays: true,
                allHolidays: []
            });

            return;
        }

        // Get customer holidays
        apiGet("ucm/holidays").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedAllHolidays:true,
                allHolidays: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedAllHolidays:true,
                error: error.data
            });
        });
    }

    addHoliday()
    {
        this.setState({addingHoliday: true})

        // TODO: authentication
        const { holidayIdToAssign, addHolidaysDialogOpen } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create request body
        var requestBody = 
        {
            holidayId : holidayIdToAssign,
            queueName : queueName
        }

        // Add holiday
        apiPostJson("ucm/queues/holidays/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingHoliday: false
            });

            this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen);
            this.showNotification("success", "Holiday assigned to queue!");

            this.getQueueHolidays();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingHoliday: false
            });

            this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    removeHoliday()
    {
        this.setState({removingHoliday: true});

        const { holidayToRemove, confirmDialogOpen } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create request body
        var requestBody = 
        {
            holidayId : holidayToRemove.ID,
            queueName : queueName
        }

        // Add holiday
        apiPostJson("ucm/queues/holidays/remove", requestBody).then(() =>        
        {
            this.setState(
            {
                removingHoliday: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("success", "Holiday removed from queue!");

            this.getQueueHolidays();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingHoliday: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    toggleAddHolidaysDialogOpen = (isOpen) => 
    {
        this.setState({
            addHolidaysDialogOpen: !isOpen
        })
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {    
        const { isLoadedQueueHolidays, isLoadedAllHolidays, error, addHolidaysDialogOpen, addingHoliday, confirmDialogOpen, removingHoliday, toastOpen, toastColor, toastText, queueHolidays, allHolidays, holidayIdToAssign } = this.state;
        const { classes } = this.props; 

        if (!isLoadedQueueHolidays || !isLoadedAllHolidays)
        {   
            return <LoadingIndicator loadingText="Loading holidays..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Add holidays dialog */}
                <Dialog open={addHolidaysDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.addHoliday()}}>
                        <DialogTitle id="form-dialog-title">Assign holiday to queue</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, select the holiday you would like to assign to the queue.
                            </DialogContentText>

                            <CustomSelect
                                labelText="Select holiday"
                                id="select-holiday"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                inputProps={{
                                    autoFocus: true
                                }}
                                values={allHolidays}
                                keyColumn="ID"
                                valueColumn="Description"
                                selectedValue={holidayIdToAssign}
                                onValueChange = 
                                {
                                    (value) => { this.setState({holidayIdToAssign: value}); }
                                }
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={addingHoliday} type="submit" color="secondary">
                                {addingHoliday ? "Assigning holiday..." : "Assign holiday"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Remove holiday confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this holiday from the queue?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will not affect other queues.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingHoliday} onClick={() => this.removeHoliday()} color="danger">
                            {removingHoliday ? "Removing holiday..." : "Remove holiday"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>Holidays</h4>
                                <p className={classes.cardCategoryWhite}>Holidays linked to queue</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <HolidayIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => this.toggleAddHolidaysDialogOpen(addHolidaysDialogOpen)} >
                                    <AddIcon />
                                </Button>                                    
                            </Typography>
                            : null
                        }

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Day", "Description"]}
                            tableHeadDataMap={["Date", "Description"]}
                            tableData={queueHolidays}
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => {this.setState({holidayToRemove: clickedItem}); this.toggleConfirmDialogOpen(confirmDialogOpen);}
                                : null
                            }
                            isSortable={true}
                            initialOrderBy={"Date"}
                            tableDateColumns={["Date"]}
                        />
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(QueueHolidays)