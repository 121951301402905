import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// material-ui components
import InfoIcon from '@material-ui/icons/Info';

const styles = {
}

function InputInfoButton({ ...props }) {
    const {
        text,
        ...rest
    } = props;

    return (
        <div title={text}>
            <InfoIcon style={{color: "#229988" }} />
        </div>
    );
}

InputInfoButton.propTypes = {
    text: PropTypes.string
};

export default withStyles (styles)(InputInfoButton)
