import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import QueueDetails from "views/UCallManager/Queues/ViewQueue/QueueDetails.jsx";
import QueueBusinessHours from "views/UCallManager/Queues/ViewQueue/QueueBusinessHours.jsx";
import QueueHolidays from "views/UCallManager/Queues/ViewQueue/QueueHolidays.jsx";
import QueueAgents from "views/UCallManager/Queues/ViewQueue/QueueAgents.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.QUEUES.VIEW",
    "UCALLMANAGER.QUEUES.EDIT"
]

// ClassName
class ViewQueuePage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            queueDetails: null
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                error: "Access denied"
            });
        }

        this.getQueueDetails();
    }

    getQueueDetails()
    {
        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");
        
        // Get details of queue
        apiGet("ucm/queues/get?queueName=" + queueName).then((result) =>            
        {
            globalData.currentlyViewingQueue = result.data;

            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                queueDetails: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    render() 
    {    
        const { isLoaded, error, queueDetails } = this.state;

        if (!isLoaded)
        {   
            return <LoadingIndicator loadingText="Loading queue..." fullScreen></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <QueueDetails></QueueDetails>
                    </GridItem>
                </GridContainer>

                {
                    // Hide content based on queuetype
                    queueDetails.QueueType.Type !== "shift"
                    ?
                    (
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                                <QueueBusinessHours></QueueBusinessHours>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                                <QueueHolidays></QueueHolidays>
                            </GridItem>
                        </GridContainer>
                    )
                    :
                    null
                }
                
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        <QueueAgents></QueueAgents>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        {/* <p>Voice Prompts</p> */}
                    </GridItem>
                </GridContainer>
            </div>
        );   
    }
}

export default withStyles(styles)(ViewQueuePage)