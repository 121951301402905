import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

// Extra libs
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import UCallManagerQueuesWidget from "views/Dashboard/uCallManagerWidgets/UCallManagerQueuesWidget"
import UCallManagerAgentsWidget from "views/Dashboard/uCallManagerWidgets/UCallManagerAgentsWidget"
import UCallManagerHolidaysWidget from "views/Dashboard/uCallManagerWidgets/UCallManagerHolidaysWidget"

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredUCallManagerPermissions = [
    "UCALLMANAGER.ACCESS"
  ]
  
  const allowedUCallManagerQueuesPermissions = [   
    "UCALLMANAGER.QUEUES.VIEW",
    "UCALLMANAGER.QUEUES.EDIT"
  ]
  
  const allowedUCallManagerAgentsPermissions = [   
    "UCALLMANAGER.AGENTS.VIEW",
    "UCALLMANAGER.AGENTS.EDIT",
    "UCALLMANAGER.QUEUES.EDIT"
  ]
  
  const allowedUCallManagerHolidaysPermissions = [   
    "UCALLMANAGER.HOLIDAYS.VIEW",
    "UCALLMANAGER.HOLIDAYS.EDIT"
  ]

class UCallManagerPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
        }
    }

    render() 
    {
        return (
        <div>
            <h2>Dashboard</h2>
            <GridContainer>
                {
                PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerQueuesPermissions, globalData.loggedInUser.PortalPermissions)
                ?
                (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                    <UCallManagerQueuesWidget/>
                    </GridItem>
                )
                :
                null
                }

                {
                PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerAgentsPermissions, globalData.loggedInUser.PortalPermissions)
                ?
                (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                    <UCallManagerAgentsWidget/>
                    </GridItem>
                )
                :
                null
                }

                {                  
                PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerHolidaysPermissions, globalData.loggedInUser.PortalPermissions)
                ?
                (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                    <UCallManagerHolidaysWidget/>
                    </GridItem>
                )
                :
                null
                }
            </GridContainer>
        </div>
        )     
    }
}

export default withStyles(dashboardStyle)(UCallManagerPage)