import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import HolidayIcon from "@material-ui/icons/Today";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Extra libs
import { apiGet } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPortalPermissions = [    
  "UCALLMANAGER.ACCESS"
]

class UCallManagerHolidaysWidget extends React.Component 
{
  constructor(props) 
  {
    super(props);
      
    this.state = 
    {
      error: null,
      isLoaded: false,
      holidaysCount: 0
    }
  }

  componentDidMount()
  {   
      // Check portal permissions   
      if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions))
      {
          this.setState(
          {
              isLoaded: true,
              error: "Access denied"
          });

          return;
      }

      // Get holidays count
      this.getHolidaysCount();
  }

  getHolidaysCount()
  {
      apiGet("ucm/holidays/count").then((result) =>            
      {
          // TODO: Check if 'Authorization has been denied for this request' message is received.
          this.setState(
          {
              isLoaded:true,
              holidaysCount: result.data
          });
      },
      (error) =>
      {
          this.setState(
          {
              isLoaded: true,
              error: error.data
          });
      });
  }

  render() 
  {
      const { classes } = this.props;
      const { isLoaded, error, holidaysCount } = this.state;

      if (!isLoaded)
      {
          return <LoadingIndicator loadingText="Loading holidays widget..."></LoadingIndicator>
      }
      else if (error)
      {
          // TODO: Error logging
          return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
      }  
      
      return (
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <HolidayIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Holidays</p>
            <h3 className={classes.cardTitle}>{holidaysCount === 1 ? "1 holiday" : (holidaysCount + " holidays")}</h3>
          </CardHeader> 
          <CardFooter stats>
            <NavLink to="/ucm/holidays">
              Go to uCallManager holidays
            </NavLink>  
          </CardFooter>
        </Card>
      );
  }
}
export default withStyles(dashboardStyle)(UCallManagerHolidaysWidget);