import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import Divider from '@material-ui/core/Divider';
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";

import Cookies from "js-cookie";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPortalPermissions = [
    "UCP.SETTINGS.ACCESS",
    "UCP.SETTINGS.GRAPHAPI.EDIT"
]

class SettingsGraphApi extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            // Component loading
            error: null,
            checkingGraphApiConnection: true,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Graph
            connectedToGraph: false,
            connectedUserFullName: "",
            connectedUserEmail: ""
        }
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) => {
        this.setState(
            {
                toastOpen: true,
                toastColor: toastColor,
                toastText: toastText
            });

        this.alertTimeout = setTimeout(
            function () {
                this.setState(
                    {
                        toastOpen: false
                    });
            }.bind(this),
            3000
        );
    }

    componentDidMount() {
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    error: "Access denied"
                });

            return;
        }
        
        // Check if 'code' parameter was provided in url. If it is, fetch new refresh token and access token.
        var url = new URL(window.location.href);
        var code = url.searchParams.get("code");

        if (code) {
            this.getGraphApiTokenByAuthorizationCode(code);
            return;
        }

        // If no code parameter was provided, check if a token cookie exists. If no token cookie exists, try to fetch token from UCP API.
        // If a token cookie exists, try to fetch connected graph user info.
        var graphToken = Cookies.get("auth-graph");

        if (graphToken)
        {
            this.getGraphApiConnectedUserInfo();
        }
        else
        {
            this.getNewGraphApiToken();
        }
    }

    redirectUserToGraphAPIAuthorization = () => {
        window.location.replace("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?"
            + "client_id=8db7dd71-98a1-4a9e-83f8-c1f90a74f44e"
            + "&response_type=code"
            //+ "&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fsettings%2Fgraph-api"
            + "&redirect_uri=https%3A%2F%2Fportal.universal.cloud%2Fsettings%2Fgraph-api"
            + "&response_mode=query"
            + "&scope=user.read user.read.all group.read.all");
        //TODO: Add state parameter.
    }

    /**
     * Fetch access and refresh token by authorization code
     */
    getGraphApiTokenByAuthorizationCode = (code) => 
    {       
        apiPostJson("graph-api/auth", code).then((result) => 
        {
            Cookies.set("auth-graph", result.data, { expires: 1 / 24, secure: true, sameSite: "lax" });  // TODO: Add Secure, http only & SameSite flags. Write documentation about why these flags are added.
            this.getGraphApiConnectedUserInfo(result.data);
        },
        (error) => 
        {
            this.setState({
                checkingGraphApiConnection: false
            })

            this.showNotification("danger", "Could not fetch token: " + error.data);
        });
    }

    logoutFromGraphAPI = () =>
    {
        var graphToken = Cookies.get("auth-graph");
        
        apiPostJson("graph-api/auth/logout", graphToken).then((result) => 
        {
            Cookies.remove("auth-graph");

            this.setState({                
                checkingGraphApiConnection: false,
                connectedToGraph: false,
                connectedUserEmail: "",
                connectedUserFullName: ""
            })
        },
        (error) => {
            this.setState({
                checkingGraphApiConnection: false
            })
        });
    }

    /**
     * Try to fetch Graph API access token from cookie or API, and fetch graph API user info.
     */
    getGraphApiConnectedUserInfo = (token) => 
    {
        var graphToken = token;

        if (!graphToken)
        {
            graphToken = Cookies.get("auth-graph");
        }

        fetch("https://graph.microsoft.com/v1.0/me/",
        {
            method: "GET",
            headers:
            {
                "Authorization": "Bearer " + graphToken
            }
        })
        .then((response) => 
        {
            response.json().then((result) => 
            {
                // If no token was provided intitially and the request failed, try to fetch one from the UCP API.
                if (!token && response.status !== 200)
                {
                    this.getNewGraphApiToken();
                    return;
                }

                // If a token was provided and the request failed, no valid tokens can be fetched. User needs to reconnect to Graph.
                else if (response.status !== 200)
                {
                    throw new Error(result.error_description);
                }

                // If request as succesful, show data.
                this.setState({
                    checkingGraphApiConnection: false,
                    connectedToGraph: true,
                    connectedUserEmail: result.mail,
                    connectedUserFullName: result.displayName
                })
            })
            .catch((error) => 
            {
                console.log(error);

                this.setState({
                    checkingGraphApiConnection: false
                })
            });
        })
    }

    /**
     * Try to fetch Graph API access token from UCP API.
     */
    getNewGraphApiToken = () =>
    {
        apiGet("graph-api/auth").then((result) => 
        {
            Cookies.set("auth-graph", result.data, { expires: 1 / 24, secure: true, sameSite: "lax" });  // TODO: Add Secure, http only & SameSite flags. Write documentation about why these flags are added.
            this.getGraphApiConnectedUserInfo(result.data);
        },
        (error) => {
            this.setState({
                checkingGraphApiConnection: false
            })
        });
    }

    render() {
        const { classes } = this.props;
        const { error, checkingGraphApiConnection,
                toastColor, toastText, toastOpen,
                connectedToGraph, connectedUserEmail, connectedUserFullName } = this.state;

        if (error) {
            // TODO: Error logging
            return <ErrorIndicator errorText={"Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* <h3>What is the Graph API?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. (What is graph api, need admin consent, will only access ... data).</p>
                 */}
                {
                    checkingGraphApiConnection
                    ?
                        <LoadingIndicator loadingText="Checking Graph API connection..."></LoadingIndicator>
                    :
                    (
                        <div>
                            <h5> 
                            { 
                                connectedToGraph 
                                ? "Connected to Graph API (" + connectedUserFullName +", " + connectedUserEmail + ")!"                                
                                : "Not connected to Graph API."
                            }
                            </h5>
                            {
                                !connectedToGraph
                                ? <Button disabled={connectedToGraph} onClick={(e) => this.redirectUserToGraphAPIAuthorization()} color={connectedToGraph ? "success" : "secondary"}> {connectedToGraph ? "Connected!" : "Connect to Graph API"}</Button>
                                : <Button disabled={!connectedToGraph} onClick={(e) => this.logoutFromGraphAPI()} color={!connectedToGraph ? "success" : "danger"}> {!connectedToGraph ? "Disconnected!" : "Disconnect from Graph API"}</Button>
                            }
                        </div>
                    )
                }
                
            </div>
        );
    }
}

SettingsGraphApi.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(SettingsGraphApi);
