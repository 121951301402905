import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HolidayIcon from "@material-ui/icons/Today";
import AddIcon from '@material-ui/icons/Add';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TableJson from "components/Table/TableJson.jsx";

import globalData from "variables/globalData.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";
import IVRHolidaysAddDialog from "./IVRHolidaysAddDialog.jsx"
import IVRHolidaysDeleteDialog from "./IVRHolidaysDeleteDialog.jsx"
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);

const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

class IVRHolidays extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoadedAllHolidays: false,
            isLoadedIVRHolidays: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Dialog
            dialogOpen: false,
            dialogType: "",

            // Meta
            allHolidays: [],

            // Data
            holidays: [],
            selectedHoliday: null
        }
    }
    
    componentDidMount()
    {
        this.getAllHolidays();
        this.getIVRHolidays();
    }

    openHolidaysAddDialog() 
    {
        this.setState({ dialogType: "add", dialogOpen: true });
    }

    openHolidaysDeleteDialog(selectedHoliday) 
    {
        this.setState({ selectedHoliday: selectedHoliday, dialogType: "delete", dialogOpen: true });
    }

    toggleDialogOpen = (isOpen) => 
    {
        this.setState({ dialogOpen: !isOpen });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    getAllHolidays()
    {
        // Check if user has permission to fetch customer holidays. If not, leave holidays array empty.
        if (!PMUtils.userHasAllRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoadedAllHolidays: true,
                allHolidays: []
            });

            return;
        }

        // Get customer holidays
        apiGet("ucm/holidays").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedAllHolidays: true,
                allHolidays: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedAllHolidays: true,
                error: error.data
            });
        });
    }

    getIVRHolidays()
    {
        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        apiGet("ucm/ivr/holidays/get?ivrName=" + ivrName).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedIVRHolidays: true,
                holidays: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedIVRHolidays: true,
                error: error.data
            });
        });
    }

    render() 
    {    
        const { error, isLoadedAllHolidays, isLoadedIVRHolidays } = this.state;
        const { toastColor, toastText, toastOpen } = this.state;
        const { dialogOpen, dialogType } = this.state;
        const { allHolidays } = this.state;
        const { holidays, selectedHoliday } = this.state;

        const { classes } = this.props; 

        if (!isLoadedAllHolidays || !isLoadedIVRHolidays)
        {   
            return <LoadingIndicator loadingText="Loading holidays..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Dialog */}
                <Dialog open={dialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleDialogOpen(dialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    {(() => {
                        switch (dialogType) {
                            case 'add':
                                return <IVRHolidaysAddDialog 
                                            ivrName={ivrName}
                                            allHolidays={allHolidays}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Holiday added!"); this.getIVRHolidays(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRHolidaysAddDialog>
                            case 'delete':
                                return <IVRHolidaysDeleteDialog 
                                            selectedHolidayID={selectedHoliday.ID}
                                            ivrName={ivrName}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Holiday removed!"); this.getIVRHolidays(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRHolidaysDeleteDialog>
                            default:
                                return <div></div>;
                        }
                    })()}
                </Dialog>

                {/* Business Hours card */}
                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>Holidays</h4>
                                <p className={classes.cardCategoryWhite}>Holidays linked to IVR</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <HolidayIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => this.openHolidaysAddDialog()} >
                                    <AddIcon />
                                </Button>                                    
                            </Typography>
                            : null
                        }                        

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Day", "Description"]}
                            tableHeadDataMap={["Date", "Description"]}
                            tableData={holidays}
                            marginTop={0}                                
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => { this.openHolidaysDeleteDialog(clickedItem); }
                                : null
                            }
                            isSortable={true}
                            initialOrderBy={"Date"}
                            tableDateColumns={["Date"]}
                        />
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(IVRHolidays)