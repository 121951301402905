import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialpadIcon from "@material-ui/icons/Dialpad";
import AddIcon from '@material-ui/icons/Add';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TableJson from "components/Table/TableJson.jsx";

import PMUtils from "utils/portalPermissionUtils.jsx";
import globalData from "variables/globalData.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";
import IVRMenuOptionsAddDialog from "./IVRMenuOptionsAddDialog"
import IVRMenuOptionsEditDialog from "./IVRMenuOptionsEditDialog"
import IVRMenuOptionsDeleteDialog from "./IVRMenuOptionsDeleteDialog"

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);

const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

class IVRMenuOptions extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoadedOptions: false,
            isLoadedMeta: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Meta,
            ivrMenuOptionsMeta : null,

            // Dialog
            dialogOpen: false,
            dialogType: "",

            // Data
            ivrMenuOptions: [],
            selectedMenuOption: null
        }
    }

    componentDidMount()
    {        
        this.getIVRMenuOptionsMeta();
        this.getIVRMenuOptions()
    }

    getIVRMenuOptionsMeta()
    {
        // Fetch IVR meta data
        apiGet("ucm/ivr/options/meta").then((result) =>
        {
            this.setState(
            {
                isLoadedMeta:true,
                ivrMenuOptionsMeta: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedMeta: true,
                error: error.data
            });
        });
    }

    getIVRMenuOptions()
    {
        // TODO: Check fetched ivr

        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        apiGet("ucm/ivr/options/get?ivrName=" + ivrName).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedOptions:true,
                ivrMenuOptions: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedOptions: true,
                error: error.data.Message
            });
        });
    }

    openMenuOptionAddDialog() 
    {
        this.setState({ dialogType: "add", dialogOpen: true });
    }

    openMenuOptionUpdateDialog(selectedMenuOption) 
    {
        this.setState({ selectedMenuOption: selectedMenuOption, dialogType: "update", dialogOpen: true });
    }

    openMenuOptionDeleteDialog(selectedMenuOption) 
    {
        this.setState({ selectedMenuOption: selectedMenuOption, dialogType: "delete", dialogOpen: true });
    }

    toggleDialogOpen = (isOpen) => 
    {
        this.setState({ dialogOpen: !isOpen });
    }

    getExistingMenuOptionIndices()
    {        
        const { ivrMenuOptions } = this.state;

        var menuOptionIndices = [];

        for(var i = 0; i < ivrMenuOptions.length; i++)
        {
            menuOptionIndices[i] = ivrMenuOptions[i].Option;
        }

        return menuOptionIndices;
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {    
        const {  error, isLoadedOptions, isLoadedMeta } = this.state;
        const { toastColor, toastText, toastOpen } = this.state;
        const { ivrMenuOptionsMeta } = this.state;
        const { dialogOpen, dialogType } = this.state;
        const { ivrMenuOptions, selectedMenuOption } = this.state;

        const { classes } = this.props; 

        if (!isLoadedOptions || !isLoadedMeta)
        {   
            return <LoadingIndicator loadingText="Loading IVR menu options..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        
        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Dialog */}
                <Dialog open={dialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleDialogOpen(dialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    {(() => {
                        switch (dialogType) {
                            case 'add':
                                return <IVRMenuOptionsAddDialog 
                                            ivrName={ivrName}
                                            ivrMenuOptionsMeta={ivrMenuOptionsMeta}
                                            existingOptionIndices={this.getExistingMenuOptionIndices()}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "IVR menu option added!"); this.getIVRMenuOptions(); this.getIVRMenuOptionsMeta(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRMenuOptionsAddDialog>
                            case 'update':
                                return <IVRMenuOptionsEditDialog 
                                            ivrName={ivrName}
                                            ivrMenuOptionsMeta={ivrMenuOptionsMeta}
                                            existingOptionIndices={this.getExistingMenuOptionIndices()}
                                            menuOptionData={selectedMenuOption}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "IVR menu option updated!"); this.getIVRMenuOptions(); this.getIVRMenuOptionsMeta(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRMenuOptionsEditDialog>
                            case 'delete':
                                return <IVRMenuOptionsDeleteDialog 
                                            menuOptionID={selectedMenuOption.ID}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "IVR menu option removed!"); this.getIVRMenuOptions(); this.getIVRMenuOptionsMeta(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRMenuOptionsDeleteDialog>
                            default:
                                return <div></div>;
                        }
                    })()}
                </Dialog>

                {/* Menu options overview card */}
                <Card>
                    <CardHeader color="primary">                        
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>IVR Menu Options</h4>
                                <p className={classes.cardCategoryWhite}>IVR menu options assigned to currently viewing IVR.</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <DialpadIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => this.openMenuOptionAddDialog()}>
                                    <AddIcon />
                                </Button>                                 
                            </Typography>
                            : null
                        }  

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Option", "Action Type", "Action", "AcknowledgeAudio"]}
                            tableHeadDataMap={["Option", "ActionType", "Action", "AcknowledgeAudio"]}
                            tableData={ivrMenuOptions}
                            isSortable={true}
                            initialOrderBy={"Option"}
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => { this.openMenuOptionDeleteDialog(clickedItem) }
                                :
                                null
                            }
                            onEditButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => { this.openMenuOptionUpdateDialog(clickedItem); }
                                :
                                null
                            }
                        />
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(IVRMenuOptions)