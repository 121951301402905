import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import Danger from "components/Typography/Danger.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Local scripts
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

var allowedPortalPermissions = [
    "ADMINCASES", 
    "PERSONALCASESONLY", 
    "READALLCASESPERSONALWRITE"
]

class NewCase extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            creatingCase: false,
            createCaseError: null,
            caseCreated: false,

            caseTypes: [],
            entitlements: [],
            subjects:[],

            caseTitle: "",
            caseType: "",
            caseSubject: "",
            caseEntitlement: "",
            caseDescription: ""
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Fetch case meta data
        apiGet("cases/meta").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            var formattedSubjects = this.flattenSubjectTree(result.data.SubjectTree);

            this.setState(
            {
                isLoaded:true,
                caseTypes: result.data.CaseTypes,
                entitlements: result.data.Entitlements,
                subjects: formattedSubjects,
                caseOriginCodes: result.data.CaseOriginCodes
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data.Message
            });
        });
    }

    /**
     * Put all parent and child subjects in the same list.
     */
    flattenSubjectTree = (parentSubject) =>
    {
        var subjects = []

        parentSubject.ChildSubjects.map((childSubject) =>
        {
            subjects.push(childSubject);

            if (childSubject.ChildSubjects != null)
            {
                this.flattenSubjectTree(childSubject).map((childChildSubject) =>
                {
                    childChildSubject.ChildSubjects = null;
                    childChildSubject.Title = childSubject.Title + " / " + childChildSubject.Title;
                    subjects.push(childChildSubject);
                });
            }
        });

        return subjects;
    }

    createNewCase = () => 
    {
        this.setState({creatingCase: true})

        // TODO: authentication
        const { caseTitle, caseType, caseSubject, caseEntitlement, caseDescription, caseOriginCodes } = this.state;
        
        // Get Web origin code
        var originCodeKey = -1;

        for (var i = 0; i < caseOriginCodes.length; i++) 
        {
            var originCode = caseOriginCodes[i];
            
            if (originCode.Name === "Web")
            {
                originCodeKey = originCode.Key;
                break;
            }
        } 

        // Create request body
        var requestBody = 
        {
            SubjectId : caseSubject,
            CaseTypeKey: caseType,
            Title : caseTitle,
            EntitlementId : caseEntitlement,
            Description : caseDescription,
            CaseOriginKey : originCodeKey
        }

        // Create case
        apiPostJson("cases/create", requestBody).then(() =>
        {
            this.setState(
            {
                caseCreated: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                createCaseError: error.data, 
                creatingCase: false
            });
        });
    }

    render() 
    {
        const { error, isLoaded, creatingCase, createCaseError, caseCreated, caseTypes, entitlements, subjects, caseType, caseSubject, caseEntitlement } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading new case data..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            // TODO: Proper error handling
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error.message}></ErrorIndicator>
        }
        else if (caseCreated)
        {
            // If case was created, navigate to cases page.
            return (<Redirect to="../cases" push />)
        }

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Card>
                        <form onSubmit={(e) => {e.preventDefault(); this.createNewCase()}}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Create new case</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                        labelText="Company Name"
                                        id="company"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            defaultValue: globalData.loggedInUser.Organization.Name
                                        }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                        labelText="Primary Contact"
                                        id="primary-contact"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            defaultValue: globalData.loggedInUser.FullName
                                        }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                        labelText="Case Title"
                                        id="case-title"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({caseTitle: value}); }
                                        }
                                        inputProps={{
                                            autoFocus: true
                                        }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomSelect
                                        labelText="Case Type"
                                        id="case-type"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={caseTypes}
                                        keyColumn="Key"
                                        valueColumn="Name"
                                        selectedValue={caseType}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({caseType: value}); }
                                        }
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomSelect
                                        labelText="Case Subject"
                                        id="case-subject"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={subjects}
                                        keyColumn="ID"
                                        valueColumn="Title"
                                        selectedValue={caseSubject}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({caseSubject: value}); }
                                        }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomSelect
                                        labelText="Entitlement"
                                        id="entitlement"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={entitlements}
                                        keyColumn="ID"
                                        valueColumn="Name"
                                        selectedValue={caseEntitlement}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({caseEntitlement: value}); }
                                        }
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                        labelText="Please, enter a description..."
                                        id="description"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({caseDescription: value}); }
                                        }
                                        multiline={true}
                                        rows={5}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Typography align="right" style={{width:"100%"}}>
                                    <Button color="secondary" disabled={creatingCase} type="submit">{(creatingCase ? "Creating Case..." : "Create")}</Button>
                                    <Danger visible={(createCaseError != null)} >{createCaseError ? "Oops, something went wrong: " + createCaseError.message : ""}</Danger>
                                </Typography>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(NewCase);