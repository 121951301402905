import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import FolderIcon from "@material-ui/icons/FolderOpen";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPortalPermissions = [    
  "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
  "UCALLMANAGER.AGENTS.VIEW",
  "UCALLMANAGER.AGENTS.EDIT",
  "UCALLMANAGER.QUEUES.EDIT"
]

class UCallManagerAgentsWidget extends React.Component 
{
  constructor(props) 
  {
    super(props);
      
    this.state = 
    {
      error: null,
      isLoaded: false
    }
  }

  componentDidMount()
  {   
      // Check portal permissions   
      if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) ||
          !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
      {
          this.setState(
          {
              isLoaded: true,
              error: "Access denied"
          });

          return;
      }

      this.setState(
        {
            isLoaded: true
        });
  }

  render() 
  {
      const { classes } = this.props;
      const { isLoaded, error } = this.state;
      
      if (!isLoaded)
      {
          
          return <LoadingIndicator loadingText="Loading contracts widget..."></LoadingIndicator>
      }
      else if (error)
      {
          // TODO: Error logging
          return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
      } 

      return (
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <FolderIcon />
            </CardIcon>
            <p className={classes.cardCategory}>Contracts</p>
          </CardHeader> 
          <CardFooter stats>
            <NavLink to="/documents/contracts">
              Go to Contracts
            </NavLink>  
          </CardFooter>
        </Card>
      );
  }
}
export default withStyles(dashboardStyle)(UCallManagerAgentsWidget);