import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Divider from '@material-ui/core/Divider';
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import PortalCasesWidget from "./PortalWidgets/PortalCasesWidget"
import UCallManagerQueuesWidget from "./uCallManagerWidgets/UCallManagerQueuesWidget"
import UCallManagerAgentsWidget from "./uCallManagerWidgets/UCallManagerAgentsWidget"
import UCallManagerHolidaysWidget from "./uCallManagerWidgets/UCallManagerHolidaysWidget"

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const allowedPortalCasesPermissions =
[
    "ADMINCASES",
    "PERSONALCASESONLY",
    "READALLCASES",
    "READALLCASESPERSONALWRITE"
]

const requiredUCallManagerPermissions = [
  "UCALLMANAGER.ACCESS"
]

const allowedUCallManagerQueuesPermissions = [   
  "UCALLMANAGER.QUEUES.VIEW",
  "UCALLMANAGER.QUEUES.EDIT"
]

const allowedUCallManagerAgentsPermissions = [   
  "UCALLMANAGER.AGENTS.VIEW",
  "UCALLMANAGER.AGENTS.EDIT",
  "UCALLMANAGER.QUEUES.EDIT"
]

const allowedUCallManagerHolidaysPermissions = [   
  "UCALLMANAGER.HOLIDAYS.VIEW",
  "UCALLMANAGER.HOLIDAYS.EDIT"
]

class Dashboard extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        <h2>Welcome, {globalData.loggedInUser.FullName}!</h2>

        {
          // // Customer Portal dashboard
          // PMUtils.userHasAnyRequiredPermissions(allowedPortalCasesPermissions, globalData.loggedInUser.PortalPermissions)
          // ?
          // (
          //   <div>
          //     <h4>Customer Portal</h4>
          //     <Divider light={true}/>
          //     <br/>
          //     <GridContainer>
          //       {
          //         PMUtils.userHasAnyRequiredPermissions(allowedPortalCasesPermissions, globalData.loggedInUser.PortalPermissions)
          //         ?
          //         (
          //           <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
          //             <PortalCasesWidget />            
          //           </GridItem>
          //         )
          //         :
          //         null
          //       }
          //     </GridContainer>
          //   </div>
          // )
          // :
          // null
        }
        
        {
          // uCallManager dashboard
          PMUtils.userHasAllRequiredPermissions(requiredUCallManagerPermissions, globalData.loggedInUser.PortalPermissions)
          ?
          (
            <div>
              <h4>uCallManager</h4>
              <Divider light={true}/>
              <br/>
              <GridContainer>

                {
                  PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerQueuesPermissions, globalData.loggedInUser.PortalPermissions)
                  ?
                  (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                      <UCallManagerQueuesWidget/>
                    </GridItem>
                  )
                  :
                  null
                }

                {
                  PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerAgentsPermissions, globalData.loggedInUser.PortalPermissions)
                  ?
                  (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                      <UCallManagerAgentsWidget/>
                    </GridItem>
                  )
                  :
                  null
                }

                {
                  PMUtils.userHasAnyRequiredPermissions(allowedUCallManagerHolidaysPermissions, globalData.loggedInUser.PortalPermissions)
                  ?
                  (
                    <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                      <UCallManagerHolidaysWidget/>
                    </GridItem>
                  )
                  :
                  null
                }

              </GridContainer>
            </div>
          )
          :
          null
        }
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
