import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

// Extra libs
import Cookies from "js-cookie";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const allowedPortalPermissions =
[
    "ADMINCASES",
    "PERSONALCASESONLY",
    "READALLCASES",
    "READALLCASESPERSONALWRITE"
]

class ClosedCases extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            cases: []
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get cases
        apiGet("cases/closedcases").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                cases: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }


    render() 
    {
        const { error, isLoaded, cases } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading closed cases..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else
        {
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Closed Cases</h4>
                                <p className={classes.cardCategoryWhite}>
                                    Closed cases (in the last 12 months) of my organization. 
                                </p>
                            </CardHeader>
                            <CardBody>                               
                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Ticket Number", "Title", "Type", "Subject", "Origin", "Primary Contact", "Status", "Created On"]}
                                    tableHeadDataMap={["TicketNumber", "Title", "Type.Name", "Subject.Title", "Origin.Name", "PrimaryContact.FullName", "Status", "DateCreated"]}
                                    tableData={cases}
                                    isClickable={true}
                                    onClickLink="/cases/closedticket?no={TicketNumber}"
                                    isSortable={true}
                                    initialOrderBy={"DateCreated"}
                                    tableDateColumns={["DateCreated"]}                                    
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }        
    }
}

export default withStyles(styles)(ClosedCases)