import React from "react";

// @material-ui/core components
import Divider from '@material-ui/core/Divider';

// core components
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

// Extra libs
import { apiGet } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import * as powerbi from 'powerbi-client';
import $ from 'jquery';

const allowedPortalPermissions = [
    "ADMININVOICES"
  ]

class InvoicesReportingPage extends React.Component {
    constructor(props)  {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            reportEmbedData: null
        }
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    isLoaded: true,
                    error: "Access denied"
                });

            return;
        }

        this.getDashboardReport();
    }

    getDashboardReport()
    {
        apiGet("ucm/reports/invoices").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded: true,
                reportEmbedData: result.data
            });

            this.loadReport(result.data.AccessToken, result.data.EmbedURL, result.data.ReportID)
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }
    
    loadReport(accessToken, embedUrl, embedReportId) 
    {   
        // Fetch viewport width (used for determining if report should be shown in mobile view).
        var screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    
        // Get Power BI SDK models.
        var models = powerbi.models;
    
        var config;
    
        // Load normal or mobile report view.
        if (screenWidth > 768) {
            config = {
                type: 'report',
                id: embedReportId,
                tokenType: models.TokenType.Embed,
                accessToken: accessToken,
                embedUrl: embedUrl,
                permissions: models.Permissions.Read,
                settings:
                {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                }
            };
        }
        else {
            config = {
                type: 'report',
                tokenType: models.TokenType.Embed,
                accessToken: accessToken,
                embedUrl: embedUrl,
                id: embedReportId,
                permissions: models.Permissions.Read,
                settings:
                {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    layoutType: models.LayoutType.MobilePortrait
                }
            };
        }

        // Get a reference to the reportcontainer element
        var reportContainer = $('#reportContainer')[0];

        if (reportContainer == null)
        {
            return;
        }

        // Embed the report and display it within the div container.
        var report = window.powerbi.embed(reportContainer, config);
        report.iframe.style.border = "None";

        // For updating settings at runtime
        // report.updateSettings(newSettings)
        //     .catch(error => { ... });
    }

    render() 
    {
        const { error, isLoaded } = this.state

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading reporting..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        } 

        return (
        <div id="reportContainer" style={{height:"100%"}}></div>
        )     
    }
}

export default InvoicesReportingPage