import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import globalData from "variables/globalData.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class NewOrderConfirmation extends React.Component 
{
    constructor(props) 
    {
        super(props);
    }

    render() 
    {
        const { products, tenant, agreementAccepted, onAgreementAccept, productNameFieldKey } = this.props;

        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={4} sm={2} md={2}>
                        <p><b>Account Name</b></p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <p>{globalData.loggedInUser.Organization.Name}</p>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={4} sm={2} md={2}>
                        <p><b>Tenant</b></p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <p>{tenant}</p>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={4} sm={2} md={2}>
                        <p><b>Created By</b></p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={4}>
                        <p>{globalData.loggedInUser.FullName}</p>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={10} sm={8} md={6}>
                        <p><b>Products</b></p>
                        <ul>
                        {
                            products.map((product, index) => 
                            {
                                return <li key={index}>{product.Amount} x {product[productNameFieldKey]}</li>
                            })
                        }
                        </ul>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={10} sm={8} md={6}>
                        <p><b>Microsoft Customer Agreement</b></p>
                        <FormControlLabel
                            control={
                                <Checkbox checked={agreementAccepted} onChange={(value) => onAgreementAccept(value) } color="primary" required={true}  />
                            }
                            label={(<span>I confirm that I have read, understand and agree to the <a href="https://universal.cloud/microsoft_customer_agreement_eu/" target="blank" >Microsoft Customer Agreement</a></span>)}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );       
    }
}

export default withStyles(styles)(NewOrderConfirmation)