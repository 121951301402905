/* eslint-disable */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Danger from "components/Typography/Danger.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// Extra libs
import Constants from "variables/constants.jsx"

const styles = 
{
  cardTitleWhite: 
  {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class ResetPasswordPage extends React.Component 
{
  constructor(props) 
  {
    super(props);

    this.state = 
    {
      error: null,  

      submittingEmail: false, 
      requestSuccessful: false,

      email: "",
    }
  }

  submitEmail = () =>
  {  
    const { email } = this.state
    
    this.setState({submittingEmail: true})

    fetch(Constants.ApiBaseUrl + "registration/requestpasswordreset", 
    {
      method: "POST",
      headers:
      {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(email)
    })
    .then((response) => 
    {
      response.json().then((result) =>
      {
        if (response.status !== 200)
        {                       
          throw new Error(result.error_description);
        }

        this.setState(() => ({
          submittingEmail: false,
          requestSuccessful: true
        }));
      })
      .catch((error) =>
      {
        this.setState(
        {
          error: error.message, 
          submittingEmail: false
        })
      });
    })
  }

  render() 
  {    
    const { error, submittingEmail, requestSuccessful  } = this.state
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      > 
        <div style={{ maxWidth: '400px', width: "100%" }}>
          <Card style={{textAlign: 'center'}}>
            <form onSubmit={(e) => {e.preventDefault(); this.submitEmail()}}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Forgot my password</h4>
              </CardHeader>
              {
                !requestSuccessful
                ?
                (
                  <div>
                    <CardBody>
                      <CustomInput
                        labelText="Email Address"
                        id="email"
                        formControlProps=
                        {{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps = 
                        {{
                          type: "email",
                          autoFocus: true
                        }}
                        onValueChange = 
                        {
                          (value) => { this.setState({email: value}); }
                        }
                      />
                      {/* TODO: Show dynamic error message based on result. */}
                      <Danger visible={(error != null)} >{error}</Danger>
                    </CardBody>
                    <CardFooter style={{textAlign: 'center', display: 'block'}}>
                      <Button color="secondary" disabled={submittingEmail} type="submit" >{(submittingEmail ? "Submitting..." : "Submit")}</Button>
                    </CardFooter>
                  </div>
                )
                :
                <p>We have sent you an email containing instructions on how to reset your password!</p>
              }              
              <br/>
            </form>
          </Card>
        </div>
      </Grid>       
    );
  }
}

export default withStyles(styles)(ResetPasswordPage);
