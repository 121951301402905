
/**
 * Check if the actualPermissions array contains any permissions from the allowedPermissions array.
 * @param {string[]} allowedPermissions Array containing allowed permissions
 * @param {string[]} actualPermissions Array containing actual permissions of a user.
 * @returns True if actualPermissions contains any permissions from the allowedPermissions array.
 */
function userHasAnyRequiredPermissions(allowedPermissions, actualPermissions)
{
    // If no allowedPermissions are defined, return true (wildcard)
    if (allowedPermissions.length === 0)
    {
        return true;
    }

    for (var i = 0; i < allowedPermissions.length; i++)
    {
        if (actualPermissions.includes(allowedPermissions[i]))
        {
            return true;
        }            
    }

    return false;
}

/**
 * Check if the actualPermissions array contains all permissions from the allowedPermissions array.
 * @param {string[]} requiredPermissions Array containing required permissions
 * @param {string[]} actualPermissions Array containing actual permissions of a user.
 * @returns True if actualPermissions contains all permissions from the allowedPermissions array.
 */
function userHasAllRequiredPermissions(requiredPermissions, actualPermissions)
{
    // If no requiredPermissions are defined, return true (wildcard)
    if (requiredPermissions.length === 0)
    {
        return true;
    }

    for (var i = 0; i < requiredPermissions.length; i++)
    {
        if (!actualPermissions.includes(requiredPermissions[i]))
        {
            return false;
        }            
    }
    
    return true;
}

module.exports =
{
    userHasAnyRequiredPermissions,
    userHasAllRequiredPermissions
};