import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import IVRDetails from "views/UCallManager/IVR/IVRDetails/SubViews/IVRDetails.jsx";
import IVRBusinessHours from "views/UCallManager/IVR/IVRDetails/SubViews/IVRBusinessHours.jsx";
import IVRMenuOptions from "views/UCallManager/IVR/IVRDetails/SubViews/IVRMenuOptions.jsx";
import IVRHolidays from "views/UCallManager/IVR/IVRDetails/SubViews/IVRHolidays.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.IVR.VIEW",
    "UCALLMANAGER.IVR.EDIT"
]

// ClassName
class IVRDetailsOverviewPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                error: "Access denied"
            });
        }

        this.setState(
        {
            isLoaded: true
        });
    }

    render() 
    {    
        const { isLoaded, error } = this.state;

        if (!isLoaded)
        {   
            return <LoadingIndicator loadingText="Loading IVR..." fullScreen></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <IVRDetails></IVRDetails>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        <IVRBusinessHours></IVRBusinessHours>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        <IVRHolidays></IVRHolidays>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        <IVRMenuOptions></IVRMenuOptions>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={6}>
                        <div></div>
                    </GridItem>
                </GridContainer>
            </div>
        );   
    }
}

export default withStyles(styles)(IVRDetailsOverviewPage)