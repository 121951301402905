import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import globalData from "variables/globalData.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRMenuOptionsEditDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            updatingMenuOption: false,

            // Data
            id: -1,
            menuOptionIndex: 0,
            actionType: "",
            action: "",
            acknowledgeAudio: ""
        }
    }

    componentDidMount()
    {
        this.setStateValues();
    }

    setStateValues()
    {
        const { menuOptionData, ivrMenuOptionsMeta } = this.props;

        // Map provided ActionType to the correct keys
        var actionType = menuOptionData.ActionType ? menuOptionData.ActionType : "";

        for(var i = 0; i < ivrMenuOptionsMeta.ActionTypes.length; i++)
        {
            if (ivrMenuOptionsMeta.ActionTypes[i].Value === actionType)
            {
                actionType = ivrMenuOptionsMeta.ActionTypes[i].Key;
            }
        }

        this.setState({
            id: menuOptionData.ID,
            menuOptionIndex: menuOptionData.Option,
            actionType: actionType,
            action: menuOptionData.Action ? menuOptionData.Action : "",
            acknowledgeAudio: menuOptionData.AcknowledgeAudio ? menuOptionData.AcknowledgeAudio : ""
        })
    }
    
    submitForm()
    {
        this.setState({
            updatingMenuOption: true
        });
        
        // TODO: authentication
        const { id, menuOptionIndex, actionType, action, acknowledgeAudio } = this.state;
        
        // Create request body
        var requestBody =
        {
            ID: id,
            Option: menuOptionIndex,
            ActionType: actionType,
            Action: action,
            AcknowledgeAudio: acknowledgeAudio,
            IVRname: this.props.ivrName
        };
        
        // Check if option hasn't already been defined (unless it hasn't been changed).
        if (Number(menuOptionIndex) !== this.props.menuOptionData.Option)
        {
            if (this.props.existingOptionIndices.includes(Number(menuOptionIndex)))
            {
                this.props.onSubmitError("Menu option index is already in use.");
    
                this.setState(
                {
                    //TODO: User friendly error.
                    updatingMenuOption: false
                });
    
                return;
            }
        }
        
        // Create menu option
        apiPostJson("ucm/ivr/options/update", requestBody).then(() =>        
        {
            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                updatingMenuOption: false
            });

            this.props.onSubmitError(error.data);
        });
    }

    render() 
    {    
        const { updatingMenuOption } = this.state;
        const { id, menuOptionIndex, actionType, action, acknowledgeAudio } = this.state;
        
        if (id <= 0)
        {
            return <LoadingIndicator loadingText="Loading..."></LoadingIndicator>
        }

        return (
            <form onSubmit={(e) => {e.preventDefault(); this.submitForm()}}>
                <DialogTitle id="form-dialog-title">Update Menu Option</DialogTitle>
                <DialogContent>
                    <CustomInput
                        labelText="Menu option index"
                        id="menu-option-index"
                        formControlProps={{
                            fullWidth: true,
                            required: true
                        }}
                        onValueChange = 
                        {
                            (value) => { this.setState({menuOptionIndex: value}) }
                        }
                        inputProps={{
                            pattern: "^[0-9][0-9]?$|^100$",
                            maxLength: 3,
                            title: "Only numbers between 0 and 100 allowed",
                            defaultValue: menuOptionIndex,
                            autoFocus: true
                        }}
                    />

                    <CustomSelect
                        labelText="Action Type"
                        id="action-type"
                        formControlProps={{
                            fullWidth: true,
                            required: true
                        }}
                        keyColumn="Key"
                        valueColumn="Value"
                        values={this.props.ivrMenuOptionsMeta.ActionTypes}
                        selectedValue={actionType}
                        onValueChange = 
                        {
                            (value) => { this.setState({actionType: value, action: ""}); }
                        }
                    />

                    {(() => {
                        switch (actionType) {
                            case "RETURN_TO_PARENT_IVR":
                                return <div></div>
                                
                            case "JUMP_TO_IVR":
                                    return <CustomSelect
                                                labelText="Action (select IVR)"
                                                id="action"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={this.props.ivrMenuOptionsMeta.IVRNames}
                                                selectedValue={action}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({action: value}); }
                                                }
                                            />
                                    
                            case "JUMP_TO_QUEUE":
                                    return <CustomSelect
                                                labelText="Action (select queue)"
                                                id="action"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={this.props.ivrMenuOptionsMeta.QueueNames}
                                                selectedValue={action}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({action: value}); }
                                                }
                                            />
                                    
                            case "REPEAT_CURRENT_IVR":
                                    return <div></div>
                                    
                            case "HUNT_AGENT":
                                    return <CustomSelect
                                                labelText="Action (select agent)"
                                                id="action"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={this.props.ivrMenuOptionsMeta.AgentNames}
                                                selectedValue={action}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({action: value}); }
                                                }
                                            />

                            default:
                                return <div></div>
                        }
                    })()}   

                    <CustomSelect
                        labelText="Acknowledge Audio"
                        id="acknowledge-audio"
                        formControlProps={{
                            fullWidth: true
                        }}
                        values={this.props.ivrMenuOptionsMeta.AudioFiles}
                        selectedValue={acknowledgeAudio}
                        onValueChange = 
                        {
                            (value) => { this.setState({acknowledgeAudio: value}); }
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white">
                        Cancel
                    </Button>
                    <Button disabled={updatingMenuOption} type="submit" color="secondary">
                        {updatingMenuOption ? "Updating Menu Option..." : "Update Menu Option"}
                    </Button>
                </DialogActions>
            </form>
        );   
    }
}

IVRMenuOptionsEditDialog.propTypes = {
    ivrMenuOptionsMeta: PropTypes.object,
    ivrName: PropTypes.string,
    menuOptionData: PropTypes.object,
    existingOptionIndices: PropTypes.arrayOf(PropTypes.number),
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRMenuOptionsEditDialog)