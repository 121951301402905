import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import TableJson from "components/Table/TableJson.jsx";
import IVRIcon from "@material-ui/icons/CallSplit";

// Extra libs
import { apiGet } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [ 
    "UCALLMANAGER.IVR.VIEW",
    "UCALLMANAGER.IVR.EDIT"
]

class IVROverviewPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoaded: false,   

            ivrs: []
        }
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        this.getIVRs()
    }

    getIVRs()
    {
        apiGet("ucm/ivr/all").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                ivrs: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data.Message
            });
        });
    }

    render() 
    {
        const { error, isLoaded } = this.state;
        const { ivrs } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading IVR's..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }  

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Card>
                        <CardHeader color="primary">
                            <GridContainer>
                                <GridItem xs={10} sm={11} md={11}>
                                    <h4 className={classes.cardTitleWhite}>IVR</h4>
                                    <p className={classes.cardCategoryWhite}>Overview of Interactive Voice Responses.</p>
                                </GridItem>
                                <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                    <IVRIcon/>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody> 
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <p className={classes.textCursive}>Click IVR to view or edit.</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>  
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        <Typography align="right">
                                            <NavLink to="/ucm/ivr/new">
                                                <Button color="secondary" justIcon round >
                                                    <AddIcon />
                                                </Button>   
                                            </NavLink>                                 
                                        </Typography>
                                        : null
                                    }                                   
                                </GridItem>
                            </GridContainer>

                            <TableJson
                                tableHeaderColor="primary"
                                tableHead={["Name", "Is Root", "Phone Number", "Parent IVR"]}
                                tableHeadDataMap={["IVRName", "IsRoot", "LinkedPhoneNumber", "ParentIVR"]}
                                tableData={ivrs}
                                isClickable={true}
                                onClickLink="/ucm/ivr/details?no={IVRName}"
                                isSortable={true}
                                initialOrderBy={"IVRName"}
                                // tableNumericColumns={["PhoneNumber", "MaxMembers"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );     
    }
}

export default withStyles(styles)(IVROverviewPage)