import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const allowedPortalPermissions =
[
    "READORDERS",
    "ADMINORDERS"
]

class OrdersPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            orders: []
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get orders
        apiGet("orders/pending").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                orders: this.appendDescriptionToOrders(result.data)
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    appendDescriptionToOrders(orders)
    {
        var newOrders = [];

        for (var orderIndex = 0; orderIndex < orders.length; orderIndex++)
        {
            var order = orders[orderIndex];

            var productsDescription = "";

            for (var productIndex = 0; productIndex < order["Products"].length; productIndex++)
            {
                var productAmount = order["Products"][productIndex]["Amount"];
                var productName =   order["Products"][productIndex]["IsAddOn"] === true
                                    ?
                                    order["Products"][productIndex]["ProductDisplayName"]
                                    :
                                    order["Products"][productIndex]["ProductName"];


                productsDescription += ("- " + "(" + productAmount + "x) " + productName) + "\n";
            }

            order["ProductsDescription"] = productsDescription;

            newOrders.push(order);
        }

        return newOrders;
    }

    render() 
    {
        const { error, isLoaded, orders } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading orders..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else
        {
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Pending Orders</h4>
                                <p className={classes.cardCategoryWhite}>
                                    All pending orders of my organization. 
                                </p>
                            </CardHeader>
                            <CardBody>

                                <GridContainer>
                                    <GridItem xs={12} sm={5} md={5} lg={5} xl={5}>
                                        <b>Pricelists</b>
                                        <br/>
                                        <a href="https://universal.cloud/support_articles/office-365-csp-pricelist" target="_blank">Microsoft 365 CSP Pricelist</a>
                                    </GridItem>
                                    <GridItem xs={12} sm={7} md={7} lg={7} xl={7}>
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["ADMINORDERS"], globalData.loggedInUser.PortalPermissions)
                                            ?
                                                <Typography align="right">
                                                    <div>
                                                        <NavLink to="/orders/new">
                                                            <Button color="secondary" style={{marginRight:"5px"}}>
                                                                <AddIcon /> order
                                                            </Button> 
                                                        </NavLink>                                                         
                                                    </div>                                  
                                                </Typography>                                        
                                            : null
                                    } 
                                    </GridItem>
                                </GridContainer>
                                
                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Account Name", "Tenant", "Created By", "Created On", "Products"]}
                                    tableHeadDataMap={["Customer.Name", "TenantName", "Creator.FullName", "CreatedOn", "ProductsDescription"]}
                                    tableData={orders}
                                    isSortable={true}
                                    infoFieldIndex={4}
                                    infoFieldLinkText="Show products"
                                    infoModalTitle="Order products"
                                    initialSortingOrder={"desc"}
                                    initialOrderBy={"CreatedOn"}
                                    tableDateColumns={["CreatedOn"]}
                                    tableIgnoreSortColumns={["ProductsDescription"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }        
    }
}

export default withStyles(styles)(OrdersPage)