/* eslint-disable */
import React from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Danger from "components/Typography/Danger.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// Extra libs
import Constants from "variables/constants.jsx"

const styles = 
{
  cardTitleWhite: 
  {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class SetNewPasswordPage extends React.Component 
{
  constructor(props) 
  {
    super(props);

    this.state = 
    {
      error: null,  

      submittingPasswords: false, 
      requestSuccessful: false,
      loginPageRedictCount: 5,

      password: "",
      repeatPassword: ""
    }
  }

  submitPasswords = () =>
  {  
    const { password } = this.state
    
    this.setState({submittingPasswords: true, error: null})

    // Check if passwords are equal
    if (!this.checkPasswordsAreEqual())
    {
      this.setState(
      {
        error: "Oops, the two passwords you entered are not equal.", 
        submittingPasswords: false
      })
      return;
    }

    // Get registration key from url
    var url = new URL(window.location.href);
    var registrationKey = url.searchParams.get("validate");

    if (!registrationKey)
    {
      this.setState(
        {
          error: "Your registration key is missing.", 
          submittingPasswords: false
        })
      return;
    }

    // Create request body
    var requestBody = {
      "RegistrationKey": registrationKey,
      "Password": password
    }

    // Send request to API
    fetch(Constants.ApiBaseUrl + "registration/newpassword", 
    {
      method: "POST",
      headers:
      {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    })
    .then((response) => 
    {
      response.json().then((result) =>
      {
        if (response.status !== 200)
        {                      
          throw new Error(result);
        }

        this.setState(
        {
          submittingPasswords: false,
          requestSuccessful: true
        })

        this.redirectToLoginPageCountDown();
      })
      .catch((error) =>
      {
        this.setState(
        {
          error: error.message, 
          submittingPasswords: false
        })
      });
    })
  }

  redirectToLoginPageCountDown = () =>
  {
    var timerCounter = 4;

    var timer = setInterval(function() 
    {
      this.setState(
      {
        loginPageRedictCount: timerCounter
      })

      timerCounter--;

      // If the count down is finished, write some text
      if (timerCounter < 0) {
        clearInterval(timer);
      }
    }.bind(this), 1000);
  }

  checkPasswordsAreEqual = () =>
  {
    const { password, repeatPassword } = this.state

    if (!password || !repeatPassword)
    {
      return false;
    }

    return (password === repeatPassword);
  }

  render() 
  {    
    const { error, submittingPasswords, requestSuccessful, loginPageRedictCount  } = this.state
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      > 
        <div style={{ maxWidth: '400px', width: "100%" }}>
          <Card style={{textAlign: 'center'}}>
            <form onSubmit={(e) => {e.preventDefault(); this.submitPasswords()}}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Set new password</h4>
              </CardHeader>
              {
                !requestSuccessful
                ?
                (
                  <div>
                    <CardBody>
                      <CustomInput
                        labelText="Password (min. 6 characters)"
                        id="password"
                        formControlProps=
                        {{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps = 
                        {{
                          type: "password",
                          autoFocus: true,
                          minLength: 6,
                          maxLength: 50
                        }}
                        onValueChange = 
                        {
                          (value) => { this.setState({password: value}); }
                        }
                      />
                      <CustomInput
                        labelText="Repeat password"
                        id="repeat-password"
                        formControlProps=
                        {{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps = 
                        {{
                          type: "password",
                          minLength: 6,
                          maxLength: 50
                        }}
                        onValueChange = 
                        {
                          (value) => { this.setState({repeatPassword: value}); }
                        }
                      />
                      {/* TODO: Show dynamic error message based on result. */}
                      <Danger visible={(error != null)} >{error}</Danger>
                    </CardBody>
                    <CardFooter style={{textAlign: 'center', display: 'block'}}>
                      <Button color="secondary" disabled={submittingPasswords} type="submit" >{(submittingPasswords ? "Submitting..." : "Submit")}</Button>
                    </CardFooter>
                  </div>
                )
                :
                <p>Your password has successfully been changed! You will be redirected to the login page in {loginPageRedictCount} seconds.</p>
              }     
              {
                loginPageRedictCount === 0
                ?
                <Redirect to="/login" />
                :
                null
              }         
              <br/>
            </form>
          </Card>
        </div>
      </Grid>       
    );
  }
}

export default withStyles(styles)(SetNewPasswordPage);
