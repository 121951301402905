import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from '@material-ui/core/Divider';
import ScheduleIcon from "@material-ui/icons/Schedule";
import AddIcon from '@material-ui/icons/Add';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import TableJson from "components/Table/TableJson.jsx";

import globalData from "variables/globalData.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";
import IVRBusinessHoursAddDialog from "./IVRBusinessHoursAddDialog.jsx"
import IVRBusinessHoursDeleteDialog from "./IVRBusinessHoursDeleteDialog.jsx"
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);

const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

class IVRBusinessHours extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoaded: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Dialog
            dialogOpen: false,
            dialogType: "",

            // Data
            hours: [],
            selectedBusinessHours: null
        }
    }
    
    componentDidMount()
    {
        this.getIVRBusinessHours();
    }

    openBusinessHoursAddDialog() 
    {
        this.setState({ dialogType: "add", dialogOpen: true });
    }

    openBusinessHoursDeleteDialog(selectedBusinessHours) 
    {
        this.setState({ selectedBusinessHours: selectedBusinessHours, dialogType: "delete", dialogOpen: true });
    }

    toggleDialogOpen = (isOpen) => 
    {
        this.setState({ dialogOpen: !isOpen });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    getIVRBusinessHours()
    {
        // TODO: Check fetched ivr

        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        apiGet("ucm/ivr/hours/get?ivrName=" + ivrName).then((result) =>            
        {
            var hoursList = [];

            for (var i = 0; i < result.data.length; i++)
            {
                var hoursRecord = result.data[i];

                var newHoursRecord = 
                {
                    ID: hoursRecord.ID,
                    DayOfWeek: hoursRecord.DayOfWeek,
                    OpenHours: hoursRecord.OpenTime + " - " + hoursRecord.ClosedTime
                }

                hoursList.push(newHoursRecord);
            }

            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded: true,
                hours: hoursList
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data.Message
            });
        });
    }

    render() 
    {    
        const { error, isLoaded } = this.state;
        const { toastColor, toastText, toastOpen } = this.state;
        const { dialogOpen, dialogType } = this.state;
        const { hours, selectedBusinessHours } = this.state;

        const { classes } = this.props; 

        if (!isLoaded)
        {   
            return <LoadingIndicator loadingText="Loading business hours..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        
        // Get IVR name from url parameter.
        var url = new URL(window.location.href);
        var ivrName = url.searchParams.get("no");

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Dialog */}
                <Dialog open={dialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleDialogOpen(dialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    {(() => {
                        switch (dialogType) {
                            case 'add':
                                return <IVRBusinessHoursAddDialog 
                                            ivrName={ivrName}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Business hours added!"); this.getIVRBusinessHours(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRBusinessHoursAddDialog>
                            case 'delete':
                                return <IVRBusinessHoursDeleteDialog 
                                            businessHoursID={selectedBusinessHours.ID}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Business hours removed!"); this.getIVRBusinessHours(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></IVRBusinessHoursDeleteDialog>
                            default:
                                return <div></div>;
                        }
                    })()}
                </Dialog>

                {/* Business Hours card */}
                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>Business Hours</h4>
                                <p className={classes.cardCategoryWhite}>Business hours per day</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <ScheduleIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => this.openBusinessHoursAddDialog()} >
                                    <AddIcon />
                                </Button>                                    
                            </Typography>
                            : null
                        }                        

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Day", "Open Hours"]}
                            tableHeadDataMap={["DayOfWeek", "OpenHours"]}
                            tableData={hours}
                            marginTop={0}
                            isSortable={true}
                            initialOrderBy={"DayOfWeek"}
                            tableDayOfWeekColumns={["DayOfWeek"]} 
                            tableIgnoreSortColumns={["OpenHours"]}                               
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.IVR.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => { this.openBusinessHoursDeleteDialog(clickedItem); }
                                : null
                            }
                        />
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(IVRBusinessHours)