import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import { apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRHolidaysAddDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            addingHoliday: false,

            // Data
            selectedHolidayID: -1
        }
    }
    
    submitForm()
    {
        this.setState({addingHoliday: true})

        // TODO: authentication
        const { selectedHolidayID } = this.state;

        // Create request body
        var requestBody = 
        {
            HolidayID : selectedHolidayID,
            IVRName : this.props.ivrName
        }

        // Add holiday
        apiPostJson("ucm/ivr/holidays/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingHoliday: false
            });

            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingHoliday: false
            });

            this.props.onSubmitError(error.data);
        });
    }

    render() 
    {    
        const { addingHoliday } = this.state;
        const { selectedHolidayID } = this.state;

        return (
            <form onSubmit={(e) => {e.preventDefault(); this.submitForm()}}>
                <DialogTitle id="form-dialog-title">Assign holiday to IVR</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please, select the holiday you would like to assign to the IVR.
                    </DialogContentText>

                    <CustomSelect
                        labelText="Select holiday"
                        id="select-holiday"
                        formControlProps={{
                            fullWidth: true,
                            required: true
                        }}
                        inputProps={{
                            autoFocus: true
                        }}
                        values={this.props.allHolidays}
                        keyColumn="ID"
                        valueColumn="Description"
                        selectedValue={selectedHolidayID}
                        onValueChange = 
                        {
                            (value) => { this.setState({selectedHolidayID: value}); }
                        }
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white">
                        Cancel
                    </Button>
                    <Button disabled={addingHoliday} type="submit" color="secondary">
                        {addingHoliday ? "Assigning holiday..." : "Assign holiday"}
                    </Button>
                </DialogActions>
            </form>
        );   
    }
}

IVRHolidaysAddDialog.propTypes = {
    ivrName: PropTypes.string,
    allHolidays: PropTypes.array,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRHolidaysAddDialog)