import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider  } from "@azure/msal-react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { msalConfig } from "./utils/azuread/authConfig";
import { Provider } from "react-redux";
import store from "./stateManagement/store/index";
import AzureAuthentication from "views/Authentication/Azure.jsx";
import AzureAuthenticationLogout from "views/Authentication/Azure.jsx";

// core components
import Admin from "layouts/Admin.jsx";
import External from "layouts/External.jsx";

// Extra libs
import Cookies from "js-cookie";

import "assets/css/material-dashboard-react.css?v=1.6.0";

const hist = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Provider store={store}>
    <Route {...rest} render={(props) => (
      tokenIsValid() 
      ? 
      <Component {...props} /> 
      : 
      <Redirect to={"/login?url=" + window.location.pathname + window.location.search}/>
    )} />
  </Provider>
)

// TODO: Better way of checking if token is valid.
function tokenIsValid()
{
  var token = Cookies.get("auth");

  if (token)
  {
    return true;
  }
  else
  {
    return false; 
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Router history={hist}>
    <Switch>      
      <Route path="/login">
        <MsalProvider instance={msalInstance}>
          <AzureAuthentication/>
        </MsalProvider>
      </Route>
      <Route path="/logout">
        <MsalProvider instance={msalInstance}>
          <AzureAuthentication/>
        </MsalProvider>  
      </Route>
      <Route path="/password-reset" component={External} />
      <Route path="/new-password" component={External} />
      <PrivateRoute  path="/" component={Admin} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
