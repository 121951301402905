import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import GeneralDocumentsWidget from "views/Dashboard/PortalWidgets/PortalGeneralDocumentsWidget.jsx";
import ContractsWidget from "views/Dashboard/PortalWidgets/PortalContractsWidget.jsx";
import InvoicesWidget from "views/Dashboard/PortalWidgets/PortalInvoicesWidget.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const allowedPortalPermissions =
    [
        "UCP.DOCUMENTS.GENERAL.VIEW",
        "VIEWCONTRACTS",
        "ADMININVOICES"
    ]

const requiredGeneralDocsPermissions = [
    "UCP.DOCUMENTS.GENERAL.VIEW",
]

const requiredContractsPermissions = [
    "VIEWCONTRACTS",
]

const requiredInvoicesPermissions = [
    "ADMININVOICES"
]

class DocumentsOverviewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false
        }
    }

    componentDidMount() {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    isLoaded: true,
                    error: "Access denied"
                });

            return;
        }

        this.setState(
            {
                isLoaded: true
            });
    }

    render() {
        const { error, isLoaded } = this.state;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading documents overview..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                <h2>Documents</h2>
                <GridContainer>
                    {
                        PMUtils.userHasAnyRequiredPermissions(requiredGeneralDocsPermissions, globalData.loggedInUser.PortalPermissions)
                            ?
                            (
                                <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <GeneralDocumentsWidget />
                                </GridItem>
                            )
                            :
                            null
                    }

                    {
                        PMUtils.userHasAnyRequiredPermissions(requiredContractsPermissions, globalData.loggedInUser.PortalPermissions)
                            ?
                            (
                                <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <ContractsWidget />
                                </GridItem>
                            )
                            :
                            null
                    }

                    {
                        PMUtils.userHasAnyRequiredPermissions(requiredInvoicesPermissions, globalData.loggedInUser.PortalPermissions)
                            ?
                            (
                                <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <InvoicesWidget />
                                </GridItem>
                            )
                            :
                            null
                    }
                </GridContainer>
            </div>
        )
    }
}

export default withStyles(styles)(DocumentsOverviewPage)