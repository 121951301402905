import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson, apiPostFileJson } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const allowedPortalPermissions =
    [
        "UCP.DOCUMENTS.GENERAL.VIEW"
    ]

class GeneralDocumentsPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            generalDocs: [],
            selectedDocuments: [],
            triggerRender: "",

            downloading: false
        }

    }

    componentDidMount() {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    isLoaded: true,
                    error: "Access denied"
                });

            return;
        }

        // Get list of general documents
        apiGet("documents/general").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                generalDocs: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    downloadDocuments = () =>
    {
        const { selectedDocuments } = this.state;

        this.setState(
        {
            downloading: true
        });

        var requestBody = selectedDocuments;

        // Get downloadable zip file containing selected documents.
        apiPostFileJson("documents/general/get", requestBody).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                downloading: false,
            });

            // Trigger file download
            const data = window.URL.createObjectURL(result);
            var link = document.createElement('a');
            link.href = data;
            link.download="documents.zip";
            link.click();
        },
        (error) =>
        {
            this.setState(
            {
                downloading: false,
                error: error.data
            });
        });
    }

    render() {
        const { error, isLoaded, generalDocs, selectedDocuments, downloading } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading general documents..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>General Documents</h4>
                            <p className={classes.cardCategoryWhite}>
                                Overview of general documents.
                            </p>
                        </CardHeader>
                        <CardBody>
                            {
                                selectedDocuments.length > 0
                                ?
                                (
                                    <Typography align="right">  
                                        <span style={{marginRight: "10px"}}>
                                            {selectedDocuments.length === 1 ? "1 document selected" : selectedDocuments.length + " documents selected"}
                                        </span>
                                        <Button color="secondary" disabled={downloading} onClick={() => this.downloadDocuments()}>
                                            {
                                                downloading
                                                ?
                                                "Preparing download..."
                                                :
                                                (selectedDocuments.length === 1 ? "Download Document (.zip)" : "Download Documents (.zip)")
                                            }                                            
                                        </Button>                      
                                    </Typography> 
                                )
                                :
                                null
                            }         

                            <TableJson
                                tableHeaderColor="primary"
                                tableHead={["File name", "File extension", "Uploaded on"]}
                                tableHeadDataMap={["FileName", "FileExtension", "CreationTime"]}
                                tableData={generalDocs}
                                selectableRows={true}
                                selectedItems={selectedDocuments}
                                isClickable={true}
                                isSortable={true}
                                onListItemClicked={(listItem) => this.setState({ triggerRender: "" }) }
                                initialOrderBy={"CreationTime"}
                                initialSortingOrder={"desc"}
                                tableDateColumns={["CreationTime"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(GeneralDocumentsPage)