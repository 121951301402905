import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import InfoIcon from '@material-ui/icons/Info';

// core components
import customSelectStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import InputInfoButton from "components/CustomInput/InputInfoButton.jsx";

function CustomSelect({ ...props }) 
{
  const 
  {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    onValueChange,
    selectedValue,
    values,
    keyColumn,
    valueColumn,
    infoText
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  
  const createOptionItemsFromValues = () =>
  {
    // If keycolumn is not null, use the key and value column. Else use the item.
    if (keyColumn)
    {
      return values.map((item, key) =>
      {
        return(<option key={key} value={item[keyColumn]}>{item[valueColumn]}</option>)
      });
    }
    else
    {
      return values.map((item, key) =>
      {
        return(<option key={key} value={item}>{item}</option>)
      });
    }
  }

  return (
    <FormControl {...formControlProps} className={formControlProps.className + " " + classes.formControl}>
      {
        labelText !== undefined ? 
        (
          <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
            {labelText}
          </InputLabel>
        ) : null
      }
      
      <NativeSelect classes=
      {{
          root: marginTop,
          disabled: classes.disabled
      }}
      id={id} {...inputProps} 
      onChange={(event) => onValueChange ? onValueChange(event.target.value) : null}
      value={selectedValue}
      input={<Input id={id} 
      endAdornment={
        infoText ?
        <InputInfoButton text={infoText}/>
        :
        null
      } />}>
        <option key="0" value=""></option>
        {
          createOptionItemsFromValues()
        }
      </NativeSelect>
      
      {
        error ? 
        (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? 
        (
          <Check className={classes.feedback + " " + classes.labelRootSuccess} />
        ) : null
      }
    </FormControl>
  );
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onValueChange: PropTypes.func,
  values: PropTypes.array,
  keyColumn: PropTypes.string,
  valueColumn: PropTypes.string,
  infoText: PropTypes.string
};

export default withStyles(customSelectStyle)(CustomSelect);
