// @material-ui/icons
import Home from "@material-ui/icons/Home";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import Notifications from "@material-ui/icons/Notifications";
import Folder from "@material-ui/icons/FolderOpen";
import AllInbox from "@material-ui/icons/AllInbox";
import Call from "@material-ui/icons/Call";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import CloudDone from "@material-ui/icons/CloudDone";
import CloudQueue from "@material-ui/icons/CloudQueue";
import DNS from "@material-ui/icons/Dns";
import Settings from "@material-ui/icons/Settings";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import TableList from "views/TableList/TableList.jsx";
import Typography from "views/Typography/Typography.jsx";
import Icons from "views/Icons/Icons.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";

import CasesPage from "views/Cases/Cases.jsx";
import NewCasePage from "views/Cases/NewCase.jsx";
import ClosedCasesPage from "views/Cases/ClosedCases.jsx";
import ClosedCaseInfoPage from "views/Cases/CaseInfo/ClosedCaseInfoPage.jsx";
import CaseInfoPage from "views/Cases/CaseInfo/CaseInfoPage.jsx";

import DocumentsOverviewPage from "views/Documents/DocumentsOverview.jsx";
import GeneralDocumentsPage from "views/Documents/GeneralDocuments.jsx";
import ContractsPage from "views/Documents/Contracts.jsx";
import InvoicesPage from "views/Documents/Invoices.jsx";
import InvoicesReportingPage from "views/Documents/InvoicesReporting.jsx";

import OrdersPage from "views/Orders/Orders.jsx";
import GeneratePriceListPage from "views/Orders/GeneratePriceList.jsx";
import ApproveOrderPage from "views/Orders/ApproveOrder.jsx";
import NewOrderPage from "views/Orders/NewOrder.jsx";
import NewAddOnOrderPage from "views/Orders/NewAddOnOrder.jsx";
import OrderHistoryPage from "views/Orders/OrderHistory.jsx";

import SettingsOverviewPage from "views/Settings/SettingsOverview.jsx";
import SettingsGraphApiPage from "views/Settings/SettingsGraphApi";

import UCallManagerPage from "views/UCallManager/UCallManager.jsx";

import QueuesPage from "views/UCallManager/Queues/Queues.jsx";
import ViewQueuePage from "views/UCallManager/Queues/ViewQueue/ViewQueue.jsx";
import NewQueuePage from "views/UCallManager/Queues/NewQueue.jsx";

import AgentsPage from "views/UCallManager/Agents.jsx";
import HolidaysPage from "views/UCallManager/Holidays.jsx";
import VoicePromptsPage from "views/UCallManager/VoicePrompts.jsx";

import IVROverviewPage from "views/UCallManager/IVR/IVROverview.jsx";
import NewIVRPage from "views/UCallManager/IVR/NewIVR/NewIVR.jsx"
import IVRDetailsOverviewPage from "views/UCallManager/IVR/IVRDetails/IVRDetailsOverview.jsx";

import AudioLibraryOverviewPage from "views/UCallManager/AudioLibrary/AudioLibraryOverview.jsx";

import RecordingsPage from "views/UCallManager/Recordings.jsx";
import ReportingPage from "views/UCallManager/Reporting.jsx";
import CallDetailRecordsPage from "views/UCallManager/CallDetailRecords.jsx";

import Cookies from "js-cookie";

const dashboardRoutes = [
  // ------------------------ Customer Portal ------------------------
  // Home
  {
    path: "/dashboard",
    name: "Home",
    icon: Home,
    component: DashboardPage,
    parentRoute: null,
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: []
  },

  // Cases
  // {
  //   path: "/cases",
  //   name: "Cases",
  //   icon: AllInbox,
  //   component: CasesPage,
  //   parentRoute: null,
  //   displayRoute: true,
  //   portalPermissionsMinimum: [],
  //   portalPermissions: [
  //     "ADMINCASES",
  //     "PERSONALCASESONLY",
  //     "READALLCASES",
  //     "READALLCASESPERSONALWRITE"
  //   ]
  // },
  {
    path: "https://customerservice.universal.cloud",
    name: "Cases (link)",
    icon: AllInbox,
    component: null,
    parentRoute: null,
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
    ],
    portalPermissions: [
      "ADMINCASES",
      "PERSONALCASESONLY",
      "READALLCASES",
      "READALLCASESPERSONALWRITE"]
  },
  // {
  //   path: "/cases/new",
  //   name: "New Case",
  //   icon: null,
  //   component: NewCasePage,
  //   parentRoute: "/cases",
  //   displayRoute: true,
  //   portalPermissionsMinimum: [],
  //   portalPermissions: [
  //     "ADMINCASES",
  //     "PERSONALCASESONLY",
  //     "READALLCASESPERSONALWRITE"
  //   ]
  // },
  // {
  //   path: "/cases/closed",
  //   name: "Closed Cases",
  //   icon: null,
  //   component: ClosedCasesPage,
  //   parentRoute: "/cases",
  //   displayRoute: true,
  //   portalPermissionsMinimum: [],
  //   portalPermissions: [
  //     "ADMINCASES",
  //     "PERSONALCASESONLY",
  //     "READALLCASESPERSONALWRITE"
  //   ]
  // },
  // {
  //   path: "/cases/ticket",
  //   name: "Ticket",
  //   icon: null,
  //   component: CaseInfoPage,
  //   parentRoute: "/cases",
  //   displayRoute: false,
  //   portalPermissionsMinimum: [],
  //   portalPermissions: [
  //     "ADMINCASES",
  //     "PERSONALCASESONLY",
  //     "READALLCASES",
  //     "READALLCASESPERSONALWRITE"
  //   ]
  // },
  // {
  //   path: "/cases/closedticket",
  //   name: "Ticket",
  //   icon: null,
  //   component: ClosedCaseInfoPage,
  //   parentRoute: "/cases",
  //   displayRoute: false,
  //   portalPermissionsMinimum: [],
  //   portalPermissions: [
  //     "ADMINCASES",
  //     "PERSONALCASESONLY",
  //     "READALLCASES",
  //     "READALLCASESPERSONALWRITE"
  //   ]
  // },
  // Documents
  {
    path: "/documents",
    name: "Documents",
    icon: Folder,
    component: DocumentsOverviewPage,
    parentRoute: null,
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "UCP.DOCUMENTS.GENERAL.VIEW",
      "VIEWCONTRACTS",
      "ADMININVOICES"
    ]
  },
  {
    path: "/documents/general",
    name: "General",
    icon: null,
    component: GeneralDocumentsPage,
    parentRoute: "/documents",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "UCP.DOCUMENTS.GENERAL.VIEW"
    ]
  },
  {
    path: "/documents/contracts",
    name: "Contracts",
    icon: null,
    component: ContractsPage,
    parentRoute: "/documents",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "VIEWCONTRACTS"
    ]
  },
  {
    path: "/documents/invoices",
    name: "Invoices",
    icon: null,
    component: InvoicesPage,
    parentRoute: "/documents",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "ADMININVOICES"
    ]
  },
  {
    path: "/documents/reporting",
    name: "Invoices Reporting",
    icon: null,
    component: InvoicesReportingPage,
    parentRoute: "/documents",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "ADMININVOICES"
    ]
  },

  // Orders
  {
    path: "/orders",
    name: "Orders",
    icon: ShoppingCart,
    component: OrdersPage,
    parentRoute: null,
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "READORDERS",
      "ADMINORDERS"
    ]
  },
  {
    path: "/orders/approval",
    name: "Approve order",
    icon: null,
    component: ApproveOrderPage,
    parentRoute: "/orders",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "UNIVERSALAPPROVEORDERS"
    ]
  },
  {
    path: "/orders/generatepricelist",
    name: "Generate price list",
    icon: null,
    component: GeneratePriceListPage,
    parentRoute: "/orders",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "UNIVERSALGENERATEPRICELIST"
    ]
  },
  {
    path: "/orders/new",
    name: "New order",
    icon: null,
    component: NewOrderPage,
    parentRoute: "/orders",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "ADMINORDERS"
    ]
  },
  /*{
    path: "/orders/addons/new",
    name: "New add-on order",
    icon: null,
    component: NewAddOnOrderPage,
    parentRoute: "/orders",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "ADMINORDERS"
    ]
  },*/
  {
    path: "/orders/history",
    name: "Order history",
    icon: null,
    component: OrderHistoryPage,
    parentRoute: "/orders",
    displayRoute: true,
    portalPermissionsMinimum: [],
    portalPermissions: [
      "READORDERS",
      "ADMINORDERS"
    ]
  },

  // Profile
  {
    path: "/profile",
    name: "My Profile",
    icon: null,
    component: UserProfile,
    parentRoute: "",
    displayRoute: false,
    portalPermissionsMinimum: [],
    portalPermissions: []
  },

  // ------------------------ UCallManager ------------------------
  // Dashboard
  {
    path: "/ucm",
    name: "uCallManager",
    icon: Call,
    component: UCallManagerPage,
    parentRoute: null,
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: []
  },
  
  // Queues
  {
    path: "/ucm/queues",
    name: "Queues",
    icon: null,
    component: QueuesPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.QUEUES.VIEW",
      "UCALLMANAGER.QUEUES.EDIT"
    ]
  },
  {
    path: "/ucm/queues/new",
    name: "New Queue",
    icon: null,
    component: NewQueuePage,
    parentRoute: "/ucm",
    displayRoute: false,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: ["UCALLMANAGER.QUEUES.EDIT"]      
  },
  {
    path: "/ucm/queues/view",
    name: "View Queue",
    icon: null,
    component: ViewQueuePage,
    parentRoute: "/ucm",
    displayRoute: false,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.QUEUES.VIEW",
      "UCALLMANAGER.QUEUES.EDIT"]
  },

  // Agents
  {
    path: "/ucm/agents",
    name: "Agents",
    icon: null,
    component: AgentsPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.AGENTS.VIEW",
      "UCALLMANAGER.AGENTS.EDIT",
      "UCALLMANAGER.QUEUES.EDIT"
    ]
  },

  // Holidays
  {
    path: "/ucm/holidays",
    name: "Holidays",
    icon: null,
    component: HolidaysPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS",
    ],
    portalPermissions: [
      "UCALLMANAGER.HOLIDAYS.EDIT",
      "UCALLMANAGER.QUEUES.EDIT",
      "UCALLMANAGER.IVR.EDIT"
    ]
  },

  // // Voice Prompts
  // {
  //   path: "/ucm/voiceprompts",
  //   name: "Voice Prompts",
  //   icon: null,
  //   component: VoicePromptsPage,
  //   parentRoute: "/ucm",
  //   displayRoute: true,
  //   portalPermissionsMinimum: [
  //     "UCALLMANAGER.ACCESS"
  //   ],
  //   portalPermissions: []
  // },

  // IVR
  {
    path: "/ucm/ivr",
    name: "IVR",
    icon: null,
    component: IVROverviewPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.IVR.VIEW",
      "UCALLMANAGER.IVR.EDIT"]
  },

  {
    path: "/ucm/ivr/new",
    name: "New IVR",
    icon: null,
    component: NewIVRPage,
    parentRoute: "/ucm",
    displayRoute: false,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS",
      "UCALLMANAGER.IVR.EDIT"
    ],
    portalPermissions: []
  },

  {
    path: "/ucm/ivr/details",
    name: "IVR Details",
    icon: null,
    component: IVRDetailsOverviewPage,
    parentRoute: "/ucm",
    displayRoute: false,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.IVR.VIEW",
      "UCALLMANAGER.IVR.EDIT"
    ]
  },

  // Audio Library
  {
    path: "/ucm/audio",
    name: "Audio Library",
    icon: null,
    component: AudioLibraryOverviewPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS"
    ],
    portalPermissions: [
      "UCALLMANAGER.IVR.VIEW",
      "UCALLMANAGER.IVR.EDIT",
      "UCALLMANAGER.AUDIOLIBRARY.EDIT"
    ]
  },
  
  // Call Recordings
  {
    path: "/ucm/recordings",
    name: "Recordings",
    icon: null,
    component: RecordingsPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS",
      "UCALLMANAGER.CALLRECORDINGS.VIEW"
    ],
    portalPermissions: []
  },

 // Reporting
 {
  path: "/ucm/reporting",
  name: "Reporting",
  icon: null,
  component: ReportingPage,
  parentRoute: "/ucm",
  displayRoute: true,
  portalPermissionsMinimum: [
    "UCALLMANAGER.ACCESS"
  ],
  portalPermissions: []
},

  // Call Detail Records
  {
    path: "/ucm/calldetailrecords",
    name: "Call Detail Records",
    icon: null,
    component: CallDetailRecordsPage,
    parentRoute: "/ucm",
    displayRoute: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS",
      "UCALLMANAGER.CALLDETAILRECORDS.VIEW"
    ],
    portalPermissions: []
  },

  // UWallboard (link to: https://uwallboard.com)
  {
    path: "https://uwallboard.com?user=", // token to uwallboard is added in sidebar.jsx
    name: "uWallboard (Link)",
    icon: null,
    component: null,
    parentRoute: "/ucm",
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
      "UCALLMANAGER.ACCESS",
      "UCALLMANAGER.UWALLBOARD.VIEW"
    ],
    portalPermissions: []
  },

  
  // ------------------------ Customer Portal ------------------------
  // Service status (link to: https://status.universal.nl/);
  {
    path: "https://status.universal.nl",
    name: "Service Status (Link)",
    icon: CloudDone,
    component: null,
    parentRoute: null,
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
    ],
    portalPermissions: []
  },

  // Universal DNS (link to: https://dns.universal.cloud);
  {
    path: "https://dns.universal.cloud/",
    name: "Universal DNS (Link)",
    icon: DNS,
    component: null,
    parentRoute: null,
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
      "UCP.DNS"
    ],
    portalPermissions: []
  },

  // Control Cloud Service (link to: https://controlcloudservice.com/);
  {
    path: "https://controlcloudservice.com/",
    name: "Control Cloud Service (Link)",
    icon: CloudQueue,
    component: null,
    parentRoute: null,
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
      "ADMINCCS"
    ],
    portalPermissions: []
  },

  // Skype Panel (link to: https://skype.controlcloudservice.com/);
  /*{
    path: "https://skype.controlcloudservice.com/",
    name: "Skype Panel (Link)",
    icon: Dashboard,
    component: null,
    parentRoute: null,
    displayRoute: true,
    isLink: true,
    portalPermissionsMinimum: [
      "ADMINSKYPECCS"
    ],
    portalPermissions: []
  },*/

  // Settings page
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: SettingsOverviewPage,
    parentRoute: null,
    displayRoute: true,
    isLink: false,
    portalPermissionsMinimum: [
      "UCP.SETTINGS.ACCESS"
    ],
    portalPermissions: []
  },
  {
    path: "/settings/graph-api",
    name: "Graph API",
    icon: null,
    component: SettingsGraphApiPage,
    parentRoute: "/settings",
    displayRoute: true,
    isLink: false,
    portalPermissionsMinimum: [
      "UCP.SETTINGS.ACCESS",
      "UCP.SETTINGS.GRAPHAPI.EDIT"
    ],
    portalPermissions: []
  },

  // Link route example
  // {
  //   path: "/ucm/calldetailrecords",
  //   name: "Call Detail Records (Link)",
  //   icon: null,
  //   component: null,
  //   parentRoute: "/ucm",
  //   displayRoute: true,
  //   isLink: true,
  //   portalPermissionsMinimum: [
  //     "UCALLMANAGER.ACCESS",
  //     "UCALLMANAGER.CALLDETAILRECORDS.VIEW"
  //   ],
  //   portalPermissions: []
  // },

  // ------------------------ Samples ------------------------ 
  // Default
  {
    path: "/table",
    name: "Table List",
    icon: "content_paste",
    component: TableList,
    parentRoute: null,
    displayRoute: false,
    portalPermissionsMinimum: [],
    portalPermissions: []
  },
  {
    path: "/typography",
    name: "Typography",
    icon: LibraryBooks,
    component: Typography,
    parentRoute: null,
    displayRoute: false,
    portalPermissionsMinimum: [],
    portalPermissions: []
  },
  {
    path: "/icons",
    name: "Icons",
    icon: BubbleChart,
    component: Icons,
    parentRoute: null,
    displayRoute: false,
    portalPermissionsMinimum: [],
    portalPermissions: []
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    parentRoute: null,
    displayRoute: false,
    portalPermissionsMinimum: [],
    portalPermissions: []
  }
];

export default dashboardRoutes;
