import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PublishIcon from '@material-ui/icons/Publish';
import AlertIcon from "@material-ui/icons/ReportProblemOutlined"

import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import { apiPostFormData } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class AudioLibraryAddDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            uploadingFiles: false,

            selectedFilesText: "No file(s) selected.",
            warningText: ""
        }
    }
    
    submitForm()
    {
        this.setState({uploadingFiles: true})

        // Create request body
        var formDataRequestBody = new FormData();

        var filesToUpload = document.getElementById("file-upload").files;

        for (var i = 0; i < filesToUpload.length; i++) {      
            var file = filesToUpload[i];

            if (file.size > 15000000)
            {
                this.setState({ uploadingFiles: false });

                this.props.onSubmitError("Oops, file size can not be bigger than 15 MB.");
                return;
            }
            else if (file.type != "audio/wav")
            {
                this.setState({ uploadingFiles: false });

                this.props.onSubmitError("Oops, you can only upload .wav files.");
                return;
            }

            formDataRequestBody.append("attachments", file);
        }

        // Upload new audio file
        apiPostFormData("ucm/audio/add", formDataRequestBody).then(() =>        
        {
            this.setState(
            {
                uploadingFiles: false
            }); 

            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                uploadingFiles: false
            });

            this.props.onSubmitError(error.data);
        });
    }

    setSelectedFilesText = () =>
    {
        var selectedFilesText = "";
        var warningTextBase = "The following audio files already exist and will be overwritten: ";
        var warningText = "";
        var fileInput = document.getElementById("file-upload");
        var existingAudioFiles = this.props.existingAudioFiles.map(function (file) {
            return file.Filename;
        });
        
        if( fileInput.files && fileInput.files.length > 1 )
        {
            var fileSize = 0;

            for (var i = 0; i < fileInput.files.length; i++)
            {
                fileSize += parseFloat((fileInput.files[i].size / 1000000).toFixed(2));

                if (existingAudioFiles.includes(fileInput.files[i].name))
                {
                    if (warningText === "")
                    {
                        warningText += fileInput.files[i].name;
                    }
                    else 
                    {
                        warningText += ", " + fileInput.files[i].name;
                    }
                }
            }

            selectedFilesText = fileInput.files.length + " files selected (" + fileSize + " MB).";            
        }
        else
        {
            selectedFilesText = fileInput.value.split( '\\' ).pop() + " (" + parseFloat((fileInput.files[0].size / 1000000).toFixed(2)) + " MB).";

            if (existingAudioFiles.includes(fileInput.files[0].name))
            {
                warningText += fileInput.files[0].name;
            }
        }

        this.setState(
        {
            selectedFilesText: selectedFilesText,
            warningText: warningText ? warningTextBase + warningText : ""
        });
    }

    render() 
    {    
        const { uploadingFiles } = this.state;
        const { selectedFilesText, warningText } = this.state;

        return (
            <form onSubmit={(e) => {e.preventDefault(); this.submitForm()}}>
                <DialogTitle id="form-dialog-title">Upload new audio file(s)</DialogTitle>
                <DialogContent>
                    {/* TODO: Create reusable control */}
                    <p>Please note, the files need to match the following criteria:<br/><br/>
                    Maximum bitrate: 8000Khz<br/>
                    Audio stream: Mono<br/>
                    File type: WAV</p>
                    <div>
                        <label>Max. file size: 15 MB</label>
                        <br/>
                        <Button onClick={() => document.getElementById("file-upload").click()} color="secondary" style={{marginRight: "10px"}}>
                                <PublishIcon></PublishIcon>
                                Select files...
                        </Button>
                        <span>{selectedFilesText}</span>

                        {
                            warningText ? <p style={{color: "#ff9800"}}><AlertIcon></AlertIcon><br/> {warningText}</p> : null
                        }

                        <input id="file-upload" type="file" style={{display:"none"}} multiple="multiple" onChange={() => this.setSelectedFilesText()}></input>  
                    </div> 

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white">
                        Cancel
                    </Button>
                    <Button disabled={uploadingFiles} type="submit" color="secondary">
                        {uploadingFiles ? "Uploading File(s)..." : "Upload File(s)"}
                    </Button>
                </DialogActions>
            </form>
        );   
    }
}

AudioLibraryAddDialog.propTypes = {
    existingAudioFiles: PropTypes.array,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(AudioLibraryAddDialog)