import React from "react";
import classNames from "classnames";
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.jsx";

import globalData from "variables/globalData.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
// Extra libs
import Cookies from "js-cookie";

class HeaderLinks extends React.Component 
{
  constructor(props) 
  {
      super(props);
      
      this.state = 
      {
        open: false,
        openProfileMenu: false
      };
  }

  handleToggle = () => 
  {
    this.setState(state => ({ open: !state.open }));
  };

  handleProfileMenuToggle = () =>
  {
    this.setState(state => ({ openProfileMenu: !state.open }));
  }

  handleClose = event => 
  {
    if (this.anchorEl.contains(event.target)) 
    {
      return;
    }

    this.setState({ open: false });
  };

  handleProfileMenuClose = event => 
  {
    if (this.anchorEl.contains(event.target)) 
    {
      return;
    }

    this.setState({ openProfileMenu: false });
  };

  render() 
  {
    const { classes } = this.props;
    const { open, openProfileMenu } = this.state;
    
    return (
      <div>
        <div className={classes.manager}>
          {/* <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}>
          
            <Notifications className={classes.icons} />
            <span className={classes.notifications}>3</span>
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button> */}

          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }>
          
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}>
              
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Mike John responded to your email
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        You have 5 new tasks
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        You're now friend with Andrew
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>


        </div>
        
        <Button
          buttonRef={node => {
            this.anchorEl = node;
          }}
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfileMenu ? "menu-list-grow" : null}
          aria-haspopup="true"
          aria-label="Person"
          onClick={this.handleProfileMenuToggle}
          className={classes.buttonLink}>

          <Person className={classes.icons} />
          
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>{globalData.loggedInUser.FullName}</p>
          </Hidden>
        </Button>
          <Poppers
            open={openProfileMenu}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfileMenu }) +
              " " +
              classes.pooperNav
            }>
          
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}>
              
                <Paper>
                  <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                    <MenuList role="menu">
                      <NavLink to="/profile">
                        <MenuItem className={classes.dropdownItem}>
                         My Profile
                        </MenuItem>
                      </NavLink>
                      <NavLink to="/login">
                        <MenuItem className={classes.dropdownItem} onClick={() =>
                        {
                          Cookies.remove("auth");
                          Cookies.set("logoutSession", "true");
                        }}>
                          Sign out
                        </MenuItem>
                      </NavLink>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
