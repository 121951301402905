import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import { apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRBusinessHoursAddDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            addingBusinessHours: false,

            // Data
            monChecked: false,
            tueChecked: false,
            wedChecked: false,
            thuChecked: false,
            friChecked: false,
            satChecked: false,
            sunChecked: false,
            openTime: "08:30",
            closeTime: "17:00"
        }
    }
    
    submitForm()
    {
        this.setState({addingBusinessHours: true})

        // TODO: authentication
        const { openTime, closeTime, monChecked, tueChecked, wedChecked, thuChecked, friChecked, satChecked, sunChecked } = this.state;

        // Create selected days array
        var selectedDays = [];

        if (monChecked) selectedDays.push("MON");
        if (tueChecked) selectedDays.push("TUE");
        if (wedChecked) selectedDays.push("WED");
        if (thuChecked) selectedDays.push("THU");
        if (friChecked) selectedDays.push("FRI");
        if (satChecked) selectedDays.push("SAT");
        if (sunChecked) selectedDays.push("SUN");

        // Create request body
        var requestBody = 
        {
            IVRName: this.props.ivrName,
            OpenTime : openTime,
            ClosedTime : closeTime,
            Days : selectedDays
        }

        // Add business hours
        apiPostJson("ucm/ivr/hours/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingBusinessHours: false
            });
            
            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingBusinessHours: false
            });

            this.props.onSubmitError(error.data);
        });
    }

    render() 
    {    
        const { classes } = this.props; 
        const { addingBusinessHours } = this.state;
        const { openTime, closeTime, monChecked, tueChecked, wedChecked, thuChecked, friChecked, satChecked, sunChecked } = this.state;

        return (
            <form onSubmit={(e) => {e.preventDefault(); this.submitForm();}}>
                <DialogTitle id="form-dialog-title">Add new business hour record to IVR.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please, enter the desired hours below.
                    </DialogContentText>
                    <FormControl className={classes.formControl}>                                
                        <GridContainer justify="center">
                            <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={monChecked} onChange={(event) => this.setState({ monChecked: event.target.checked })}/>}
                                        label="Monday"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={tueChecked} onChange={(event) => this.setState({ tueChecked: event.target.checked })}/>}
                                        label="Tuesday"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={wedChecked} onChange={(event) => this.setState({ wedChecked: event.target.checked })}/>}
                                        label="Wednesday"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={thuChecked} onChange={(event) => this.setState({ thuChecked: event.target.checked })}/>}
                                        label="Thursday"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={friChecked} onChange={(event) => this.setState({ friChecked: event.target.checked })}/>}
                                        label="Friday"
                                    />
                                </FormGroup>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={satChecked} onChange={(event) => this.setState({ satChecked: event.target.checked })}/>}
                                        label="Saturday"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={sunChecked} onChange={(event) => this.setState({ sunChecked: event.target.checked })}/>}
                                        label="Sunday"
                                    />
                                </FormGroup>
                            </GridItem>
                        </GridContainer>
                    </FormControl>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomInput
                                labelText="Open Time"
                                id="open-time"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => this.setState({ openTime: value })
                                }
                                inputProps={{
                                    autoFocus: true,
                                    type: "time",
                                    defaultValue: openTime
                                }}
                            />
                        </GridItem>

                        <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomInput
                                labelText="Close Time"
                                id="close-time"
                                formControlProps={{
                                    fullWidth: true,
                                    required: true
                                }}
                                onValueChange = 
                                {
                                    (value) => this.setState({ closeTime: value })
                                }
                                inputProps={{
                                    type: "time",
                                    defaultValue: closeTime
                                }}
                            />
                        </GridItem>
                    </GridContainer>                      
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white">
                        Cancel
                    </Button>
                    <Button disabled={(!monChecked && !tueChecked && !wedChecked && !thuChecked && !friChecked && !satChecked && !sunChecked) || addingBusinessHours} type="submit" color="secondary">
                        {addingBusinessHours ? "Adding record..." : "Add record"}  
                    </Button>
                </DialogActions>
            </form>
        );   
    }
}

IVRBusinessHoursAddDialog.propTypes = {
    ivrName: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRBusinessHoursAddDialog)