import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import AllInbox from "@material-ui/icons/AllInbox";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const allowedPortalPermissions =
[
    "ADMINCASES",
    "PERSONALCASESONLY",
    "READALLCASES",
    "READALLCASESPERSONALWRITE"
]

class PortalCasesWidget extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            casesCount: 0
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get active cases count
        this.getCasesCount();
    }

    getCasesCount()
    {
        apiGet("cases/count").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                casesCount: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    render() 
    {
        const { classes } = this.props;
        const { isLoaded, error, casesCount } = this.state;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading cases widget..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        
        return (
            <Card>
                <CardHeader color="success" stats icon>
                <CardIcon color="success">
                    <AllInbox />
                </CardIcon>
                <p className={classes.cardCategory}>Support Cases</p>
                <h3 className={classes.cardTitle}>{ casesCount === 1 ? "1 ticket" : (casesCount + " tickets")}</h3>
                </CardHeader>
                <CardFooter stats>
                <NavLink to="/cases">
                    Go to support cases
                </NavLink>  
                </CardFooter>
            </Card>
        );
    }
}
export default withStyles(dashboardStyle)(PortalCasesWidget);