import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class NewOrderTenantSelect extends React.Component 
{
    constructor(props) 
    {
        super(props);
    }

    render() 
    {
        const { tenant, onSelectTenant, tenants } = this.props;
        
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomSelect
                        labelText="Tenant"
                        id="tenant-name"
                        formControlProps={{
                            fullWidth: true,
                            required: true
                        }}
                        values={tenants}
                        keyColumn="ID"
                        valueColumn="TenantName"
                        selectedValue={tenant}
                        onValueChange = 
                        {
                            (value) => { onSelectTenant(value) }
                        }
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );       
    }
}

export default withStyles(styles)(NewOrderTenantSelect)