import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import typographyStyle from "assets/jss/material-dashboard-react/components/typographyStyle.jsx";

function Danger({ ...props }) {
  const { classes, children, visible } = props;
  return (
    <div className={ classes.defaultFontStyle + " " + classes.dangerText } style={{display: (visible === false ? "none" : "block")}} >
      {children}
    </div>
  );
}

Danger.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(typographyStyle)(Danger);
