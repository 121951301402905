import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from "components/CustomButtons/Button.jsx";

import { apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRHolidaysDeleteDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            removingHoliday: false
        }
    }

    removeHoliday()
    {
        this.setState({removingHoliday: true});

        const { selectedHolidayID, ivrName } = this.props;
        
        var requestBody = 
        {
            HolidayID : selectedHolidayID,
            IVRName : ivrName
        };

        // Remove IVR
        apiPostJson("ucm/ivr/holidays/remove", requestBody).then(() =>        
        {
            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingHoliday: false
            });

            this.props.onSubmitError(error.data.Message);
            this.props.onDialogClose();
        });
    }

    render() 
    {    
        const { removingHoliday } = this.state;

        return (
            <div>
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this holiday from the IVR?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will not affect other IVR's.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white" autoFocus>
                        Cancel
                    </Button>
                    <Button disabled={removingHoliday} onClick={() => this.removeHoliday()} color="danger">
                        {removingHoliday ? "Removing holiday..." : "Remove holiday"}                            
                    </Button>
                </DialogActions>
            </div>
        );   
    }
}

IVRHolidaysDeleteDialog.propTypes = {
    selectedHolidayID: PropTypes.number,
    ivrName: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRHolidaysDeleteDialog)