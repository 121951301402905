import React from "react";

import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import GraphAPIWidget from "views/Dashboard/PortalWidgets/PortalSettingsGraphApiWidget.jsx";

import Divider from '@material-ui/core/Divider';
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPermissions = [
    "UCP.SETTINGS.ACCESS"
  ]
  
  const allowedGraphApiPermissions = [   
    "UCP.SETTINGS.GRAPHAPI.EDIT"
  ]

class SettingsOverview extends React.Component {

    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null
        }
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                error: "Access denied"
            });

            return;
        }
    }

    render() {
        const { classes } = this.props;
        const { error } = this.state;

        if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        } 

        return (
            <div>
                <h2>Settings</h2>
                <GridContainer>
                    {
                        PMUtils.userHasAnyRequiredPermissions(allowedGraphApiPermissions, globalData.loggedInUser.PortalPermissions)
                    ?
                        (
                            <GridItem xs={12} sm={6} md={6} lg={4} xl={4}>
                                <GraphAPIWidget />
                            </GridItem>
                        )
                    :
                        null
                    }
                </GridContainer>
            </div>
        );
    }
}

SettingsOverview.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(SettingsOverview);
