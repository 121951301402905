import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import globalData from "variables/globalData.jsx";
import { apiGet, apiPost } from "utils/webRequestUtils.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const requiredPortalPermissions =
[
    "UNIVERSALGENERATEPRICELIST"
]

class ApproveOrder extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            approvingOrder: false,
            removingOrder: false,
            orderToRemove: null,
            orderToApprove: null,
            approveOrderDialogOpen: false,
            removeOrderDialogOpen: false,
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",
            orders: []
        }
    }

    componentDidMount()
    {
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    isLoaded: true,
                    error: "Access denied"
                });

            return;
        }

        this.setState(
        {
            isLoaded: true
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) => {
        this.setState(
            {
                toastOpen: true,
                toastColor: toastColor,
                toastText: toastText
            });

        this.alertTimeout = setTimeout(
            function () {
                this.setState(
                    {
                        toastOpen: false
                    });
            }.bind(this),
            3000
        );
    }

    generateList() {
        this.setState({ generatingList: true });

        // Approve Order
        apiGet("pc/products/generate").then(() => {
            this.setState(
                {
                    generatingList: false
                });

            this.showNotification("success", "List has been generated");
        },
        (error) => {
            this.setState(
                {
                    //TODO: User friendly error.                   
                    generatingList: false
                });

            this.showNotification("danger", error.data);
        });
    }  

    render() 
    {
        const { error, isLoaded, toastColor, toastText, toastOpen, generatingList: generatingList } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else
        {
            return (
                
                <div>

                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Generate price list</h4>
                                <p className={classes.cardCategoryWhite}>
                                    Click the button below to generate a new price list. 
                                </p>
                            </CardHeader>
                            <CardBody>
                                
                            <Button disabled={generatingList} onClick={() => this.generateList()} color="secondary">
                            {generatingList ? "Generating list..." : "Generate"}                            
                            </Button>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                </div>
            );
        }        
    }
}

export default withStyles(styles)(ApproveOrder)