import React from "react";

import Spinner from "assets/img/svg/spinner.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

function LoadingIndicator({ ...props }) 
{
    const { loadingText, fullScreen, bigSpinner } = props;

    const style =
    {
        width: "100%",
        height: "75%",
        
        position: (fullScreen ?  "absolute" : ""),
        top:0,
        bottom: 0,
        left: 0,
        right: 0,
        
        textAlign: "center",
        margin: "auto"
    }

    return (
        <div style={style}>
            {
                bigSpinner ? <img src={Spinner} alt="Spinner"></img> : <CircularProgress color="primary"/>
            }            
            <br/>
            {loadingText}
        </div>
        
    );
}

export default (LoadingIndicator);