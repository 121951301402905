import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import InfoIcon from '@material-ui/icons/Info';

// core components
import customSelectStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import InputInfoButton from "components/CustomInput/InputInfoButton.jsx";

function CustomEntrySelect({ ...props }) 
{
  const 
  {
    onPress,
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    onValueChange,
    selectedValue,
    selectedName,
    values,
    keyColumn,
    valueColumn,
    infoText
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  
  const createOptionItemsFromValues = () =>
  {
    // If keycolumn is not null, use the key and value column. Else use the item.
    if (keyColumn)
    {
      return values.map((item, key) =>
      {
        return(<option key={key} value={item[keyColumn]}>{item[valueColumn]}</option>)
      });
    }
    else
    {
      return values.map((item, key) =>
      {
        return(<option key={key} value={item}>{item}</option>)
      });
    }
  }

  return (
    <div className="container">

      <div>Currently selected: {selectedName}</div><br/><br/>   

      <div className="user-list">
        {values && values.length > 0 ? (
          values.map((user) => (            
            <div key={user.id} onClick={() => onPress(user.id, user.userPrincipalName)} className="user" style={{cursor:'pointer'}}>              
              <span className="user-name"><b>{user.name}</b>&nbsp;&nbsp;{user.id == selectedValue ? <CheckCircleIcon color="primary" fontSize="small" /> : ""}</span><br/>
              <span className="user-principal-name" style={{ fontSize: 12 + 'px' }}>({user.userPrincipalName})</span><br/><br/>
            </div>
          ))
        ) : (
          <h6>No results found!</h6>
        )}
      </div>
    </div>
  );
}

CustomEntrySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  onValueChange: PropTypes.func,
  values: PropTypes.array,
  keyColumn: PropTypes.string,
  valueColumn: PropTypes.string,
  infoText: PropTypes.string
};

export default withStyles(customSelectStyle)(CustomEntrySelect);
