/* eslint-disable */
import React from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { 
  useMsal,
  useIsAuthenticated 
} from "@azure/msal-react";

import { 
  InteractionStatus
} from "@azure/msal-browser";


import { loginRequest } from "../../utils/azuread/authConfig";

// Extra libs
import Cookies from "js-cookie";
import formurlencoded from 'form-urlencoded';
import Constants from "variables/constants.jsx"

const styles = 
{
  cardTitleWhite: 
  {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export const AzureLogin = () => {

  const { instance, inProgress } = useMsal();
  
  const { accounts } = useMsal();  

  const isAuthenticated = useIsAuthenticated();
  
  const logoutRequested = Cookies.get("logoutSession");

  // Check if the user isn't authenticated and not in transition - if so, go to the login
  if (inProgress === InteractionStatus.None && !isAuthenticated) {    
    instance.loginRedirect(loginRequest);
  }
  // Else check to see if we can get an auth token
  else if (isAuthenticated)
  { 
    if (logoutRequested == "true")
    {
      Cookies.remove("logoutSession");
      instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    } 
    else {  
      return(
        <LoginForm objectid={accounts[0].localAccountId} />
      );
    }
  }  
};

class LoginForm extends React.Component 
{
  constructor(props) 
  {
    super(props);

    this.state = 
    {
      error: null,
      signinIn: false,
      redirectToReferrer: null,      
      objectid: props.objectid
    }    

    if (this.state.objectid != "") {
      this.login();
    }

  }

  login = () =>
  {
    const { objectid } = this.state
    
    this.setState({signinIn: true})

    var requestBody = 
    {
      grant_type : "password",
      username : objectid,
      password : ""
    }

    // Fetch auth token (util class is not used on purpose)
    fetch(Constants.ApiBaseUrl + "auth/token", 
    {
      method: "POST",
      headers:
      {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formurlencoded(requestBody)
    })
    .then((response) => 
    {
      response.json().then((result) =>
      {
        if (response.status !== 200)
        {                       
          throw new Error(result.error_description);
        }

        Cookies.set("auth", result.access_token, {expires: 1, secure: true, sameSite: "lax"});  // TODO: Add Secure, http only & SameSite flags. Write documentation about why these flags are added.

        this.setState(() => ({
          redirectToReferrer: true,
          signinIn: false
        }));
      })
      .catch((error) =>
      {
        this.setState(
        {
          error: error.message, 
          signinIn: false
        })
      });
    })    
  }

  render() 
  {    
    const { redirectToReferrer } = this.state    

    if (redirectToReferrer === true) 
    {
      var url = new URL(window.location.href);
      var redirectUrl = url.searchParams.get("url");

      if (redirectUrl)
      {
        return <Redirect to={redirectUrl} />
      }
      else
      {
        return <Redirect to="/dashboard" />
      }
    }
  }
  
}

export default withStyles(styles)(LoginForm);
