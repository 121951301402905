import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import globalData from "variables/globalData.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { graphApiGet } from "utils/graphApi/graphApiWebRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);

const AntTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

class QueueDetails extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoadedDetails: false,
            isLoadedMeta: false,
            isLoadedTeams: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Tabs
            selectedTab: 0,
            
            // Meta
            foundTeams: [],

            // Data
            queueDetails: null,
            newQueueMeta: null,

            // Remove queue            
            confirmDialogOpen: false,
            removingQueue: false,
            queueToRemove: "",

            queueRemoved: false,

            // Update queue
            updateQueueDialogOpen: false,
            updateQueueName: "",
            updateMaxMembers: "",
            updatePhoneNumber: "",
            updateTimeOut: "",
            updateRetry: "",
            updateAbsoluteTimeOut: "",
            updateServiceLevel: 0,
            updateHuntWhenAway: "",
            updateShowOnWallboard: "",
            updateStrategy: "",
            updateJoinEmpty: "",
            updateLeaveWhenEmpty: "",
            updateNextQueue: "",
            updateNextQueueOutsideBusinessHours: "",
            updateAudioRecording: "",
            updateMusicOnHold: "",
            updateClosedSound: "",
            updateOpenSound: "",
            updateMSTeamsTeamId: "",

            updatingQueue: false,

            // Fetch shifts
            fetchShiftsDialogOpen: false,
            fetchingShifts: false,
            dateFrom: this.getDateString(0),
            dateTo: this.getDateString(7),
        }
    }

    // Get valid date string for use in date input control.
    getDateString = (daysToAdd) =>
    {
        var dateToday = new Date();
        var year = dateToday.getFullYear();
        var month = dateToday.getMonth() + 1;
        var day = dateToday.getDate();

        if (daysToAdd)
        {
            day = day + daysToAdd
        }
        
        if (month < 10)
        {
            month = "0" + month;
        }

        if (day < 10)
        {
            day = "0" + day;
        }

        return year + "-" + month + "-" + day
    }
    
    componentDidMount()
    {        
        this.getQueueDetails();
        this.getNewQueueMeta();
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    getNewQueueMeta()
    {
        // Fetch queue meta data
        apiGet("ucm/queues/meta").then((result) =>            
        {
            var queueMeta = result.data;

            this.setState(
            {
                isLoadedMeta:true,
                newQueueMeta: queueMeta
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedMeta: true,
                error: error.data
            });
        });
    }
    
    getTeamsFromGraphApi()
    {
        graphApiGet("me/joinedTeams?$select=id,displayName").then((result) =>
        {
            this.setState({
                isLoadedTeams: true,
                foundTeams: result.data.value
            });
        },
        (error) =>
        {
            this.setState({
                isLoadedTeams: true,
                foundTeams: []
            });
        }); 
    }

    getQueueDetails()
    {
        // Check if queue data is already fetched, if not, fetch it again.
        if (globalData.currentlyViewingQueue !== null)
        {
            this.setState(
            {
                isLoadedDetails: true,
                queueDetails: globalData.currentlyViewingQueue
            });
    
            if (globalData.currentlyViewingQueue.QueueType.Type === "shift")
            {
                this.getTeamsFromGraphApi();
            }

            this.setQueueUpdateData(globalData.currentlyViewingQueue);
            return;
        }

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");
        
        // Get details of queue
        apiGet("ucm/queues/get?queueName=" + queueName).then((result) =>            
        {
            globalData.currentlyViewingQueue = result.data;
            
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoadedDetails: true,
                queueDetails: result.data
            });
            
            if (result.data.QueueType.Type === "shift")
            {
                this.getTeamsFromGraphApi();
            }

            this.setQueueUpdateData(result.data);
        },
        (error) =>
        {
            this.setState(
            {
                isLoadedDetails: true,
                error: error.data
            });
        });
    }

    updateQueue()
    {
        this.setState({updatingQueue: true})
        
        // TODO: authentication
        const { updateQueueDialogOpen,
                updateQueueName,
                updateMaxMembers,
                updatePhoneNumber,
                updateTimeOut,
                updateRetry,
                updateAbsoluteTimeOut,
                updateServiceLevel,
                updateHuntWhenAway,
                updateStrategy,
                updateJoinEmpty,
                updateLeaveWhenEmpty,
                updateNextQueue,
                updateNextQueueOutsideBusinessHours,
                updateAudioRecording,
                updateMusicOnHold,
                updateClosedSound,
                updateOpenSound,
                updateShowOnWallboard,
                updateMSTeamsTeamId } = this.state;

        // Create request body
        var requestBody = 
        {
            QueueName: updateQueueName,
            MaxMembers: updateMaxMembers,
            PhoneNumber: updatePhoneNumber,
            TimeOut: updateTimeOut,
            Retry: updateRetry,
            AbsoluteTimeOut: updateAbsoluteTimeOut,
            ServiceLevel: updateServiceLevel,
            HuntWhenAway: updateHuntWhenAway,
            Strategy: updateStrategy,
            JoinEmpty: updateJoinEmpty,
            LeaveWhenEmpty: updateLeaveWhenEmpty,
            NextQueue: updateNextQueue,
            NextQueueOutsideBusinessHours: updateNextQueueOutsideBusinessHours,
            AudioRecording: updateAudioRecording,
            MusicOnHold: updateMusicOnHold,
            ClosedSound: updateClosedSound,
            ShowOnWallboard: updateShowOnWallboard,
            OpenSound: updateOpenSound,
            MSTeamsTeamId: updateMSTeamsTeamId
        }

        // Create queue
        apiPostJson("ucm/queues/update", requestBody).then(() =>        
        {
            this.setState(
            {
                updatingQueue: false
            });

            this.toggleUpdateQueueDialogOpen(updateQueueDialogOpen);
            this.showNotification("success", "Queue updated!");
            globalData.currentlyViewingQueue = null;
            this.getQueueDetails();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                updatingQueue: false
            });

            this.showNotification("danger", error.data);
        });
    }

    setQueueUpdateData(queueData)
    {
        this.setState(
        {
            updateQueueName: queueData.QueueName,
            updateMaxMembers: queueData.MaxMembers,
            updatePhoneNumber: queueData.PhoneNumber,
            updateTimeOut: queueData.TimeOut,
            updateRetry: queueData.Retry,
            updateAbsoluteTimeOut: queueData.AbsoluteTimeOut,
            updateServiceLevel: queueData.ServiceLevel,
            updateHuntWhenAway: queueData.HuntWhenAway,
            updateStrategy: queueData.Strategy.StrategyType,
            updateJoinEmpty: queueData.JoinEmpty,
            updateLeaveWhenEmpty: queueData.LeaveWhenEmpty,
            updateNextQueue: queueData.NextQueue,
            updateNextQueueOutsideBusinessHours: queueData.NextQueueOutsideBusinessHours,
            updateAudioRecording: queueData.AudioRecording,
            updateMusicOnHold: queueData.MusicOnHold,
            updateClosedSound: queueData.ClosedSound,
            updateOpenSound: queueData.OpenSound,
            updateShowOnWallboard: queueData.ShowOnWallboard,
            updateMSTeamsTeamId: queueData.MSTeamsTeamId
        });
    }

    removeQueue()
    {
        this.setState({removingQueue: true});

        const { queueToRemove, confirmDialogOpen } = this.state;

        // Remove agent
        apiPost("ucm/queues/remove?queueName=" + queueToRemove).then(() =>        
        {
            this.setState(
            {
                queueRemoved: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingQueue: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    selectedTabChanged(newSelectedTab) 
    {
        this.setState({
            selectedTab: newSelectedTab
        })
    }

    toggleUpdateQueueDialogOpen = (isOpen) => 
    {
        this.setState({
            updateQueueDialogOpen: !isOpen
        })
    }

    toggleFetchShiftsDialogOpen = (isOpen) => 
    {
        this.setState({
            fetchShiftsDialogOpen: !isOpen
        })
    }

    render() 
    {    
        var {   isLoadedDetails, isLoadedMeta, isLoadedTeams, 
                error, confirmDialogOpen, toastColor, toastText, toastOpen, selectedTab, 
                queueDetails, newQueueMeta,
                foundTeams,
                removingQueue, queueToRemove, queueRemoved, 
                updateQueueDialogOpen, updatingQueue, 
                updateQueueName,
                updateMaxMembers,
                updatePhoneNumber,
                updateTimeOut,
                updateRetry,
                updateAbsoluteTimeOut,
                updateServiceLevel,
                updateHuntWhenAway,
                updateStrategy,
                updateJoinEmpty,
                updateLeaveWhenEmpty,
                updateNextQueue,
                updateNextQueueOutsideBusinessHours,
                updateAudioRecording,
                updateMusicOnHold,
                updateClosedSound,
                updateOpenSound,
                updateShowOnWallboard,
                updateMSTeamsTeamId,
                fetchShiftsDialogOpen,
                fetchingShifts,
                dateFrom,
                dateTo } = this.state;

        const { classes } = this.props; 

        if (!isLoadedDetails || !isLoadedMeta)
        {   
            return <LoadingIndicator loadingText="Loading Queue Details..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else if (queueRemoved)
        {
            return (<Redirect to="../queues" push />)
        }
            
        // Add current phone number to selectable phone numbers list.
        if (!newQueueMeta.PhoneNumbers.includes(queueDetails.PhoneNumber))
        {
            newQueueMeta.PhoneNumbers.push(queueDetails.PhoneNumber);
        }

        return (
            <div>                    
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Fetch shifts dialog */}
                {/* <Dialog open={fetchShiftsDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleFetchShiftsDialogOpen(fetchShiftsDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); }}>
                        <DialogTitle id="form-dialog-title">Fetch Shifts</DialogTitle>
                        <DialogContent>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Date from"
                                        id="date-from"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({dateFrom: value}) }
                                        }
                                        inputProps={{
                                            autoFocus: true,
                                            type: "date",
                                            defaultValue: dateFrom,
                                            min: dateFrom
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Date to"
                                        id="date-to"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({dateTo: value}) }
                                        }
                                        inputProps={{
                                            autoFocus: true,
                                            type: "date",
                                            defaultValue: dateTo,
                                            min: dateFrom
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleFetchShiftsDialogOpen(fetchShiftsDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={fetchingShifts} type="submit" color="secondary">
                                {fetchingShifts ? "Fetching Shifts..." : "Fetch Shifts"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog> */}

                {/* Update queue dialog */}
                <Dialog open={updateQueueDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleUpdateQueueDialogOpen(updateQueueDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.updateQueue() }}>
                        <DialogTitle id="form-dialog-title">Update Queue</DialogTitle>
                        <DialogContent>
                            <h5>Required Settings</h5>
                            <Divider light={true}/>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Queue Name (disabled)"
                                        id="queue-name"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateQueueName: value}) }
                                        }
                                        inputProps={{
                                            disabled: true,
                                            maxLength: 30,
                                            defaultValue: updateQueueName
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Max Members"
                                        id="max-members"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateMaxMembers: value}) }
                                        }
                                        inputProps={{
                                            pattern: "^[0-9]*$",
                                            title: "Only numbers allowed" ,
                                            defaultValue: updateMaxMembers
                                        }}
                                    />
                                    <CustomSelect
                                        labelText="Show on Wallboard?"
                                        id="select-showonwallboard"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}                                                                                                                       
                                        values={["Yes", "No"]}
                                        selectedValue={updateShowOnWallboard ? "Yes" : "No"}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateShowOnWallboard: (value === "Yes")}) }
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomSelect
                                        labelText="Phone Number"
                                        id="select-phonenumber"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={newQueueMeta.PhoneNumbers}
                                        selectedValue={updatePhoneNumber ? updatePhoneNumber : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updatePhoneNumber: value}) }
                                        }
                                    />
                                    <CustomSelect
                                        labelText="Strategy"
                                        id="select-strategy"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        values={newQueueMeta.QueueStrategies}
                                        keyColumn="StrategyType"
                                        valueColumn="DisplayName"
                                        selectedValue={updateStrategy ? updateStrategy : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateStrategy: value}) }
                                        }
                                    />                                    
                                </GridItem>
                            </GridContainer>

                            {
                                queueDetails.QueueType.Type === "shift"
                                ?                                     
                                <div>                             
                                    <h5>Shifts Queue Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            {
                                                isLoadedTeams
                                                ?
                                                    <CustomSelect
                                                        labelText="Connected team (MS Teams)"
                                                        id="select-team"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: true
                                                        }}
                                                        values={foundTeams}
                                                        keyColumn="id"
                                                        valueColumn="displayName"
                                                        selectedValue={updateMSTeamsTeamId}
                                                        onValueChange =
                                                        {
                                                            (value) => { this.setState({updateMSTeamsTeamId: value}); }
                                                        }
                                                    />
                                                :
                                                <p>Loading teams...</p>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </div>                                  
                                :
                                null
                            }
                            
                            <h5>Hunting Settings</h5>
                            <Divider light={true}/>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Timeout (seconds)"
                                        id="timeout"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => {  this.setState({updateTimeOut: value}) }
                                        }
                                        inputProps={{
                                            pattern: "^[0-9]*$",
                                            title: "Only numbers allowed",
                                            defaultValue: updateTimeOut 
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Retry (seconds)"
                                        id="retry"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateRetry: value}) }
                                        }
                                        inputProps={{
                                            pattern: "^[0-9]*$",
                                            title: "Only numbers allowed" ,
                                            defaultValue: updateRetry
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Absolute Timeout (seconds)"
                                        id="absolute-timeout"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => {  this.setState({updateAbsoluteTimeOut: value}) }
                                        }
                                        inputProps={{
                                            pattern: "^[0-9]*$",
                                            title: "Only numbers allowed",
                                            defaultValue: updateAbsoluteTimeOut 
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Service Level (seconds)"
                                        id="service-level"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateServiceLevel: value}) }
                                        }
                                        inputProps={{
                                            pattern: "^[0-9]*$",
                                            title: "Only numbers allowed",
                                            defaultValue: updateServiceLevel 
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomSelect
                                        labelText="Hunt Agents when Away"
                                        id="select-huntagentswhenaway"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={updateHuntWhenAway ? "Yes" : "No"}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateHuntWhenAway: (value === "Yes")}) }
                                        }
                                    />
                                </GridItem>
                            </GridContainer>

                            <h5>Queue Navigation Settings</h5>
                            <Divider light={true}/>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomSelect
                                        labelText="Join when empty"
                                        id="select-joinempty"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={updateJoinEmpty ? "Yes" : "No"}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateJoinEmpty: (value === "Yes")}) }
                                        }
                                    />
                                    <CustomSelect
                                        labelText="Leave when empty"
                                        id="select-leavewhenempty"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={updateLeaveWhenEmpty ? "Yes" : "No"}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateLeaveWhenEmpty: (value === "Yes")}) }
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>                                          
                                    <CustomSelect
                                        labelText="Next Queue (inside business hours)"
                                        id="select-nextqueue-inside-bh"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={newQueueMeta.QueueNames}
                                        selectedValue={updateNextQueue ? updateNextQueue : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateNextQueue: value}) }
                                        }
                                    />
                                    <CustomSelect
                                        labelText="Next Queue (outside business hours)"
                                        id="select-nextqueue-outside-bh"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={newQueueMeta.QueueNames}
                                        selectedValue={updateNextQueueOutsideBusinessHours ? updateNextQueueOutsideBusinessHours : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateNextQueueOutsideBusinessHours: value}) }
                                        }
                                    />
                                </GridItem>
                            </GridContainer>    

                            <h5>Sound Settings</h5>
                            <Divider light={true}/>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomSelect
                                        labelText="Audio Recording Enabled"
                                        id="select-audiorecording"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={["Yes", "No"]}
                                        selectedValue={updateAudioRecording ? "Yes" : "No"}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateAudioRecording: (value === "Yes")}) }
                                        }
                                    />
                                    <CustomSelect
                                        labelText="On Hold Music"
                                        id="select-onholdmusic"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={newQueueMeta.OnHoldMusicSounds}
                                        selectedValue={updateMusicOnHold ? updateMusicOnHold : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateMusicOnHold: value}) }
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomSelect
                                        labelText="Open Sound"
                                        id="select-opensound"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={newQueueMeta.OpenSounds}
                                        selectedValue={updateOpenSound ? updateOpenSound : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateOpenSound: value}) }
                                        }
                                    />
                                    <CustomSelect
                                        labelText="Closed Sound"
                                        id="select-closedsound"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        values={newQueueMeta.ClosedSounds}
                                        selectedValue={updateClosedSound ? updateClosedSound : ""}
                                        onValueChange = 
                                        {
                                            (value) => { this.setState({updateClosedSound: value}) }
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleUpdateQueueDialogOpen(updateQueueDialogOpen)} color="white">
                                Cancel
                            </Button>
                            <Button disabled={updatingQueue} type="submit" color="secondary">
                                {updatingQueue ? "Updating Queue..." : "Update Queue"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Remove queue confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this queue?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            All data of this queue will be lost!
                        </DialogContentText>
                        <CustomInput
                            labelText="Type the name of the queue to continue..."
                            id="queueNameConfirm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            onValueChange = 
                            {
                                (value) => { this.setState({ queueToRemove: value}) }
                            }
                        />  
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingQueue || queueToRemove !== queueDetails.QueueName} onClick={() => this.removeQueue()} color="danger">
                            {removingQueue ? "Removing Queue..." : "Remove Queue"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Details card */}
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Queue: {queueDetails.QueueName}</h4>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?                            
                            <Typography align="right">
                                {/* {
                                    queueDetails.QueueType.Type === "shift"
                                    ?
                                    <Button color="info" style={{marginRight:"5px"}} onClick={() => this.toggleFetchShiftsDialogOpen(fetchShiftsDialogOpen) }>
                                        <RefreshIcon /> Fetch Shifts
                                    </Button>
                                    : null
                                } */}
                                <Button color="secondary" style={{marginRight:"5px"}} onClick={() => this.toggleUpdateQueueDialogOpen(updateQueueDialogOpen) }>
                                    <EditIcon /> Edit Queue
                                </Button>
                                <Button color="danger" onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen) }>
                                    <DeleteIcon /> Delete Queue
                                </Button>                                     
                            </Typography>
                            : null
                        }

                        <div className={classes.demo1}>
                            <AntTabs value={selectedTab} onChange={(event, value) => this.selectedTabChanged(value)}>
                                <AntTab label="General" />
                                <AntTab label="Hunting" />
                                <AntTab label="Navigation" />
                                <AntTab label="Sounds" />
                                {
                                    queueDetails.QueueType.Type === "shift" ? <AntTab label="Shifts" /> : null
                                }
                            </AntTabs>
                            <Typography className={classes.typography} />
                        </div>

                        {
                            selectedTab === 0 &&
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Queue Name"
                                        id="queue-name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.QueueName ? queueDetails.QueueName : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Max Members"
                                        id="max-members"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.MaxMembers ? queueDetails.MaxMembers : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Show on Wallboard?"
                                        id="select-showonwallboard"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.ShowOnWallboard ? "Yes" : "No"
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Phone Number"
                                        id="phone-number"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.PhoneNumber ? queueDetails.PhoneNumber : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Strategy"
                                        id="strategy"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.Strategy.DisplayName ? queueDetails.Strategy.DisplayName : "-"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        }
                        {
                            selectedTab === 1 &&
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Timeout (seconds)"
                                        id="timeout"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.TimeOut ? queueDetails.TimeOut : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Retry (seconds)"
                                        id="retry"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.Retry ? queueDetails.Retry : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Hunt Agents when Away"
                                        id="huntwhenaway"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.HuntWhenAway ? "Yes" : "No"
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Absolute Timeout (seconds)"
                                        id="absolute-timeout"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.AbsoluteTimeOut ? queueDetails.AbsoluteTimeOut : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Service Level (seconds)"
                                        id="service-level"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.ServiceLevel ? queueDetails.ServiceLevel : "-"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        }
                        {
                            selectedTab === 2 &&
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Join When Empty"
                                        id="input-joinwhenempty"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.JoinEmpty ? "Yes" : "No"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Leave When Empty"
                                        id="input-leavewhenempty"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.LeaveWhenEmpty ? "Yes" : "No"
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Next Queue (inside business hours)"
                                        id="next-queue"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.NextQueue ? queueDetails.NextQueue : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Next Queue (outside business hours)"
                                        id="next-queue"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.NextQueueOutsideBusinessHours ? queueDetails.NextQueueOutsideBusinessHours : "-"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        }
                        {
                            selectedTab === 3 &&
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Audio Recording Enabled"
                                        id="input-audiorecording"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.AudioRecording ? "Yes" : "No"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="On Hold Music"
                                        id="on-hold-music"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.MusicOnHold ? queueDetails.MusicOnHold : "-"
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Open Sound"
                                        id="open-sound"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled:true,
                                            value: queueDetails.OpenSound ? queueDetails.OpenSound : "-"
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Close Sound"
                                        id="close-sound"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.ClosedSound ? queueDetails.ClosedSound : "-"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        }
                        {
                            selectedTab === 4 &&
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Team ID"
                                        id="input-shift-teamid"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: queueDetails.MSTeamsTeamId ? queueDetails.MSTeamsTeamId : "-"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        }
                    </CardBody>
                </Card>
            </div>
        );   
    }
}

export default withStyles(styles)(QueueDetails)