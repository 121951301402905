import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ScheduleIcon from "@material-ui/icons/Schedule";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

// Extra libs
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});

class QueueBusinessHours extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoaded: false,

            // Dialogs
            addBusinessHoursDialogOpen: false,
            addingBusinessHours: false,  
            confirmDialogOpen: false,
            removingBusinessHours: false,    

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            hours: null,

            // Add hours
            monChecked: false,
            tueChecked: false,
            wedChecked: false,
            thuChecked: false,
            friChecked: false,
            satChecked: false,
            sunChecked: false,
            openTime: "08:30",
            closeTime: "17:00",

            // Remove hours
            businessHoursToRemove: null
        }
    }

    toggleAddBusinessHoursDialogOpen = (isOpen) =>
    {
        if (isOpen)     
            // Set checkboxes to false if the AddBusinessHours dialog is about to close.       
            this.setState(
                { 
                    addBusinessHoursDialogOpen: !isOpen,
                    monChecked: false,
                    tueChecked: false,
                    wedChecked: false,
                    thuChecked: false,
                    friChecked: false,
                    satChecked: false,
                    sunChecked: false
                })
        else        
            this.setState({ addBusinessHoursDialogOpen: !isOpen })
    }

    toggleConfirmDialogOpen = (isOpen) => 
    {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    componentDidMount()
    {
        // Get queue business hours.
        this.getQueueBusinessHours();
    }

    getQueueBusinessHours()
    {        
        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        apiGet("ucm/queues/hours/queue/" + queueName).then((result) =>            
        {
            var hoursList = [];

            for (var i = 0; i < result.data.length; i++)
            {
                var hoursRecord = result.data[i];

                var newHoursRecord = 
                {
                    DayOfWeek: hoursRecord.DayOfWeek,
                    OpenHours: hoursRecord.StartTime + " - " + hoursRecord.EndTime
                }

                hoursList.push(newHoursRecord);
            }

            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                hours: hoursList
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded:true,
                error: error.data
            });
        });
    }

    addBusinessHours()
    {
        this.setState({addingBusinessHours: true})

        // TODO: authentication
        const { openTime, closeTime, monChecked, tueChecked, wedChecked, thuChecked, friChecked, satChecked, sunChecked, addBusinessHoursDialogOpen } = this.state;

        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Create selected days array
        var selectedDays = [];

        if (monChecked) selectedDays.push("MON");
        if (tueChecked) selectedDays.push("TUE");
        if (wedChecked) selectedDays.push("WED");
        if (thuChecked) selectedDays.push("THU");
        if (friChecked) selectedDays.push("FRI");
        if (satChecked) selectedDays.push("SAT");
        if (sunChecked) selectedDays.push("SUN");

        // Create request body
        var requestBody = 
        {
            queueName: queueName,
            openTime : openTime,
            closeTime : closeTime,
            days : selectedDays
        }

        // Add business hours
        apiPostJson("ucm/queues/hours/add", requestBody).then(() =>        
        {
            this.setState(
            {
                addingBusinessHours: false
            });

            this.toggleAddBusinessHoursDialogOpen(addBusinessHoursDialogOpen);
            this.showNotification("success", "Business hours assigned to queue!");

            this.getQueueBusinessHours();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                addingBusinessHours: false
            });

            this.toggleAddBusinessHoursDialogOpen(addBusinessHoursDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    removeBusinessHours()
    {
        this.setState({removingBusinessHours: true});

        const { businessHoursToRemove, confirmDialogOpen } = this.state;
        
        // TODO: Duplicate fetch queueName
        // Get queue name from url parameter.
        var url = new URL(window.location.href);
        var queueName = url.searchParams.get("no");

        // Get start and endtime
        var splitStartEndtime = businessHoursToRemove.OpenHours.split(" - ");

        // Create request body
        var requestBody = 
        {
            QueueName: queueName,
            StartTime : splitStartEndtime[0],
            EndTime : splitStartEndtime[1],
            DayOfWeek : businessHoursToRemove.DayOfWeek
        }

        // Remove business hours
        apiPostJson("ucm/queues/hours/remove", requestBody).then(() =>        
        {
            this.setState(
            {
                removingBusinessHours: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("success", "Business hours removed!");

            this.getQueueBusinessHours();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingBusinessHours: false
            });

            this.toggleConfirmDialogOpen(confirmDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {
        // Dialogs
        const { error, isLoaded, addBusinessHoursDialogOpen, addingBusinessHours, confirmDialogOpen, removingBusinessHours, toastOpen, toastColor, toastText, hours, monChecked, tueChecked, wedChecked, thuChecked, friChecked, satChecked, sunChecked, openTime, closeTime } = this.state;
        const { classes } = this.props;       
        
        if (!isLoaded)
        {   
            return <LoadingIndicator loadingText="Loading business hours..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Business Hours dialog */}
                <Dialog open={addBusinessHoursDialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleAddBusinessHoursDialogOpen(addBusinessHoursDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <form onSubmit={(e) => {e.preventDefault(); this.addBusinessHours();}}>
                        <DialogTitle id="form-dialog-title">Add new business hour record to queue.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please, enter the desired hours below.
                            </DialogContentText>
                            <FormControl className={classes.formControl}>                                
                                <GridContainer justify="center">
                                    <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={monChecked} onChange={(event) => this.setState({ monChecked: event.target.checked })}/>}
                                                label="Monday"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={tueChecked} onChange={(event) => this.setState({ tueChecked: event.target.checked })}/>}
                                                label="Tuesday"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={wedChecked} onChange={(event) => this.setState({ wedChecked: event.target.checked })}/>}
                                                label="Wednesday"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={thuChecked} onChange={(event) => this.setState({ thuChecked: event.target.checked })}/>}
                                                label="Thursday"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={friChecked} onChange={(event) => this.setState({ friChecked: event.target.checked })}/>}
                                                label="Friday"
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={satChecked} onChange={(event) => this.setState({ satChecked: event.target.checked })}/>}
                                                label="Saturday"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={sunChecked} onChange={(event) => this.setState({ sunChecked: event.target.checked })}/>}
                                                label="Sunday"
                                            />
                                        </FormGroup>
                                    </GridItem>
                                </GridContainer>
                            </FormControl>

                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <CustomInput
                                        labelText="Open Time"
                                        id="open-time"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => this.setState({ openTime: value })
                                        }
                                        inputProps={{
                                            autoFocus: true,
                                            type: "time",
                                            defaultValue: openTime
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <CustomInput
                                        labelText="Close Time"
                                        id="close-time"
                                        formControlProps={{
                                            fullWidth: true,
                                            required: true
                                        }}
                                        onValueChange = 
                                        {
                                            (value) => this.setState({ closeTime: value })
                                        }
                                        inputProps={{
                                            type: "time",
                                            defaultValue: closeTime
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>                      
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.toggleAddBusinessHoursDialogOpen(addBusinessHoursDialogOpen)} color="white" autoFocus>
                                Cancel
                            </Button>
                            <Button disabled={(!monChecked && !tueChecked && !wedChecked && !thuChecked && !friChecked && !satChecked && !sunChecked) || addingBusinessHours} type="submit" color="secondary">
                                {addingBusinessHours ? "Adding record..." : "Add record"}  
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>                

                {/* Remove business hours confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={confirmDialogOpen} onClose={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this business hour record?"}</DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleConfirmDialogOpen(confirmDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                        <Button disabled={removingBusinessHours} onClick={() => this.removeBusinessHours()} color="danger">
                            {removingBusinessHours ? "Removing business hours..." : "Remove business hours"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Business Hours card */}
                <Card>
                    <CardHeader color="primary">
                        <GridContainer>
                            <GridItem xs={10} sm={11} md={11}>
                                <h4 className={classes.cardTitleWhite}>Business Hours</h4>
                                <p className={classes.cardCategoryWhite}>Business hours per day</p>
                            </GridItem>
                            <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                <ScheduleIcon/>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        {
                            PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions)
                            ?
                            <Typography align="right">
                                <Button color="secondary" justIcon round onClick={() => this.toggleAddBusinessHoursDialogOpen(addBusinessHoursDialogOpen)} >
                                    <AddIcon />
                                </Button>                                    
                            </Typography>
                            : null
                        }                        

                        <TableJson
                            tableHeaderColor="primary"
                            tableHead={["Day", "Open Hours"]}
                            tableHeadDataMap={["DayOfWeek", "OpenHours"]}
                            tableData={hours}
                            marginTop={0}
                            isSortable={true}
                            initialOrderBy={"DayOfWeek"}
                            tableDayOfWeekColumns={["DayOfWeek"]} 
                            tableIgnoreSortColumns={["OpenHours"]}     
                            onDeleteButtonClicked=
                            {
                                PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.QUEUES.EDIT"], globalData.loggedInUser.PortalPermissions)
                                ?
                                (clickedItem) => {this.setState({businessHoursToRemove: clickedItem}); this.toggleConfirmDialogOpen(confirmDialogOpen);}
                                : null
                            }
                        />
                    </CardBody>
                </Card>
            </div>
        );     
    }
}

export default withStyles(styles)(QueueBusinessHours)