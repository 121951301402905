import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import QueuesIcon from "@material-ui/icons/ViewHeadline";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const requiredPortalPermissions = [
  "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [   
  "UCALLMANAGER.QUEUES.VIEW",
  "UCALLMANAGER.QUEUES.EDIT"
]

class UCallManagerQueuesWidget extends React.Component 
{
  constructor(props) 
  {
    super(props);
      
    this.state = 
    {
      error: null,
      isLoaded: false,
      queuesCount: 0
    }
  }

  componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get queues count
        this.getQueuesCount();
    }

    getQueuesCount()
    {
        apiGet("ucm/queues/count").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                queuesCount: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

  render() 
  {
    const { classes } = this.props;
    const { error, isLoaded, queuesCount } = this.state;

    if (!isLoaded)
    {
      return <LoadingIndicator loadingText="Loading queues widget..."></LoadingIndicator>
    }
    else if (error)
    {
      // TODO: Error logging
      return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
    }  

    return (
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <QueuesIcon />
          </CardIcon>
          <p className={classes.cardCategory}>Call Queues</p>
          <h3 className={classes.cardTitle}>{queuesCount === 1 ? "1 queue" : (queuesCount + " queues")}</h3>
        </CardHeader> 
        <CardFooter stats>
          <NavLink to="/ucm/queues">
            Go to uCallManager call queues
          </NavLink>  
        </CardFooter>
      </Card>
    );
  }
}
export default withStyles(dashboardStyle)(UCallManagerQueuesWidget);