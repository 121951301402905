import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from '@material-ui/core/Divider';

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

import globalData from "variables/globalData.jsx";
import { apiGet, apiPost, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class IVRDetailsDeleteDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            removingIVR: false,
            ivrRemoved: false,

            // Data
            ivrToRemove: "",
        }
    }

    removeIVR()
    {
        this.setState({removingIVR: true});

        const { ivrToRemove } = this.state;

        // Remove IVR
        apiPost("ucm/ivr/remove?ivrName=" + ivrToRemove).then(() =>        
        {
            this.setState(
            {
                ivrRemoved: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingIVR: false
            });

            this.props.onSubmitError(error.data);
            this.props.onDialogClose();
        });
    }

    render() 
    {    
        const { removingIVR } = this.state;
        const { ivrRemoved, ivrToRemove } = this.state;
        
        if (ivrRemoved)
        {
            return (<Redirect to="../ivr" push />)
        }
        
        return (
            <div>
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this IVR?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All data of this IVR will be lost!
                    </DialogContentText>
                    <CustomInput
                        labelText="Type the name of the IVR to continue..."
                        id="ivrNameConfirm"
                        formControlProps={{
                            fullWidth: true
                        }}
                        onValueChange = 
                        {
                            (value) => { this.setState({ ivrToRemove: value}) }
                        }
                    />  
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white" autoFocus>
                        Cancel
                    </Button>
                    <Button disabled={removingIVR || ivrToRemove !== this.props.ivrName} onClick={() => this.removeIVR()} color="danger">
                        {removingIVR ? "Removing IVR..." : "Remove IVR"}                            
                    </Button>
                </DialogActions>
            </div>
        );   
    }
}

IVRDetailsDeleteDialog.propTypes = {
    ivrName: PropTypes.string,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(IVRDetailsDeleteDialog)