import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// Local scripts
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";
import { graphApiGet } from "utils/graphApi/graphApiWebRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

  const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.QUEUES.EDIT"
]

class NewQueue extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            // Component loading
            error: null,
            isLoaded: false,
            isLoadedTeams: false,

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Data
            newQueueMeta: null,

            // New queue
            queueName: "",
            queueType: "",
            maxMembers: 0,
            phoneNumber: "",
            strategy: "",

            timeOut: 0,
            retry: 0,
            absoluteTimeOut: 0,
            serviceLevel: 0,
            huntWhenAway: true,

            joinEmpty: false,
            leaveWhenEmpty: false,
            nextQueue: "",
            nextQueueOutsideBusinessHours: "",

            audioRecording: false,
            onHoldMusic: "",
            closedSound: "",
            openSound: "",
            showOnWallboard: true,

            // New Shift Queue properties
            foundTeams: [],
            selectedTeam: "",

            // New queue state
            showShiftQueueProperties: false,
            creatingQueue: false,
            queueCreated: false
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) ||
            !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        this.getNewQueueMeta();
    }

    getNewQueueMeta()
    {
        // Fetch queue meta data
        apiGet("ucm/queues/meta").then((result) =>
        {
            this.setState(
            {
                isLoaded:true,
                newQueueMeta: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    createQueue()
    {
        this.setState({creatingQueue: true})

        // TODO: authentication
        const { queueName, queueType, maxMembers, phoneNumber, timeOut, retry, absoluteTimeOut, serviceLevel, strategy, huntWhenAway, joinEmpty, leaveWhenEmpty, nextQueue, nextQueueOutsideBusinessHours, audioRecording, onHoldMusic, closedSound, openSound, showOnWallboard } = this.state;

        // Create request body
        var requestBody =
        {
            QueueName: queueName,
            QueueType: queueType,
            MaxMembers: maxMembers,
            PhoneNumber: phoneNumber,
            TimeOut: timeOut,
            Retry: retry,
            AbsoluteTimeOut: absoluteTimeOut,
            ServiceLevel: serviceLevel,
            Strategy: strategy,
            HuntWhenAway: huntWhenAway,
            JoinEmpty: joinEmpty,
            LeaveWhenEmpty: leaveWhenEmpty,
            NextQueue: nextQueue,
            NextQueueOutsideBusinessHours: nextQueueOutsideBusinessHours,
            AudioRecording: audioRecording,
            MusicOnHold: onHoldMusic,
            ClosedSound: closedSound,
            OpenSound: openSound,
            ShowOnWallboard: showOnWallboard
        }

        if (queueType === "default")
        {
            this.createDefaultQueue(requestBody)
        }
        else
        {
            this.createShiftQueue(requestBody)
        }
    }

    createDefaultQueue(requestBody)
    {
        // Create queue
        apiPostJson("ucm/queues/add", requestBody).then(() =>
        {
            this.setState(
            {
                queueCreated: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                creatingQueue: false
            });

            this.showNotification("danger", error.data);
        });
    }

    createShiftQueue(requestBody)
    {
        var { selectedTeam } = this.state;

        requestBody["MSTeamsTeamId"] = selectedTeam;
        
        // Create queue
        apiPostJson("ucm/queues/add/with-graph-connection", requestBody).then(() =>
        {
            this.setState(
            {
                queueCreated: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                creatingQueue: false
            });

            this.showNotification("danger", error.data);
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function()
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    showSpecificQueueTypeProperties(queueType)
    {
        if (queueType === "shift")
        {
            this.showShiftQueueProperties();
        }
    }

    showShiftQueueProperties()
    {
        this.setState({
            showShiftQueueProperties: true
        });

        graphApiGet("me/joinedTeams?$select=id,displayName").then((result) =>
        {
            this.setState({
                isLoadedTeams: true,
                foundTeams: result.data.value
            });
        },
        (error) =>
        {
            this.setState({
                isLoadedTeams: true,
                foundTeams: []
            });
        }); 
    }

    render()
    {
        const { error, isLoaded, isLoadedTeams,
                toastColor, toastText, toastOpen,
                newQueueMeta,
                queueName, queueType, maxMembers, phoneNumber, strategy, timeOut, retry, absoluteTimeOut, serviceLevel,  huntWhenAway, joinEmpty, leaveWhenEmpty, nextQueue, nextQueueOutsideBusinessHours, audioRecording, onHoldMusic, closedSound, openSound,
                foundTeams, selectedTeam, showOnWallboard,
                showShiftQueueProperties, creatingQueue, queueCreated } = this.state;

        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading new queue data..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            // TODO: Proper error handling
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error.message}></ErrorIndicator>
        }
        else if (queueCreated)
        {
            // If case was created, navigate to cases page.
            return (<Redirect to={"../queues/view?no=" + queueName} push />)
        }

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <form onSubmit={(e) => { e.preventDefault(); this.createQueue() }}>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Create New Queue</h4>
                                </CardHeader>
                                <CardBody>
                                    <h5>Required Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Queue Name"
                                                id="queue-name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({queueName: value}) }
                                                }
                                                inputProps={{
                                                    autoFocus: true,
                                                    maxLength: 30
                                                }}
                                            />
                                            <CustomSelect
                                                labelText="Queue Type"
                                                id="select-queuetype"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={newQueueMeta.QueueTypes}
                                                keyColumn="Type"
                                                valueColumn="DisplayName"
                                                selectedValue={queueType}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({queueType: value}); this.showSpecificQueueTypeProperties(value); }
                                                }
                                            />
                                            <CustomSelect
                                                labelText="Show on uWallboard?"
                                                id="select-showonwallboard"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={showOnWallboard ? "Yes" : "No"}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({showOnWallboard: (value === "Yes")}); }
                                                }
                                            />
                                            <CustomInput
                                                labelText="Max Members"
                                                id="max-members"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({maxMembers: value}) }
                                                }
                                                inputProps={{
                                                    pattern: "^[0-9]*$",
                                                    title: "Only numbers allowed"
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Phone Number"
                                                id="select-phonenumber"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={newQueueMeta.PhoneNumbers}
                                                selectedValue={phoneNumber}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({phoneNumber: value}); }
                                                }
                                            />

                                            <CustomSelect
                                                labelText="Strategy"
                                                id="select-strategy"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                values={newQueueMeta.QueueStrategies}
                                                keyColumn="StrategyType"
                                                valueColumn="DisplayName"
                                                selectedValue={strategy}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({strategy: value}); }
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    {
                                        showShiftQueueProperties ?
                                        (
                                            <div>
                                                <h5>Shifts Queue Settings</h5>
                                                <Divider light={true}/>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        {
                                                            isLoadedTeams
                                                            ?
                                                                <CustomSelect
                                                                    labelText="Connected team (MS Teams)"
                                                                    id="select-team"
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        required: true
                                                                    }}
                                                                    values={foundTeams}
                                                                    keyColumn="id"
                                                                    valueColumn="displayName"
                                                                    selectedValue={selectedTeam}
                                                                    onValueChange =
                                                                    {
                                                                        (value) => { this.setState({selectedTeam: value}); }
                                                                    }
                                                                />
                                                            :
                                                            <p>Loading teams...</p>
                                                        }
                                                    </GridItem>
                                                </GridContainer>
                                            </div>
                                        )
                                        :
                                        null
                                    }

                                    <h5>Hunting Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Timeout (seconds)"
                                                id="timeout"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({timeOut: value}) }
                                                }
                                                inputProps={{
                                                    pattern: "^[0-9]*$",
                                                    title: "Only numbers allowed"
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Retry (seconds)"
                                                id="retry"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({retry: value}) }
                                                }
                                                inputProps={{
                                                    pattern: "^[0-9]*$",
                                                    title: "Only numbers allowed"
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Absolute Timeout (seconds)"
                                                id="absolute-timeout"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({absoluteTimeOut: value}) }
                                                }
                                                inputProps={{
                                                    pattern: "^[0-9]*$",
                                                    title: "Only numbers allowed"
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Service Level (seconds)"
                                                id="service-level"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({serviceLevel: value}) }
                                                }
                                                inputProps={{
                                                    pattern: "^[0-9]*$",
                                                    title: "Only numbers allowed"
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Hunt Agents when Away"
                                                id="select-huntagentswhenaway"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={huntWhenAway ? "Yes" : "No"}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({huntWhenAway: (value === "Yes")}); }
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <h5>Queue Navigation Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Join When Empty"
                                                id="select-joinwhenempty"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={joinEmpty ? "Yes" : "No"}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({joinEmpty: (value === "Yes")}); }
                                                }
                                            />
                                            <CustomSelect
                                                labelText="Leave When Empty"
                                                id="select-leavewhenempty"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={leaveWhenEmpty ? "Yes" : "No"}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({leaveWhenEmpty: (value === "Yes")}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Next Queue (inside business hours)"
                                                id="select-nextqueue-inside-bh"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newQueueMeta.QueueNames}
                                                selectedValue={nextQueue}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({nextQueue: value}); }
                                                }
                                            />
                                            <CustomSelect
                                                labelText="Next Queue (outside business hours)"
                                                id="select-nextqueue-outside-bh"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newQueueMeta.QueueNames}
                                                selectedValue={nextQueueOutsideBusinessHours}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({nextQueueOutsideBusinessHours: value}); }
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <h5>Sound Settings</h5>
                                    <Divider light={true}/>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Enable Audio Recording"
                                                id="select-audiorecording"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={["Yes", "No"]}
                                                selectedValue={audioRecording ? "Yes" : "No"}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({audioRecording: (value === "Yes")}); }
                                                }
                                            />
                                            <CustomSelect
                                                labelText="On Hold Music"
                                                id="select-onholdmusic"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newQueueMeta.OnHoldMusicSounds}
                                                selectedValue={onHoldMusic}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({onHoldMusic: value}); }
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomSelect
                                                labelText="Open Sound"
                                                id="select-opensound"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newQueueMeta.OpenSounds}
                                                selectedValue={openSound}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({openSound: value}); }
                                                }
                                            />
                                            <CustomSelect
                                                labelText="Closed Sound"
                                                id="select-closedsound"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                values={newQueueMeta.ClosedSounds}
                                                selectedValue={closedSound}
                                                onValueChange =
                                                {
                                                    (value) => { this.setState({closedSound: value}); }
                                                }
                                            />

                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <Typography align="right" style={{width:"100%"}}>
                                        <Button color="secondary" disabled={creatingQueue} type="submit">{(creatingQueue ? "Creating Queue..." : "Create")}</Button>
                                    </Typography>
                                </CardFooter>
                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(NewQueue);