import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import AddAlert from "@material-ui/icons/AddAlert";
import Typography from '@material-ui/core/Typography';
import AudioIcon from '@material-ui/icons/Equalizer';
import Dialog from '@material-ui/core/Dialog';

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import AudioLibraryAddDialog from "./AudioLibraryAddDialog.jsx";
import AudioLibraryDeleteDialog from "./AudioLibraryDeleteDialog.jsx";

// Extra libs
import { apiGet } from "utils/webRequestUtils.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [   
    "UCALLMANAGER.IVR.VIEW",
    "UCALLMANAGER.IVR.EDIT",
    "UCALLMANAGER.AUDIOLIBRARY.EDIT"
]

class AudioLibraryOverviewPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // Component loading
            error: null,
            isLoaded: false,
            
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            // Dialog
            dialogOpen: false,
            dialogType: "",

            // Data
            audioFiles: [],
            selectedAudioFile: ""
        }
    }

    componentDidMount()
    {   
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        this.getAudioFiles();
    }

    getAudioFiles = () =>
    {
        // Get activities of ticket
        apiGet("ucm/audio/get-meta").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded: true,
                audioFiles: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }
    
    openAudioFileAddDialog() 
    {
        this.setState({ dialogType: "add", dialogOpen: true });
    }

    openAudioFileDeleteDialog(selectedAudioFile) 
    {console.log(selectedAudioFile)
        this.setState({ selectedAudioFile: selectedAudioFile, dialogType: "delete", dialogOpen: true });
    }

    toggleDialogOpen = (isOpen) => 
    {
        this.setState({ dialogOpen: !isOpen });
    }
    
    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    render() 
    {
        const { classes } = this.props;
        const { error, isLoaded } = this.state;
        const { toastColor, toastText, toastOpen } = this.state;
        const { dialogOpen, dialogType } = this.state;
        const { audioFiles, selectedAudioFile } = this.state;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading audio library..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }  

        return (
            <div>
                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Dialog */}
                <Dialog open={dialogOpen} className={classes.responsiveDialog} onClose={() => this.toggleDialogOpen(dialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    {(() => {
                        switch (dialogType) {
                            case 'add':
                                return <AudioLibraryAddDialog   
                                            existingAudioFiles={audioFiles}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Audio file(s) uploaded"); this.getAudioFiles(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></AudioLibraryAddDialog>
                            case 'delete':
                                return <AudioLibraryDeleteDialog 
                                            audioFileName={selectedAudioFile}
                                            onSubmitSuccess={() => { this.toggleDialogOpen(dialogOpen); this.showNotification("success", "Audio file removed!"); this.getAudioFiles(); }}
                                            onSubmitError={(message) => this.showNotification("danger", message)}
                                            onDialogClose={() => this.toggleDialogOpen(dialogOpen)}
                                        ></AudioLibraryDeleteDialog>
                            default:
                                return <div></div>;
                        }
                    })()}
                </Dialog>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <GridContainer>
                                    <GridItem xs={10} sm={11} md={11}>
                                        <h4 className={classes.cardTitleWhite}>Audio Library</h4>
                                        <p className={classes.cardCategoryWhite}>Overview of audio files to be used in uCallManager IVR's.</p>
                                    </GridItem>
                                    <GridItem xs={2} sm={1} md={1} style={{textAlign: 'right'}}>
                                        <AudioIcon/>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody> 
                                {
                                    PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.AUDIOLIBRARY.EDIT"], globalData.loggedInUser.PortalPermissions)
                                    ?
                                    <Typography align="right">
                                        <Button color="secondary" justIcon round onClick={() => this.openAudioFileAddDialog()}>
                                            <AddIcon />
                                        </Button>                                 
                                    </Typography>
                                    : null
                                }  

                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["File Name",]}
                                    tableHeadDataMap={["Filename"]}
                                    tableData={audioFiles}
                                    isClickable={true}
                                    // onListItemClicked= 
                                    // {
                                    //     (listItem) => { console.log(listItem) }
                                    // }
                                    isSortable={true}
                                    initialOrderBy={"Filename"}                             
                                    onDeleteButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UCALLMANAGER.AUDIOLIBRARY.EDIT"], globalData.loggedInUser.PortalPermissions)
                                        ?
                                        (clickedItem) => { this.openAudioFileDeleteDialog(clickedItem.Filename); }
                                        : null
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div> 
        );     
    }
}

export default withStyles(styles)(AudioLibraryOverviewPage)