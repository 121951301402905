import React from "react";
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';
import AddAlert from "@material-ui/icons/AddAlert";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import ProductPicker from "components/Table/TableJsonProductPicker.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import NewOrderConfirmationTab from "views/Orders/NewOrder/NewOrderConfirmation.jsx";
import NewOrderTenantSelectTab from "views/Orders/NewOrder/NewOrderTenantSelect.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiPostJson } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const invisibleStyle = {
    display: "none"
}

const previousTabButtonStyle = {
    float: "left",
    backgroundColor: "#229988"
}

const nextTabButtonStyle = {
    float: "right",
    backgroundColor: "#229988"
}

const previousTabButtonStyleFixed = {
    //position: "absolute",
    float: "left"
}

const nextTabButtonStyleFixed = {
    //position: "absolute",
    float: "right"
}

const allowedPortalPermissions =
[
    "ADMINORDERS"
]

class NewOrderPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            offersAreLoaded: false,
            tenantIdsAreLoaded: false,
            offers: [],
            tenants: [],            
            data: [],
            searchText: "",

            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",

            selectedTenantName: "",
            selectedTenantId: "",
            selectedOffers: [],
            previousSelectedTenantId: "",

            agreementAccepted: false,

            activeStepIndex: 0,

            previousButtonStyle: invisibleStyle,
            previousButtonText: "",
            nextButtonText: "Select Products",

            creatingOrder: false,
            orderCreated: false
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                offersAreLoaded: true,
                tenantIdsAreLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get tenants
        apiGet("tenantids").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                tenantIdsAreLoaded:true,
                tenants: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                tenantIdsAreLoaded: true,
                error: error.data
            });
        });
    }

    getOffers = (selectedTenant) => 
    {
        apiGet("pc/products/" + selectedTenant).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                offersAreLoaded: true,
                offers: result.data,
                data: result.data
            });
        },
        (error) =>
        {
            this.setState(
            {
                offersAreLoaded: true,
                error: error.data
            });
        });
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) =>
    {
        this.setState(
        {
            toastOpen: true,
            toastColor: toastColor,
            toastText: toastText
        });

        this.alertTimeout = setTimeout(
            function() 
            {
                this.setState(
                {
                    toastOpen: false
                });
            }.bind(this),
            3000
        );
    }

    previousStepClicked = () =>
    {
        const { activeStepIndex } = this.state;

        var newStepIndex = activeStepIndex === 0 ? 0 : activeStepIndex - 1;
        
        this.setPreviousAndNextButtonVariables(newStepIndex);
        
        document.getElementById("mainpanel").scrollTop = 0; // Get pagecontainer by id and scroll to top of it.
        
        this.setState({ activeStepIndex: newStepIndex });

    }

    validateFormAndGoToNextStep = () =>
    {
        const { selectedTenantId, previousSelectedTenantId, selectedOffers, activeStepIndex } = this.state;

        // If current active tab is select-tenant, try fetching orders if tenant changed.
        if (activeStepIndex === 0 && previousSelectedTenantId !== selectedTenantId)
        {
            this.setState({ previousSelectedTenantId: selectedTenantId, offersAreLoaded: false, selectedOffers: [] });
            this.getOffers(selectedTenantId);
        }

        // If current active tab is select-orders, check if any orders have been filled in.
        else if (activeStepIndex === 1 && selectedOffers.length < 1)
        {            
            this.showNotification("warning", "Please, select an offer to continue.");
            return false;
        }

        else if (selectedOffers.length > 0 && activeStepIndex === 1) {
            for(var i = 0; i < selectedOffers.length; i++) {                
                if (selectedOffers[i].Amount == 0)
                {
                    this.showNotification("warning", "Please, select a correct amount for the chosen offers to continue.");
                    return false;
                }
            }
        }

        else if (activeStepIndex === 2)
        {
            this.confirmOrder();
            return true;
        }

        this.nextStepClicked();
        return true;
    }

    nextStepClicked = () =>
    {
        const { activeStepIndex } = this.state;

        var newStepIndex = activeStepIndex === 2 ? 2 : activeStepIndex + 1;

        this.setPreviousAndNextButtonVariables(newStepIndex);

        document.getElementById("mainpanel").scrollTop = 0; // Get pagecontainer by id and scroll to top of it.

        this.setState({ activeStepIndex: newStepIndex });

    }

    setPreviousAndNextButtonVariables = (stepIndex) =>
    {        
        if (stepIndex === 0)
        {
            this.setState(
                { 
                    previousButtonStyle : invisibleStyle,
                    previousButtonText: "",
                    nextButtonText: "Select Products"
                });
        }
        else if (stepIndex === 1)
        {
            this.setState(
                { 
                    previousButtonStyle : previousTabButtonStyle,
                    previousButtonText: "Select Tenant",
                    nextButtonText: "Confirm Order"
                });
        }
        else if (stepIndex === 2)
        {
            this.setState(
                { 
                    previousButtonStyle : previousTabButtonStyle,
                    previousButtonText: "Select Products",
                    nextButtonText: "Confirm"
                });
        }
    }

    setNewSelectedTenantValues = (tenantId) =>
    {
        const { tenants } = this.state;

        // Get tenantName by id and set states
        var tenantName;

        for (var i = 0; i < tenants.length; i++)
        {
            if (tenants[i].ID === tenantId)
            {
                tenantName = tenants[i].TenantName;
                break;
            }
        }

        this.setState({selectedTenantName: tenantName, selectedTenantId: tenantId})
    }

    confirmOrder = () =>
    {
        this.setState({creatingOrder: true})
        
        // TODO: authentication
        const { selectedTenantId, selectedOffers } = this.state;
        
        // Create request body
        var requestBody = 
        {
            TenantId: selectedTenantId,
            Products: selectedOffers
        }

        // Create order
        apiPostJson("orders/new", requestBody).then(() =>        
        {
            this.setState(
            {
                orderCreated: true
            });
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                creatingOrder: false
            });

            this.showNotification("danger", error.data);
        });
        
    }

    setSearch = (searchTerm) => {
        const { offers, searchText, data } = this.state;        
        const search = offers.filter(
            item =>
                item.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1,
        );
        if (searchTerm == "") {
            this.setState( { data: offers, searchText: searchTerm });
        }
        else {        
            this.setState( { data: search, searchText: searchTerm });        
        }        
    }

    render() 
    {
        const { error, offersAreLoaded, tenantIdsAreLoaded,
                toastColor, toastText, toastOpen, data, searchText,
                offers, tenants, selectedOffers, selectedTenantName, selectedTenantId, agreementAccepted, activeStepIndex, previousButtonStyle, previousButtonText, nextButtonText, creatingOrder, orderCreated } = this.state;
        const { classes } = this.props;
        
        const handleSearch = (event) => {
            if (offersAreLoaded)
            { 
                this.setSearch(event);
            }
        };

        if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else if (orderCreated)
        {
            // If order was created, navigate to orders page.
            return (<Redirect to="../orders" push />)
        }

        return (
            <div>                                                                    

                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Content */}
                <GridContainer justify="center">
                    
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <form onSubmit={(e) => { e.preventDefault(); return this.validateFormAndGoToNextStep(); }}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>New order</h4>
                                </CardHeader>
                                <CardBody>
                                    <Stepper activeStep={activeStepIndex} alternativeLabel>
                                        <Step key="Select Tenant">
                                            <StepLabel>Select Tenant</StepLabel>
                                        </Step>
                                        <Step key="Select Products">
                                            <StepLabel>Select Products</StepLabel>
                                        </Step>
                                        <Step key="Confirm order">
                                            <StepLabel>Confirm Order</StepLabel>
                                        </Step>
                                    </Stepper>

                                        {
                                            activeStepIndex === 0 ?
                                                tenantIdsAreLoaded === true ?
                                                <NewOrderTenantSelectTab tenant={selectedTenantId} onSelectTenant={(tenantId) => this.setNewSelectedTenantValues(tenantId) } tenants={tenants}/>
                                                :
                                                <LoadingIndicator loadingText="Loading tenants..."></LoadingIndicator>
                                            : null
                                        }
                                        
                                        {
                                            activeStepIndex === 1 ?
                                                offersAreLoaded === true ?
                                                    offers.length > 0 ?
                                                    <>
                                                    <CustomInput
                                                        labelText="Search by product"
                                                        id="search"
                                                        
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: false
                                                        }}
                                                        onValueChange =
                                                        
                                                            {handleSearch}
                                                        
                                                        inputProps={{
                                                            autoFocus: true,
                                                            maxLength: 200,
                                                            defaultValue: searchText
                                                        }}
                                                    />
                                                    <ProductPicker 
                                                        tableHeaderColor="primary"
                                                        tableHead={["Name", "Amount"]}
                                                        tableHeadDataMap={["Name", "Amount"]}
                                                        tableData={data}
                                                        isSortable={true}
                                                        tableIgnoreSortColumns={["Amount"]}
                                                        initialOrderBy={"Name"}
                                                        isClickable={true}
                                                        selectableRows={true}
                                                        selectedItems={selectedOffers}
                                                        amountEditFieldIndex={1}
                                                        minimumAmountColumnName={"MinimumQuantity"}
                                                        maximumAmountColumnName={"MaximumQuantity"}
                                                        amountUnitColumnName={"UnitType"}
                                                    />
                                                    </>
                                                    :
                                                    <p style={{textAlign: "center"}}>No products found for tenant {selectedTenantName}.</p>
                                                :
                                                <LoadingIndicator loadingText="Loading catalog..."></LoadingIndicator>
                                            : null
                                        }

                                        {
                                            activeStepIndex === 2 ?
                                            <NewOrderConfirmationTab products={selectedOffers} tenant={selectedTenantName} agreementAccepted={agreementAccepted} productNameFieldKey="Name" onAgreementAccept={() => this.setState({agreementAccepted: !agreementAccepted})}/>
                                            : null
                                        }
                                </CardBody>
                            </Card>

                            <Button color="secondary" style={previousButtonStyle} onClick={() => this.previousStepClicked()} disabled={creatingOrder}>
                                <ArrowBackIosIcon/><span>{previousButtonText}</span>
                            </Button> 
                            
                            <Button color="secondary" type="submit" style={nextTabButtonStyle} disabled={creatingOrder}>
                                <span>{(creatingOrder ? "Creating Order..." : nextButtonText)}</span>
                                {
                                    activeStepIndex === 2
                                    ?
                                    <DoneIcon/>
                                    :
                                    <ArrowForwardIosIcon/>
                                }
                            </Button> 

                            {/* <Fab color="primary" aria-label="add" variant="extended" style={previousButtonStyle} onClick={() => this.previousStepClicked()} disabled={creatingOrder}>
                                <ArrowBackIosIcon/><span>{previousButtonText}</span>
                            </Fab>
                            
                            <Fab color="primary" aria-label="add" variant="extended" type="submit" style={nextTabButtonStyle} disabled={creatingOrder}>
                                <span>{(creatingOrder ? "Creating Order..." : nextButtonText)}</span>
                                {
                                    activeStepIndex === 2
                                    ?
                                    <DoneIcon/>
                                    :
                                    <ArrowForwardIosIcon/>
                                }
                            </Fab> */}
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
                
    }
}

export default withStyles(styles)(NewOrderPage)