import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom'

// @material-ui/
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CustomInput from "components/CustomInput/CustomInput.jsx";

// styles
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";


// TODO: Clear up this class
function CustomTableJson({ ...props }) 
{
  const 
  { 
    classes, 

    tableHead, 
    tableData, 
    tableHeadDataMap, 

    tableHeaderColor,

    tableNumericColumns,
    tableDateColumns,

    tableIgnoreSortColumns,

    isClickable,

    onClickLink,
    
    selectableRows,
    selectedItems,

    infoFieldIndex,
    infoFieldLinkText,
    infoModalTitle,

    amountEditFieldIndex, // TODO: Very specific. Please clean up
    minimumAmountColumnName,
    maximumAmountColumnName,
    amountUnitColumnName,

    onListItemClicked,
    onDeleteButtonClicked,
    onEditButtonClicked,
    initialOrderBy,
    initialSortingOrder,
    isSortable
  } = props;
  
  const [tempToggle, setTempToggle] = React.useState(false); // TODO: Somehow the TableRow doesn't update it's style if not updating this variable...
  const [localSelectedItems, setLocalSelectedItems] = React.useState(selectedItems);
  const [open, setOpen] = React.useState(false);
  const [modalDescription, setModalDescription] = React.useState(false);
  const [sortingOrder, setSortingOrder] = React.useState(initialSortingOrder ? initialSortingOrder : "asc");
  const [orderBy, setOrderBy] = React.useState(initialOrderBy ? initialOrderBy : "");

  const selectedRowStyle =
  {
    backgroundColor: "Red"
  }

  const amountFieldStyle = 
  {
    margin: "0px",
    verticalAlign: "Bottom",
    padding: "0px 10px 0px 0px"
  }

  const handleClickOpen = (description) => 
  {
    setOpen(true);
    setModalDescription(description);
  };

  function handleClose() {
    setOpen(false);
  }

  /**
   * Generates a sortable tableHead if isSortable is true.
   * If isSortable is false, it will return a normal tableHead.
   * @param {*} props Represents onRequestSort callback function.
   */
  function SortableTableHead(props)
  {
    const { onRequestSort } = props;

    const createSortHandler = dataColumnName => event => 
    {
      onRequestSort(event, dataColumnName);
    }

    return(
      <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
        <TableRow>
          {
            tableHead.map((prop, index) => 
            {
              var dataColumnName = tableHeadDataMap[index];

              // Check if tableHeaders should be sorted and return sortable tableHeaders.
              // If tableHeaders shouldn't be sorted or if they should be ignored, return normal tableHeaders.
              if (isSortable && (tableIgnoreSortColumns ? !tableIgnoreSortColumns.includes(prop) : true))
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={index}
                    sortDirection={orderBy === dataColumnName ? sortingOrder : false}
                  >
                    <TableSortLabel
                      active={orderBy === dataColumnName}
                      direction={sortingOrder}
                      onClick={createSortHandler(dataColumnName)}
                    >
                      {prop}
                    </TableSortLabel>
                  </TableCell>
                );
              }
              else
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={index}
                  >
                    {prop}
                  </TableCell>
                );
              }
            })
          }
        </TableRow>
      </TableHead>
    )
  }

  /**
   * Updates sortingOrder and orderBy variables 
   * @param {*} event clickEvent
   * @param {*} orderByProperty property the data should be sorted by.
   */
  function handleRequestSort(event, orderByProperty) 
  {
    // If same property is clicked, sort data descending. Otherwise sort data ascending.
    const sortDescending = orderBy === orderByProperty && sortingOrder === 'asc';

    // Set sorting direction and orderBy property
    setSortingOrder(sortDescending ? "desc" : "asc");
    setOrderBy(orderByProperty);
  }

  /**
   * Compares the provided obj1 and obj2 in an ascending order
   * @param {*} obj1 Object 1 to compare
   * @param {*} obj2 Object 2 to compare
   * @param {*} orderBy Property to compare between the two objects.
   */
  function compareDataAscending(obj1, obj2, orderBy) 
  {
    var valueToCompare1 = "";
    var valueToCompare2 = "";

    // Check if data should be sorted on child of a property (only goes one layer deep).
    if (orderBy.includes('.'))
    {
      var orderBySplit = orderBy.split('.');

      valueToCompare1 = obj1[orderBySplit[0]][orderBySplit[1]];
      valueToCompare2 = obj2[orderBySplit[0]][orderBySplit[1]];
    }
    else
    {
      valueToCompare1 = obj1[orderBy];
      valueToCompare2 = obj2[orderBy];
    }

    // Check if value is a number, date or text and handle comparisons
    if (tableNumericColumns && tableNumericColumns.includes(orderBy))
    {  
      if (valueToCompare2 - valueToCompare1 > 0) 
      {
        return -1;
      }
      if (valueToCompare2 - valueToCompare1 < 0) 
      {
        return 1;
      }
    }
    else if (tableDateColumns && tableDateColumns.includes(orderBy))
    {
      // Assumes date is in DAY-MONTH-YEAR HOUR:MINUTE format.
      var splitDateTime1 = valueToCompare1.split(' ');
      var splitDateTime2 = valueToCompare2.split(' ');

      var date1 = splitDateTime1[0].split('-');
      var date2 = splitDateTime2[0].split('-');

      // If date contained time, add time to new date object
      if (splitDateTime1[1] && splitDateTime2[1])
      {
        var time1 = splitDateTime1[1].split(':');
        var time2 = splitDateTime2[1].split(':');
        
        valueToCompare1 = new Date(date1[2], date1[1], date1[0], time1[0], time1[1]);
        valueToCompare2 = new Date(date2[2], date2[1], date2[0], time2[0], time2[1]);
      }
      else
      {
        valueToCompare1 = new Date(date1[2], date1[1], date1[0]);
        valueToCompare2 = new Date(date2[2], date2[1], date2[0]);
      }

      if (valueToCompare2 > valueToCompare1) 
      {
        return -1;
      }
      if (valueToCompare2 < valueToCompare1) 
      {
        return 1;
      }
    }
    else
    {
      valueToCompare1 = valueToCompare1.toString();
      valueToCompare2 = valueToCompare2.toString();
  
      if (valueToCompare2.toLowerCase() > valueToCompare1.toLowerCase()) 
      {
        return -1;
      }
      if (valueToCompare2.toLowerCase() < valueToCompare1.toLowerCase()) 
      {
        return 1;
      }
    }

    return 0;
  }

  /**
   * Sort provided data by the provided property
   * @param {*} data Data to sort
   * @param {*} orderBy Property to sort data by
   */
  function sortTableByProperty(data, orderBy) 
  {
    if (!orderBy)
    {
      return data;
    }

    var sortedData = data.sort((obj1, obj2) =>
    {
      if (sortingOrder === "asc")
      {
        return compareDataAscending(obj1, obj2, orderBy);
      }
      else
      {
        return -compareDataAscending(obj1, obj2, orderBy);
      }
    });

    return sortedData;
  }

  /**
   * Custom TableRow component which is able to perform an action on click
   */
  const RouterTableRow = withRouter(({rowKey, hoverStyle, listItem, history }) => (
    <TableRow hover={isClickable} style=
    { 
      Object.assign({}, 
      isClickable ? hoverStyle : null
      /* ,selectedItemsss.includes(listItem) ? selectedRowStyle : null */ )
    } key={rowKey} selected={(localSelectedItems.includes(listItem))} >

    {
      createPropertyArrayFromJsonObject(listItem, tableHeadDataMap).map((property, index) =>
      {    
        // If current item index is equal to the amountEditFieldIndex,
        // and if current item is selected.
        // Show an editable numeric field
        if (amountEditFieldIndex && amountEditFieldIndex === index)
        {
          var inputProps = 
          {
            title: "Only numbers allowed",
            placeholder: "0",
            defaultValue: listItem["Amount"] ? listItem["Amount"] : "",
            type: "number"
          }

          if (minimumAmountColumnName)
          {
            inputProps["title"] += " (min: " + listItem[minimumAmountColumnName] + ")";
            inputProps["min"] = listItem[minimumAmountColumnName];
          }
          if (maximumAmountColumnName)
          {
            inputProps["title"] += " (max: " + listItem[maximumAmountColumnName] + ")";
            inputProps["max"] = listItem[maximumAmountColumnName];
          }

          return (
            <TableCell className={classes.tableCell} key={index}>
              {
                localSelectedItems.includes(listItem)
                ?
                <div>
                  <CustomInput
                    id={"product-amount-" + rowKey}
                    formControlProps={{
                      required: true,
                      style: amountFieldStyle,
                      
                    }}
                    onValueChange = 
                    {
                      (value) => { listItem["Amount"] = value }
                    }

                    inputProps={ inputProps }                
                  />
                  <span>
                    { listItem[amountUnitColumnName] }
                  </span>
                </div>
                : null
              }                
            </TableCell>
          );        
        } 

        // Else return a default TableCell column
        else
        { 
          return (
            <TableCell className={classes.tableCell} key={index} onClick=
            {
              () => 
              {
                // Somehow the TableRow doesn't update without setting this variable... 
                setTempToggle(!tempToggle);
        
                // Check if TableRow is clickable
                if (!isClickable)
                {
                  return;
                }
        
                // Check if a click event should be sent back to the place where this control is defined.
                if (onListItemClicked)
                  onListItemClicked(listItem)
        
                // Check if an onClickLink has been passed to this control.
                if (onClickLink)
                {
                  // Get single parameter from url.
                  var matches = onClickLink.match(/\{([^)]+)\}/)
                  
                  var newLink = onClickLink;
          
                  // If any parameter enclosed by { } was found,
                  // try to fetch a listItemValue using the parameter
                  // and replace the parameter by the found listItemValue.
                  // e.g: /cases/ticket?no={ticketnumber}  ->  /cases/ticket?no=TIC-12345-67890
                  if (matches)
                  {
                    var parameter = matches[1];
                    var listItemValue = listItem[parameter];
          
                    newLink = newLink.replace(matches[0], listItemValue);
                  }
          
                  history.push(newLink); 
                }
        
                // Check if rows should be selected
                else if (selectableRows && selectedItems)
                {
                  if (selectedItems.includes(listItem))
                  {
                    var itemIndex = selectedItems.indexOf(listItem);
                    selectedItems.splice(itemIndex, 1);
        
                    setLocalSelectedItems(selectedItems);
                  }
                  else
                  {
                    selectedItems.push(listItem);
                    setLocalSelectedItems(selectedItems);
                  }
                }
              }}>
              { 
                // If current item index is equal to the infoFieldIndex,
                // Show info field as a link.
                (infoFieldIndex === index) 
                ? <a style={hoverStyle} onClick={() => handleClickOpen(property)}>{infoFieldLinkText}</a> 
                : property.toString()
              }
            </TableCell>
          );
        }  
      })
    }

    {
      // Show delete button if onDeleteButtonClicked is not null
      onDeleteButtonClicked || onEditButtonClicked
      ?
      <TableCell className={classes.tableButtonCell} key={1001}>
        {
          onEditButtonClicked ? <EditIcon style={hoverStyle} onClick={() => onEditButtonClicked(listItem)}/> : null
        }
        {
          onDeleteButtonClicked ? <DeleteIcon style={hoverStyle} onClick={() => onDeleteButtonClicked(listItem)}/> : null
        } 
      </TableCell>
      :
      null
    }

    </TableRow> 
  ))

  return (
    // TODO: Maybe create global modal.
    <div>
      <div>        
        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          fullWidth={true}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">{infoModalTitle}</DialogTitle>
          <DialogContent>
              <DialogContentText className={classes.dialogContent} >                
                  {modalDescription}                
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {
            tableHead !== undefined ?
              <SortableTableHead onRequestSort={handleRequestSort} />
              :
              null
          }
          
          <TableBody>
            {
              sortTableByProperty(tableData, orderBy).map((listItem, rowKey) =>
              {              
                var hoverStyle =
                {
                  cursor: "pointer"
                }

                return (
                  <RouterTableRow key={rowKey} rowKey={rowKey} hoverStyle={hoverStyle} listItem={listItem}></RouterTableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </div>
    </div>    
  );
}

/**
 * Creates array of property values from the provided Json object. 
 * Properties are returned in the order specified in the Keys parameter.
 * @param {*} jsonObject Json object containing property keys and values.
 * @param {*} keys Array of keys that should be fetched from the Json object. 
 */
function createPropertyArrayFromJsonObject(jsonObject, keys)
{
  var propertyValueArray = []

  for (var i = 0; i < keys.length; i++) 
  {
    // Check if key contains child key (key.subkey).
    var key = keys[i];

    var splitKeys = key.split('.');
    
    // Check if parentObject is null, return empty string.
    var parentObject = jsonObject[splitKeys[0]];

    if (parentObject === null) 
    {
      propertyValueArray.push("");
      continue;
    }

    // If key contains childKey, fetch value of the childkey.    
    if (splitKeys.length > 1)
    {
      propertyValueArray.push(jsonObject[splitKeys[0]][splitKeys[1]])
    }
    else
    {
      propertyValueArray.push(parentObject)
    }
  }

  return propertyValueArray;
}

CustomTableJson.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTableJson.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  tableHeadDataMap : PropTypes.arrayOf(PropTypes.any), 
  onClickLink : PropTypes.string,
  descriptionModal : PropTypes.bool
};

export default withStyles(tableStyle)(CustomTableJson);
