import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import CodeIcon from "@material-ui/icons/Code";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class PortalSettingsGraphApiWidget extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes } = this.props;

        return (
            <Card>
                <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                        <CodeIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Graph API Settings</p>
                </CardHeader>
                <CardFooter stats>
                    <NavLink to="/settings/graph-api">
                        Go to Graph API settings
            </NavLink>
                </CardFooter>
            </Card>
        );
    }
}
export default withStyles(dashboardStyle)(PortalSettingsGraphApiWidget);