import React from "react";
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

// core components
import Button from "components/CustomButtons/Button.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import globalData from "variables/globalData.jsx";
import { apiGet, apiPost } from "utils/webRequestUtils.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const requiredPortalPermissions =
[
    "UNIVERSALAPPROVEORDERS"
]

class ApproveOrder extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            approvingOrder: false,
            removingOrder: false,
            orderToRemove: null,
            orderToApprove: null,
            approveOrderDialogOpen: false,
            removeOrderDialogOpen: false,
            // Toast
            toastOpen: false,
            toastColor: "success",
            toastText: "",
            orders: []
        }
    }

    componentDidMount()
    {
        // Check portal permissions   
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions)) {
            this.setState(
                {
                    isLoaded: true,
                    error: "Access denied"
                });

            return;
        }

        this.getOrders();
    }

    toggleConfirmDialogOpen = (isOpen) => {
        this.setState({
            confirmDialogOpen: !isOpen
        })
    }

    getOrders() {

        // Get orders
        apiGet("orders/pendingapproval").then((result) => {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
                {
                    isLoaded: true,
                    orders: this.appendDescriptionToOrders(result.data)
                });
        },
            (error) => {
                this.setState(
                    {
                        isLoaded: true,
                        error: error.data
                    });
            });
    }

    toggleRemoveOrderDialogOpen = (isOpen) => {
        this.setState({
            removeOrderDialogOpen: !isOpen
        })
    }

    // TODO: Check if this method is reusable in other classes.
    showNotification = (toastColor, toastText) => {
        this.setState(
            {
                toastOpen: true,
                toastColor: toastColor,
                toastText: toastText
            });

        this.alertTimeout = setTimeout(
            function () {
                this.setState(
                    {
                        toastOpen: false
                    });
            }.bind(this),
            3000
        );
    }

    removeOrder() {
        this.setState({ removingOrder: true });

        const { orderToRemove, removeOrderDialogOpen } = this.state;

        // Remove Order
        apiPost("orders/disapprove?orderId=" + orderToRemove.OrderId).then(() => {
            this.setState(
                {
                    removingOrder: false
                });

            this.toggleRemoveOrderDialogOpen(removeOrderDialogOpen);
            this.showNotification("success", "Order removed!");

            this.getOrders();
        },
        (error) => {
            this.setState(
                {
                    //TODO: User friendly error.
                    removingOrder: false
                });

            this.toggleRemoveOrderDialogOpen(removeOrderDialogOpen);
            this.showNotification("danger", error.data);
        });
    }

    toggleApproveOrderDialogOpen = (isOpen) => {
        this.setState({
            approveOrderDialogOpen: !isOpen
        })
    }

    approveOrder() {
        this.setState({ approvingOrder: true });

        const { orderToApprove, approveOrderDialogOpen } = this.state;

        // Approve Order
        apiPost("orders/approve?orderId=" + orderToApprove.OrderId).then(() => {
            this.setState(
                {
                    approvingOrder: false
                });

            this.toggleApproveOrderDialogOpen(approveOrderDialogOpen);
            this.showNotification("success", "Order approved!");

            this.getOrders();
        },
        (error) => {
            this.setState(
                {
                    //TODO: User friendly error.                   
                    approvingOrder: false
                });

            this.toggleApproveOrderDialogOpen(approveOrderDialogOpen);
            this.showNotification("danger", error.data);
        });
    }  

    appendDescriptionToOrders(orders)
    {
        var newOrders = [];

        for (var orderIndex = 0; orderIndex < orders.length; orderIndex++)
        {
            var order = orders[orderIndex];

            var productsDescription = "";

            for (var productIndex = 0; productIndex < order["Products"].length; productIndex++)
            {
                var productAmount = order["Products"][productIndex]["Amount"];
                var productName =   order["Products"][productIndex]["IsAddOn"] === true
                                    ?
                                    order["Products"][productIndex]["ProductDisplayName"]
                                    :
                                    order["Products"][productIndex]["ProductName"];


                productsDescription += ("- " + "(" + productAmount + "x) " + productName) + "\n";
            }

            order["ProductsDescription"] = productsDescription;

            newOrders.push(order);
        }

        return newOrders;
    }

    render() 
    {
        const { error, isLoaded, orders, toastColor, toastText, toastOpen, removeOrderDialogOpen, approveOrderDialogOpen, removingOrder, approvingOrder } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading orders..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else
        {
            return (
                
                <div>

                {/* Toast */}
                <Snackbar
                    place="br"
                    color={toastColor}
                    message={toastText}
                    open={toastOpen}
                    icon={AddAlert}
                    closeNotification={() => this.setState({ toastOpen: false })}
                    close
                />

                {/* Disapprove order confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={removeOrderDialogOpen} onClose={() => this.toggleRemoveOrderDialogOpen(removeOrderDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to disapprove this order?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This step will be irreversible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleRemoveOrderDialogOpen(removeOrderDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                    <Button disabled={removingOrder} onClick={() => this.removeOrder()} color="danger">
                            {removingOrder ? "Disapproving order..." : "Disapprove order"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Approve order confirmation dialog */}
                <Dialog className={classes.responsiveDialog} open={approveOrderDialogOpen} onClose={() => this.toggleApproveOrderDialogOpen(approveOrderDialogOpen)} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to approve this order?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This step will be irreversible.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleApproveOrderDialogOpen(approveOrderDialogOpen)} color="white" autoFocus>
                            Cancel
                        </Button>
                    <Button disabled={approvingOrder} onClick={() => this.approveOrder()} color="secondary">
                            {approvingOrder ? "Approving order..." : "Approve order"}                            
                        </Button>
                    </DialogActions>
                </Dialog>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Pending Orders</h4>
                                <p className={classes.cardCategoryWhite}>
                                    All pending orders (to be approved by an Universal employee). 
                                </p>
                            </CardHeader>
                            <CardBody>
                                
                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Account Name", "Tenant", "Created By", "Created On", "Products"]}
                                    tableHeadDataMap={["Customer.Name", "TenantName", "Creator.FullName", "CreatedOn", "ProductsDescription"]}
                                    tableData={orders}
                                    isSortable={true}
                                    infoFieldIndex={4}
                                    infoFieldLinkText="Show products"
                                    infoModalTitle="Order products"
                                    initialSortingOrder={"desc"}
                                    initialOrderBy={"CreatedOn"}
                                    tableDateColumns={["CreatedOn"]}
                                    tableIgnoreSortColumns={["ProductsDescription"]}
                                    onApproveButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UNIVERSALAPPROVEORDERS"], globalData.loggedInUser.PortalPermissions)
                                            ?
                                            (clickedItem) => { this.setState({ orderToApprove: clickedItem }); this.toggleApproveOrderDialogOpen(approveOrderDialogOpen); }
                                            : null
                                    }
                                    onDisapproveButtonClicked=
                                    {
                                        PMUtils.userHasAnyRequiredPermissions(["UNIVERSALAPPROVEORDERS"], globalData.loggedInUser.PortalPermissions)
                                            ?
                                            (clickedItem) => { this.setState({ orderToRemove: clickedItem }); this.toggleRemoveOrderDialogOpen(removeOrderDialogOpen); }
                                            : null
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                </div>
            );
        }        
    }
}

export default withStyles(styles)(ApproveOrder)