import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from "components/CustomButtons/Button.jsx";

import { apiPost } from "utils/webRequestUtils.jsx";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    },
    responsiveDialog:
    {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "-33px !important",
            marginRight: "-33px !important"
        },
    }
});


class AudioLibraryDeleteDialog extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            // State
            removingAudioFile: false
        }
    }

    removeAudioFile()
    {
        this.setState({removingAudioFile: true});

        const { audioFileName } = this.props;
        
        // Remove audio file
        apiPost("ucm/audio/remove?audioFileName=" + audioFileName).then(() =>        
        {
            this.props.onSubmitSuccess();
        },
        (error) =>
        {
            this.setState(
            {
                //TODO: User friendly error.
                removingAudioFile: false
            });
            
            this.props.onSubmitError(error.data);
            this.props.onDialogClose();
        });
    }

    render() 
    {    
        const { removingAudioFile } = this.state;

        return (
            <div>
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove this audio file?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action can't be undone. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onDialogClose()} color="white" autoFocus>
                        Cancel
                    </Button>
                    <Button disabled={removingAudioFile} onClick={() => this.removeAudioFile()} color="danger">
                        {removingAudioFile ? "Removing Audio File..." : "Remove Audio File"}                            
                    </Button>
                </DialogActions>
            </div>
        );   
    }
}

AudioLibraryDeleteDialog.propTypes = {
    audioFileName: PropTypes.string,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    onDialogClose: PropTypes.func
  };

export default withStyles(styles)(AudioLibraryDeleteDialog)