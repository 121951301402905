import React from "react";

import ErrorLogo from "assets/img/broken-cloud.png";

function ErrorIndicator({ ...props }) 
{
    const { errorText } = props;

    const style =
    {
        width: "100%",
        height: "75%",
        
        //position: "absolute",
        top:0,
        bottom: 0,
        left: 0,
        right: 0,
        
        textAlign: "center",
        margin: "auto"
    }

    const imageStyle =
    {
        width: "190px",
        height: "130px"
    }

    return (
        <div style={style}>
            <img src={ErrorLogo} style={imageStyle} alt="Error"></img>
            <br/>
            {errorText}
        </div>
        
    );
}

export default (ErrorIndicator);