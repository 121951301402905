import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import TableJson from "components/Table/TableJson.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";

import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet } from "utils/webRequestUtils.jsx";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

const allowedPortalPermissions =
[
    "READORDERS",
    "ADMINORDERS"
]

class OrderHistoryPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            error: null,
            isLoaded: false,
            orders: []
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAnyRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });

            return;
        }

        // Get orders
        apiGet("orders/history").then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                orders: this.appendDescriptionToOrders(result.data)
            });
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.data
            });
        });
    }

    appendDescriptionToOrders(orders)
    {
        var newOrders = [];

        for (var orderIndex = 0; orderIndex < orders.length; orderIndex++)
        {
            var order = orders[orderIndex];

            var productsDescription = "";

            for (var productIndex = 0; productIndex < order["Products"].length; productIndex++)
            {
                var productAmount = order["Products"][productIndex]["Amount"];
                var productName =   order["Products"][productIndex]["IsAddOn"] === true
                                    ?
                                    order["Products"][productIndex]["ProductDisplayName"]
                                    :
                                    order["Products"][productIndex]["ProductName"];


                productsDescription += ("- " + "(" + productAmount + "x) " + productName) + "\n";
            }

            order["ProductsDescription"] = productsDescription;

            newOrders.push(order);
        }

        return newOrders;
    }

    render() 
    {
        const { error, isLoaded, orders } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading order history..."></LoadingIndicator>
        }
        else if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
        else
        {
            return (
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Order History</h4>
                                <p className={classes.cardCategoryWhite}>
                                    Order history of my organization. 
                                </p>
                            </CardHeader>
                            <CardBody>
                                
                                <TableJson
                                    tableHeaderColor="primary"
                                    tableHead={["Account Name", "Tenant", "Created By", "Created On", "Products", "Approved"]}
                                    tableHeadDataMap={["Customer.Name", "TenantName", "Creator.FullName", "CreatedOn", "ProductsDescription", "IsApproved"]}
                                    tableData={orders}
                                    isSortable={true}
                                    infoFieldIndex={4}
                                    infoFieldLinkText="Show products"
                                    infoModalTitle="Order products"
                                    initialOrderBy={"CreatedOn"}
                                    initialSortingOrder={"desc"}
                                    tableDateColumns={["CreatedOn"]}
                                    tableIgnoreSortColumns={["ProductsDescription"]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }        
    }
}

export default withStyles(styles)(OrderHistoryPage)