import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator.jsx";
import ErrorIndicator from "components/ErrorIndicator/ErrorIndicator.jsx";
import TableJson from "components/Table/TableJson.jsx";
import Button from "components/CustomButtons/Button.jsx";
import globalData from "variables/globalData.jsx";
import PMUtils from "utils/portalPermissionUtils.jsx";
import { apiGet, apiGetFile } from "utils/webRequestUtils.jsx";
import CustomSelect from "components/CustomInput/CustomSelect";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
        }
    }
};

const requiredPortalPermissions = [
    "UCALLMANAGER.ACCESS"
]

const allowedPortalPermissions = [
    "UCALLMANAGER.ACCESS",
    "UCALLMANAGER.CALLRECORDINGS.VIEW"
]

class RecordingsPage extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
        this.state = 
        {
            selectedHour: "X",
            selectedDay: "X",
            selectedMonth: "X",
            error: null,
            isLoaded: false,
            recordings: [],

            audioPlayerSource: null
        }
    }

    componentDidMount()
    {
        // Check portal permissions
        if (!PMUtils.userHasAllRequiredPermissions(requiredPortalPermissions, globalData.loggedInUser.PortalPermissions) || !PMUtils.userHasAllRequiredPermissions(allowedPortalPermissions, globalData.loggedInUser.PortalPermissions))
        {
            this.setState(
            {
                isLoaded: true,
                error: "Access denied"
            });            

            return;
        } 
        
        this.setState(
        {
            isLoaded: true                    
        });
    }

    getAudioRecordings = () =>
    {
        const { selectedDay, selectedMonth, selectedHour } = this.state;

        var selectedDayTemp, selectedMonthTemp, selectedHourTemp;

        this.setState(
        {
            isLoaded:false            
        });

        if (selectedHour == "" || selectedHour == null)
        {
            selectedHourTemp = "X";
        }
        else
        {
            selectedHourTemp = selectedHour;
        }
        
        if (selectedMonth == "" || selectedMonth == null)
        {
            selectedMonthTemp = "X";            
        }
        else
        {
            selectedMonthTemp = selectedMonth;                        
        }

        if (selectedDay == "" || selectedDay == null)
        {
            selectedDayTemp = "X";            
        }
        else
        {
            selectedDayTemp = selectedDay;            
        }

        // Get list of recordings
        apiGet("ucm/recordings/list/" + selectedMonthTemp + "/" + selectedDayTemp + "/" + selectedHourTemp).then((result) =>            
        {
            // TODO: Check if 'Authorization has been denied for this request' message is received.
            this.setState(
            {
                isLoaded:true,
                recordings: result.data,
                selectedDay: "X",
                selectedHour: "X",
                selectedMonth: "X"
            });

            // Load first recording if list isn't empty.
            if (result.data.length > 0)
            {
                this.loadAudioFile(result.data[0]);
            }
        },
        (error) =>
        {
            this.setState(
            {
                isLoaded: true,
                error: error.message
            });
        });
    }

    loadAudioFile = (callRecording) =>
    {
        // Get recording file.
        // TODO: Check if 'Authorization has been denied for this request' message is received.
        apiGetFile("ucm/recordings/get/" + callRecording.FileName + "/" + callRecording.FileExtension).then((result) =>            
        {
            // Create blob from byte array.
            var blob = new Blob([result], {type : "audio/" + callRecording.FileExtension});      
            var blobUrl = URL.createObjectURL(blob);

            this.setState({
                audioPlayerSource: blobUrl
            });
            
            // Load audio blob into audio player.
            let audio = document.getElementById("callRecordingsPlayer");

            if (audio != null)
            {
                audio.load();
            }
        },
        (error) =>
        {
            this.setState(
            {
                error: error.message
            });
        });
    }

    render() 
    {
        const { error, isLoaded, recordings, audioPlayerSource } = this.state;
        const { classes } = this.props;

        if (!isLoaded)
        {
            return <LoadingIndicator loadingText="Loading recordings..."></LoadingIndicator>
        }
        if (error)
        {
            // TODO: Error logging
            return <ErrorIndicator errorText={ "Oops, something went wrong: " + error}></ErrorIndicator>
        }
       
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Call Recordings</h4>
                            <p className={classes.cardCategoryWhite}>
                                Overview of past call recordings. 
                            </p>
                        </CardHeader>
                        <CardBody> 
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12} lg={10} xl={10}>
                                    <audio id="callRecordingsPlayer" style={{width:"100%"}} controls>
                                        <source id="source" src={audioPlayerSource} type="audio/wav"/>
                                    </audio> 
                                    <div className={classes.textCursive}>Select a period/date to view the recordings in the list below.</div>
                                    <GridContainer>                                

                                        <GridItem xs={3} sm={3} md={3}>
                                            <CustomSelect
                                                labelText="Select month"
                                                id="select-month"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ selectedMonth: value}) }
                                                }
                                                values={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]}
                                            />
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3}>
                                            <CustomSelect
                                                labelText="Select day"
                                                id="select-day"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ selectedDay: value}) }
                                                }
                                                values={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15",
                                                "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]}
                                            />
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3}>
                                            <CustomSelect
                                                labelText="Select hour"
                                                id="select-hour"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    required: true
                                                }}
                                                onValueChange = 
                                                {
                                                    (value) => { this.setState({ selectedHour: value}) }
                                                }
                                                values={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15",
                                                "16", "17", "18", "19", "20", "21", "22", "23", "24"]}
                                            />
                                        </GridItem>
                                        </GridContainer>

                                    <Button color="info" onClick={() => this.getAudioRecordings()}>{ "Get recordings" }</Button>
                                </GridItem>
                            </GridContainer>

                            <TableJson                                
                                tableHeaderColor="primary"
                                tableHead={["Call To", "Date & Time", "Duration"]}
                                tableHeadDataMap={["CallTo", "CallDateTime", "CallDuration"]}
                                tableData={recordings}
                                isClickable={true}                                
                                onListItemClicked= 
                                {
                                    (listItem) => { 
                                        
                                        for(var i  = 0; i < recordings.length; i++)
                                        {
                                            if (recordings[i].FileName == listItem.FileName)
                                            {
                                                recordings[i].CallTo = recordings[i].CallTo.toString().replace(" (currently playing)", "");
                                                recordings[i].CallTo = recordings[i].CallTo + " (currently playing)"; 
                                            }
                                            else
                                            {
                                                recordings[i].CallTo = recordings[i].CallTo.toString().replace(" (currently playing)", "");
                                            }
                                        }
                                        
                                        this.loadAudioFile(listItem) }
                                }
                                isSortable={true}
                                initialOrderBy={"CallDateTime"}
                                initialSortingOrder={"desc"}
                                tableNumericColumns={["CallTo"]}
                                tableDateColumns={["CallDateTime"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );  
    }
}

export default withStyles(styles)(RecordingsPage)